import { CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { IServiceOffering } from 'model/serviceOffering';
import useCoverageAreaAssignment from 'components/assignCoverageArea/useCoverageAreaAssignment';
import { useEffect, useState } from 'react';
import { Save } from '@mui/icons-material';
import { useLoading } from 'components/Layout/Loading';

interface IAssignCoverageAreaProps {
  serviceOffering: IServiceOffering;
}

import { debounce } from 'lodash';

const debouncedInit = debounce((init: (providerId: string) => void, providerId: string) => {
  void init(providerId);
}, 300);

export default function AssignCoverageArea({ serviceOffering }:IAssignCoverageAreaProps) {
  const { providerCoverageAreaOptions, init, onAssignCoverageArea } = useCoverageAreaAssignment();
  const { onLoading, doneLoading, getLoadingState } = useLoading(`${serviceOffering.id}-summary-coverage-area`);
  const { isLoading } = getLoadingState();

  useEffect(() => {
    if (serviceOffering.providerId) {
      void debouncedInit(init, serviceOffering.providerId);
    }
  }, [serviceOffering]);

  const [providerCoverageAreaId, setProviderCoverageAreaId] = useState<string | null>(serviceOffering.providerCoverageAreaId ?? 'UNSELECTED');

  if (providerCoverageAreaOptions.length === 0) {
    return null;
  }

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs>
        <FormControl variant='standard' fullWidth>
          <InputLabel htmlFor="provider-coverage-area">Coverage Area</InputLabel>

          <Select
            id={`provider-coverage-area-service-offering-${serviceOffering.id}`}
            name={`providerCoverageAreaAssignment-${serviceOffering.id}`}
            autoWidth
            label="Coverage Area"
            onChange={(e) => {
              setProviderCoverageAreaId(e.target.value);
            }}
            value={providerCoverageAreaId}
            sx ={{ width: '100%' }}
          >
            {
              providerCoverageAreaOptions.map((x: any, i) => {
                return <MenuItem key={x.key} value={x.optionValue}>{x.optionText}</MenuItem>;
              })
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm='auto'>
        <Tooltip title={'Assign coverage area'} >
          <IconButton
            color="primary"
            onClick={async () => {
              onLoading();
              if (providerCoverageAreaId === 'UNSELECTED') {
                await onAssignCoverageArea(serviceOffering.id!, null);
              } else {
                await onAssignCoverageArea(serviceOffering.id!, providerCoverageAreaId);
              }
              doneLoading(150);
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : <Save />}

          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}