import {
  deleteCustomerUploadV2,
  stageCustomersV2 as apiStageCustomers,
  reconcileCustomerUpload,
} from 'api/customerApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { ICustomerUploadDeleteFormV2, IReconcileCustomerUploadForm, IReconcileCustomerUploadRequestDto, IServiceOfferingAllocation, createServiceOfferingAllocation, IEditServiceAllocationsForm, IEditServiceAllocationsRequest } from 'model/customerUpload';
import { IDropdownOption } from 'model/dropdown';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { add } from 'date-fns';
import formatDate from 'date-fns/format';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { IServiceOffering } from 'model/serviceOffering';
import { findServiceTypeInfo } from 'api/serviceCategoryApi';
import { findInternalServiceProviderById, updateServiceOfferingAllocations } from 'api/serviceProviderApi';
import { isGuid } from 'util/guidUtil';

type CustomerUpload = {
  uploadOrDeleteCustomers:Function;
  uploadCustomers:Function;
  deleteCustomerUpload:Function;
  onDropFile:Function;
  acceptedFiles:any;
  serviceProviderOptions: IDropdownOption[];
  neighborhoodOptions: IDropdownOption[];
  deleteByNeighborhoodOptions: IDropdownOption[];
  serviceOfferings: IServiceOffering[];
  serviceTypeNameMap: any;
}
const loadingKey = 'customerUploadV2';

const { get, update, registerListener, unregisterListener } = createStore<CustomerUpload>('customerUploadV2', {
  uploadOrDeleteCustomers: () => {},
  uploadCustomers: () => {},
  deleteCustomerUpload: () => {},
  onDropFile: () => {},
  acceptedFiles: [],
  serviceProviderOptions: [],
  neighborhoodOptions: [],
  deleteByNeighborhoodOptions: [],
  serviceOfferings: [],
  serviceTypeNameMap: {},
});

export default function useCustomerUpload() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { neighborhoodId } = useParams();
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();

    let serviceTypeNameMap = {};

    const resServiceCategoryTypes = await findServiceTypeInfo();
    serviceTypeNameMap = resServiceCategoryTypes.data.serviceTypes
      .reduce((map, obj) => {
        if (obj.serviceTypeId && !obj.deleteDate) {
          map[obj.serviceTypeId] = obj.displayName ?? obj.name;
        }
        return map;
      }, {});

    update({
      ...get(),
      serviceTypeNameMap,
    });
    doneLoading(300);
  }

  async function onUpload(onConfirmArgs) {
    if (onConfirmArgs.shouldUpload) {
      await uploadCustomers(onConfirmArgs);
    }
  }

  async function onStep1SelectedProviderChanged(formContext:any, replace:any, serviceProviderId?:string) {
    if (serviceProviderId) {
      const res = await findServiceOfferingsByProvider(serviceProviderId);
      if (res.data) {
        //reset service offering allocations if the service provider changes.
        let serviceOfferingAllocations = res.data.map(x => createServiceOfferingAllocation(x));
        replace(serviceOfferingAllocations);
        update({
          ...get(),
          serviceOfferings: res.data,
        });
      }
    }
  }


  async function onDelete(data:ICustomerUploadDeleteFormV2) {
    onLoading();
    const res = await deleteCustomerUploadV2(data);
    doneLoading(300);
    if (res.data.tentativeDeleteCount) {
      let now = new Date();
      let secondsToWait = 1.6 * res.data.tentativeDeleteCount;
      let dateOfFinish = add(now, { seconds: secondsToWait });
      const temporalMessage = `Delete should finish around: ${formatDate(dateOfFinish, 'MMM dd, yyyy hh:mm aa')}`;
      createInfoToast(`Previous upload with processing id: ${data.processingId} request to delete received. You will receive an email with the results. ${temporalMessage}`);
    } else {
      createInfoToast(`Previous upload with processing id: ${data.processingId} request to delete received. You will receive an email with the results.`);
    }
  }


  async function onReconcile(form: IReconcileCustomerUploadForm) {
    onLoading();
    try {
      const data: IReconcileCustomerUploadRequestDto = {
        serviceProviderId: form.selectedServiceProvider?.optionValue ?? null,
        allServiceProviders: form.allServiceProviders,
      };
      const res = await reconcileCustomerUpload(data);
      createSuccessToast('Reconcile request received.');
    } catch (err:any) {
      createErrorToast(err.response.data.message);
    }
    doneLoading(300);

  }

  async function uploadCustomers(onConfirmArgs) {
    const { data, serviceProviderId } = onConfirmArgs;
    if (data.customerUploadKey) {
      data.customerUploadKey = data.customerUploadKey.trim();
      if (!isGuid(data.customerUploadKey)) {
        createErrorToast('Customer Upload Key must be a valid GUID');
        return;
      }
    }
    data.serviceOfferingAllocations = _modifyAllocationsForSave(data.serviceOfferingAllocations);
    try {
      await apiStageCustomers(serviceProviderId, data);
      createSuccessToast('Upload received. You will receive an email once the process is complete.');
      update({
        ...get(),
        acceptedFiles: [],
      });
    } catch (err:any) {
      console.error(err);
      createErrorToast(err.response.data.message);
    }
  }

  function _modifyAllocationsForSave(allocations:IServiceOfferingAllocation[]) {
    return allocations.map(x => {
      return {
        serviceOfferingId: x.serviceOfferingId,
        percent: x.percent / 100.0,
      };
    });
  }

  async function onDropFile(acceptedFiles, ctx) {
    try {
      if (acceptedFiles[0].size > 2_000_000) {
        createErrorToast('File size must be less than 2MB');
        return;
      }
      ctx.setValue('customersTemplate', acceptedFiles[0]);
      ctx.setValue('fileName', acceptedFiles[0].name);
      update({
        ...get(),
        acceptedFiles,
      });
    } catch (e:any) {
      console.error(e);
    }

  }


  async function onEditServiceAllocationsSelectedProviderChanged(formContext:any, serviceProviderId?:string) {
    if (serviceProviderId) {
      let res = await findInternalServiceProviderById(serviceProviderId);
      if (res.data && res.data.requestIdToServiceOfferingAllocationMap) {
        const serviceOfferingRes = await findServiceOfferingsByProvider(serviceProviderId);
        if (serviceOfferingRes.data) {

          //reset service offering allocations if the service provider changes.
          let serviceOfferingAllocations = serviceOfferingRes.data.map(x => createServiceOfferingAllocation(x));
          var serviceOfferingAllocationMap = serviceOfferingAllocations.reduce((map, obj) => {
            if (obj.serviceOfferingId) {
              map[obj.serviceOfferingId] = obj;
            }
            return map;
          }, {});


          let requestIdToFormMap = {};
          for (let requestId of Object.keys(res.data.requestIdToServiceOfferingAllocationMap)) {

            let requestServiceOfferingAllocations = res.data.requestIdToServiceOfferingAllocationMap[requestId];
            for (let i = 0; i < requestServiceOfferingAllocations.length; i++) {
              var serviceOfferingAllocation = requestServiceOfferingAllocations[i];
              if (!requestIdToFormMap[requestId]) {
                requestIdToFormMap[requestId] = [];
              }
              requestIdToFormMap[requestId].push({
                ...serviceOfferingAllocationMap[serviceOfferingAllocation!.serviceOfferingId],
                ...serviceOfferingAllocation,
                percent: (serviceOfferingAllocation.percent * 100.0)?.toString() ?? '',
              });
            }
          }
          formContext.setValue('serviceOfferingAllocations', requestIdToFormMap);
        }

      }
    }
  }

  async function onSubmitEditAllocations(formData: IEditServiceAllocationsForm) {
    var serviceProviderId = formData.selectedServiceProvider?.ancillary.id;
    let request: IEditServiceAllocationsRequest = {
      requestIdToServiceOfferingAllocationMap: {},
    };
    for (let requestId of Object.keys(formData.serviceOfferingAllocations)) {

      if (!request.requestIdToServiceOfferingAllocationMap![requestId] || request.requestIdToServiceOfferingAllocationMap![requestId].length === 0) {
        request.requestIdToServiceOfferingAllocationMap![requestId] = [];
      }
      request.requestIdToServiceOfferingAllocationMap![requestId] = formData.serviceOfferingAllocations[requestId].map(x => {
        return {
          serviceOfferingId: x.serviceOfferingId,
          percent: x.percent / 100.0,
        };
      });
    }
    try {
      let res = await updateServiceOfferingAllocations(serviceProviderId, request );
      if (res.status === 200) {
        createSuccessToast('Service offering allocations updated');
      }
    } catch (err:any) {
      console.error(err);
      createErrorToast(err.response.data.message);
    }
  }


  return {
    loadingKey,
    ...get(),
    init,
    onDropFile,
    onUpload,
    uploadCustomers,
    onDelete,
    onStep1SelectedProviderChanged,
    onReconcile,
    onEditServiceAllocationsSelectedProviderChanged,
    onSubmitEditAllocations,
  };
}