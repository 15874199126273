import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { Controller, useFormContext } from 'react-hook-form';

export default function HookCheckbox({ name, label, defaultValue, postOnChange, rules, extraProps, labelProps }:any) {
  const methods = useFormContext();
  const { control } = methods;
  const { isError, getErrorMessage } = useErrorState(methods);
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          rules={rules}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={!!field.value}
              inputRef={field.ref}
              onChange={(e) => {
                field.onChange(e.target.checked);
                if (postOnChange) {
                  postOnChange(e);
                }
              }}
              {...extraProps}
            />
          )}
        />
      }
      label={label}
      {...labelProps}
    />
  );
}