import ProviderOnboardingStepper from './ProviderOnboardingStepper';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { Grid } from '@mui/material';

export default function OldProviderOnboarding({ step }) {
  return (
    <>
      <Grid container item padding={1}>
        <PageHeader headerText={'Provider Onboarding'} />
      </Grid>
      <ProviderOnboardingStepper step={step}/>
    </>
  );
}