import PriceFormTabs from './PriceFormTabs';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import useToast from 'components/toast/useToast';
import styles from '../providerOnboarding.module.css';
import { Card, FormControl, FormHelperText, Grid, InputLabel, Typography } from '@mui/material';
import { HookTextField, HookSelect } from 'components/reactHookForm';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { PrimaryButton } from 'components/buttons';
import { IDiscountSchedule, DiscountScheduleTypes, DiscountScheduleStandardOptions, IDiscountScheduleWithStandardOption, IDiscountScheduleDetails } from 'model/discountSchedule';
import { useLoading } from 'components/Layout/Loading';
import { createValidateNumber } from 'util/rhfValidateFunctions';
import { useEffect } from 'react';
import { saveDiscountSchedule } from 'api/discountScheduleApi';
import { saveNeighborhoodServiceOffering } from 'api/neighborhoodServiceOfferingApi';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import {
  createDefaultDiscSchedByServiceAndCadence,
  getDiscountScheduleStandardOptionFromSchedule,
  shouldServiceTypeHavePrice,
} from '../helper';
import { useNavigate, useParams } from 'react-router-dom';

import useMarkets from 'hooks/useMarkets';
import useProviderSetupStore from '../useProviderSetupStore';

const validateNumber = createValidateNumber('Can only have numbers');
const loadingKey = 'ProviderOnboardingDiscountScheduleForm';

export default function DiscountScheduleForm() {
  const {
    cadenceType,
    currentDiscountSchedule,
    currentServiceOffering,
    init,
    serviceType,
    setCurrentDiscountSchedule,
  } = useProviderSetupStore();
  const { market } = useMarkets();
  const { providerId, serviceOfferingId, discountScheduleId } = useParams();

  const formContext = useForm<IDiscountScheduleWithStandardOption>(
    {
      mode: 'onTouched',
      defaultValues: currentDiscountSchedule?.id ?
        currentDiscountSchedule :
        createDefaultDiscSchedByServiceAndCadence(serviceType, cadenceType),
    },
  );
  const { isError, getErrorMessage } = useErrorState(formContext);
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    void init(market);
  }, [market]);

  useEffect(() => {
    if (currentDiscountSchedule?.id) {
      formContext.reset({
        ... currentDiscountSchedule,
        priceSuffix: currentDiscountSchedule.priceSuffix || '', // for scenarios where priceSuffix is null on backend
        priceSubText: currentDiscountSchedule.priceSubText || '', // for scenarios where priceSubText is null on backend
        standardOption: getDiscountScheduleStandardOptionFromSchedule(currentDiscountSchedule),
      });
    }
  }, [currentDiscountSchedule]);

  async function createDiscountSchedule(data:IDiscountScheduleWithStandardOption): Promise<any> {
    if (data.type === DiscountScheduleTypes.NoDiscount) {
      delete data.discountSchedule;
      delete data.pricingSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarPerCustomer) {
      delete data.discountSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarSavePerCustomer) {
      delete data.pricingSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarFlatRate) {
      delete data.pricingSchedule;
      delete data.discountSchedule;
    } else if (data.type === DiscountScheduleTypes.PercentFlatRate) {
      delete data.pricingSchedule;
      delete data.discountSchedule;
    }
    if (providerId) {
      data.providerId = providerId;
    }
    if (currentServiceOffering?.serviceTypeId) {
      data.serviceTypeId = currentServiceOffering.serviceTypeId;
    }
    if (data.noPriceTextField && data.priceLabel) {
      createErrorToast('You cannot have both price and no price fields set');
      return;
    }
    if (data.hasPrice) {
      delete data.noPriceTextField;
    } else {
      delete data.priceAmount;
      delete data.priceLabel;
      delete data.priceSuffix;
      delete data.priceSubText;
      delete data.unitBasedPricingFlag;
    }
    delete data.standardOption;
    return saveDiscountSchedule(data);
  }

  const onSubmit : SubmitHandler<IDiscountScheduleWithStandardOption> = async data => {
    try {
      onLoading();
      const response = await createDiscountSchedule(data);
      if (response) {
        if (!currentDiscountSchedule?.id) {
          setCurrentDiscountSchedule(response.data);
          await generateNso(providerId, currentServiceOffering?.id, response.data.id);
        } else {
          setCurrentDiscountSchedule(response.data);
        }
      }
      doneLoading();

      if (response) {
        navigate(`/providerOnboarding/${providerId}/summary`);
      }

    } catch (error:any) {
      doneLoading();
      createErrorToast('Error creating discount schedule: ', error.message);
      console.error('error creating discount schedule:', { error, data });
    }
  };

  function onScheduleTypeChange(ev) {
    const { options, discountType } = DiscountScheduleStandardOptions.standardOptionsMap[ev.target.value];
    formContext.setValue('discountSchedule', options as IDiscountScheduleDetails);
    formContext.setValue('type', discountType);
  }

  async function generateNso(provId, soId, discSchedId) {
    if (!provId || !soId || !discSchedId) {
      console.log('missing required data to generate NSO');
      return;
    }
    let nso:INeighborhoodServiceOffering = {
      name: serviceType,
      altName: '',
      serviceOfferingId: soId,
      discountScheduleId: discSchedId,
      neighborhoodId: window.REACT_APP_NEIGHBORVILLE_UUID,
      providerId: provId,
      disabledDate: null,
    };
    const request = {
      neighborhoodServiceOfferings: [nso],
    };

    try {
      await saveNeighborhoodServiceOffering(request);
    } catch (error) {
      console.error('error creating NSO: ', { error, request });
    }
  }

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
          <Card sx={{ width: '70%', paddingLeft: '50px', paddingBottom: '50px' }}>
            <PriceFormTabs defaultHasPrice={shouldServiceTypeHavePrice(serviceType)} />
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ marginTop: '20px', marginBottom: '20px' }}>Schedule Details</Typography>
            </Grid>
            <Grid item xs={5}>
              <FormControl variant='standard' fullWidth error={isError('type')}>
                <InputLabel htmlFor="discount-schedule-standard-option">Schedule</InputLabel>
                <HookSelect
                  control={formContext.control}
                  rules={{
                    required: { value: true, message: 'Required' },
                  }}
                  name='standardOption'
                  id='discount-schedule-standard-option'
                  menuItems={DiscountScheduleStandardOptions.list}
                  postOnChange={onScheduleTypeChange}
                />
                <FormHelperText>{getErrorMessage('type')}</FormHelperText>
              </FormControl>
            </Grid>
          </Card>
          <Grid item xs={4}>
            <PrimaryButton type='submit' sx={{ marginTop: '40px' }}>Save & Continue</PrimaryButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}