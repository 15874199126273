import { Box, Grid, Typography } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import useProviderSetupStore from 'pages/providerSetup/useProviderSetupStore';
import { useNavigate, useParams } from 'react-router-dom';
import { formatMonthDayTime, formatMonthDayYearTime } from 'util/dateUtil';


export default function SummaryActionBar() {
  const { provider } = useProviderSetupStore();
  const navigate = useNavigate();
  const { providerId } = useParams();
  return (
    <Grid container justifyContent={{ xs: 'center', md: 'flex-start' }}>
      <Grid item sx={{ paddingBottom: '20px' }}>
        <PrimaryButton
          onClick={() => {
            navigate(`/providerCoverageArea/${providerId}`);
          }}
        >
          <>Coverage Area</>
        </PrimaryButton>
      </Grid>
      <Grid item xs={12} sm={12} md='auto'>
        <Box sx={{
          width: '10px',
        }}></Box>
      </Grid>
      <Grid item >
        <Grid item xs={12}>
          <PrimaryButton
            onClick={() => {
              navigate(`/neighborhoodLaunchTooling/${providerId}/launch`);
            }}
          >
            <>Launch</>
          </PrimaryButton>
        </Grid>
        <Grid item xs='auto'>
          <Typography variant='caption'>
          Launched: {provider.serviceOfferingLaunchDate ? formatMonthDayYearTime(provider.serviceOfferingLaunchDate!): 'Not Launched'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}