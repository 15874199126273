import { Grid, Chip } from '@mui/material';
import { RemoveButton } from 'components/buttons';
import { HookTextField } from 'components/reactHookForm';
import useServiceOfferingForm from 'hooks/serviceOffering/useServiceOfferingForm';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { AddListItemButton, DraggableServiceOfferingCardContainer } from '../common';
import CustomFieldOptionsSuggestions from './CustomFieldOptionsSuggestions';

export default function CustomFieldOptions({ customField }:any) {
  const { control } = useFormContext();
  const { createEmptyCustomFieldOption } = useServiceOfferingForm(null);
  const { fields: customFieldOptions, append, move, remove } = useFieldArray({
    control,
    name: `${customField}.customFieldOptions`,
  });

  return (
    <Grid container item xs={12} alignItems='center'>
      <Grid item xs={12}>
        <CustomFieldOptionsSuggestions
          append={append}
          customFieldKey={`${customField}.customFieldOptions.suggestions`}
        />
      </Grid>
      <Grid container item xs={12} alignItems='center'>
        <AddListItemButton
          label='Add  option'
          onClick={() => append(createEmptyCustomFieldOption())}
        />
      </Grid>

      <DraggableServiceOfferingCardContainer
        droppableId='customFieldOptions'
        onDragEnd={(result:any) => {
          if (result.source && result.destination) {
            move(result.source.index, result.destination.index);
          }
        }}
        items={customFieldOptions}
        render={(index:number, onDelete:any) =>(
          <Grid container item alignItems='center' xs={12}>
            <Grid item xs='auto' sx={{ marginRight: '10px' }}>
              <Chip label={`${index+1}`}/>
            </Grid>
            <Grid item xs>
              <HookTextField
                name={`${customField}.customFieldOptions.${index}.optionText`}
                label='Option text'
                required
                validationRules={{
                  validate: function(value) {
                    if (!value || !value.trim()) {
                      return 'Must not be blank';
                    }
                  },
                }}
              />
            </Grid>
            <Grid item xs='auto'>
              <RemoveButton
                tooltipTitle="Delete this custom field option"
                onClick={() => {
                  onDelete(() => remove(index));
                }}
              />
            </Grid>
          </Grid>
        )}
      />

    </Grid>
  );
}