import Axios, { AxiosResponse } from 'axios';
import { ICreateTsoiManagementBatchRequest, ITsoiManagementBatch } from 'model/tsoiManagementBatch';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function createTsoiManagementBatch(data: ICreateTsoiManagementBatchRequest):Promise<AxiosResponse<ITsoiManagementBatch>> {
  return Axios.post(`${apiUrl}/tsoiManagement/v2/createTsoiManagementBatch`, data);
}

export function getTsoiManagementBatchById(id: String):Promise<AxiosResponse<ITsoiManagementBatch>> {
  return Axios.get(`${apiUrl}/tsoiManagement/v2/getTsoiManagementBatch/${id}`);
}

export function updateTsoiManagementBatch(data: ITsoiManagementBatch):Promise<AxiosResponse<ITsoiManagementBatch>> {
  return Axios.put(`${apiUrl}/tsoiManagement/v2/updateTsoiManagementBatch/${data.id}`, data);
}

export function updateTsoiManagementBatchTerritoryServiceOfferings(batchId, territoryId, data: ITsoiManagementBatch):Promise<AxiosResponse<ITsoiManagementBatch>> {
  return Axios.put(`${apiUrl}/tsoiManagement/v2/updateTsoiManagementBatch/${batchId}/territory/${territoryId}/serviceOfferings`, data);
}

export function findTsoiManagementBatchesByMarket(marketId):Promise<AxiosResponse<ITsoiManagementBatch[]>> {
  return Axios.get(`${apiUrl}/tsoiManagement/v2/getTsoiManagementBatchesForMarket/${marketId}`);
}

export function submitTsoiManagementBatch(batchId):Promise<AxiosResponse<ITsoiManagementBatch>> {
  return Axios.post(`${apiUrl}/tsoiManagement/v2/submitTsoiManagementBatch/${batchId}`);
}

export function submitTsoiManagementBatchInviteOnly(batchId):Promise<AxiosResponse<ITsoiManagementBatch>> {
  return Axios.post(`${apiUrl}/tsoiManagement/v2/submitTsoiManagementBatchInviteOnly/${batchId}`);
}

export function deleteTsoiManagementBatch(batchId):Promise<AxiosResponse<void>> {
  return Axios.delete(`${apiUrl}/tsoiManagement/v2/deleteTsoiManagementBatch/${batchId}`);
}