
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { createUninitializedDropdownOption, IDropdownOption } from 'model/dropdown';
import { findCoverageAreaDTOsByServiceProvider } from 'api/providerCoverageAreaApi';
import { assignCoverageArea, findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { IProviderCoverageAreaDTO } from 'model/providerCoverageArea';
import { IAssignCoverageAreaDTO } from 'model/serviceOffering';
import { debounce } from '@mui/material';

const loadingKey = 'CoverageAreaAssignment';
type CoverageAreaAssignment = {
  providerCoverageAreaOptions: IDropdownOption[];
}

const { get, update, registerListener, unregisterListener } = createStore<CoverageAreaAssignment>('CoverageAreaAssignment', {
  providerCoverageAreaOptions: [],
});


export default function useCoverageAreaAssignment() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(serviceProviderId:string) {
    void refreshProviderCoverageAreas(serviceProviderId);
  }

  async function getServiceOfferings(serviceProviderId:string) {
    if (!serviceProviderId) {
      return [];
    }
    const serviceOfferingsRes = await findServiceOfferingsByProvider(serviceProviderId);
    return serviceOfferingsRes.data;
  }

  //might not need this. depends on if the init code runs when navigating to provider coverage area page and back
  async function refreshProviderCoverageAreas(serviceProviderId:string) {
    const providerCoverageAreaOptions = await getProviderCoverageAreas(serviceProviderId);
    update({
      ...get(),
      providerCoverageAreaOptions,
    });
  }

  async function onAssignCoverageArea(selectedServiceOfferingId:string, providerCoverageAreaId:string| null) {
    try {
      const data:IAssignCoverageAreaDTO = {
        serviceOfferingId: selectedServiceOfferingId,
        providerCoverageAreaId,
      };
      await assignCoverageArea(data);
    } catch (e) {
      console.error('could not assign coverage area', e);
    }
  }

  function updateCoverageAreaData(data:IProviderCoverageAreaDTO[]) {
    const providerCoverageAreaOptions = _createProviderCoverageAreaOptions(data);
    update({
      ...get(),
      providerCoverageAreaOptions,
    });
  }

  async function getProviderCoverageAreas(serviceProviderId:string): Promise<IDropdownOption[]> {
    if (!serviceProviderId) {
      return [];
    }
    const providerCoverageAreaDtosRes = await findCoverageAreaDTOsByServiceProvider(serviceProviderId);
    return _createProviderCoverageAreaOptions(providerCoverageAreaDtosRes.data);
  }

  function _createProviderCoverageAreaOptions(providerCoverageAreaDtos:IProviderCoverageAreaDTO[]):IDropdownOption[] {
    return [createUninitializedDropdownOption('Clear'), ...providerCoverageAreaDtos.map(x => ({
      key: x.providerCoverageArea.id ?? '',
      optionValue: x.providerCoverageArea.id ?? '',
      optionText: x.providerCoverageArea.name,
    }
    ))];
  }

  return {
    ...get(),
    init,
    onAssignCoverageArea,
    getServiceOfferings,
    refreshProviderCoverageAreas,
    updateCoverageAreaData,
    loadingKey,
  };
}