import { Grid } from '@mui/material';
import CopyButton from 'components/buttons/CopyButton';
import { useFormContext } from 'react-hook-form';

export default function CustomerUploadKeyDisplay() {
  const formContext = useFormContext();
  const customerUploadKey = formContext.getValues('customerUploadKey');
  return (
    <Grid container item xs={12}>
      <Grid item xs='auto'>
      Customer upload key: {customerUploadKey ?? 'N/A'}
      </Grid>
      {customerUploadKey && (
        <Grid item xs='auto'>
          <CopyButton
            message={customerUploadKey}
          />
        </Grid>
      )}

    </Grid>
  );
}