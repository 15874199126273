import ProviderOnboardingStepper from './ProviderOnboardingStepper';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { Grid } from '@mui/material';

export default function ProviderOnboarding({ step, mode }) {
  return (
    <Grid container justifyContent={'center'} >
      <Grid item xs={12}>
        <PageHeader headerText={'Provider Onboarding'} sx={{ padding: '0 10px' }}/>
      </Grid>
      <Grid item xs={12} sm={11}>
        <ProviderOnboardingStepper step={step} mode={mode}/>
      </Grid>
    </Grid>
  );
}