import { Tooltip, Link, Typography, MenuItem } from '@mui/material';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import useProviderSetupStore from 'pages/providerSetup/useProviderSetupStore';

export interface IClaimBusinessLinkProps {
  nso: INeighborhoodServiceOffering;
}

export default function ClaimBusinessLink({ nso }: IClaimBusinessLinkProps) {
  if (!nso?.id) {
    return <></>;
  }
  const { buildClaimBusinessUri } = useProviderSetupStore();
  const link = buildClaimBusinessUri(nso.id!);

  return (
    <Link href={link} target="_blank" rel='noreferrer'>

      <Tooltip title={'View the "Claim your business" page on provider.streetfair.com'} >
        <Typography>CYB Link</Typography>
      </Tooltip>

    </Link>
  );
}

