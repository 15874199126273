import PriceFormTabs from './PriceFormTabs';
import useToast from 'components/toast/useToast';
import { Card, Grid, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { PrimaryButton } from 'components/buttons';
import { DiscountScheduleStandardOptions, IDiscountScheduleWithStandardOption } from 'model/discountSchedule';
import { useLoading } from 'components/Layout/Loading';
import { useEffect } from 'react';
import {
  createDefaultDiscSchedByServiceAndCadence,
  createDiscountSchedule,
  getDiscountScheduleStandardOptionFromSchedule,
  shouldServiceTypeHavePrice,
} from '../helper';
import { useNavigate, useParams } from 'react-router-dom';
import useMarkets from 'hooks/useMarkets';
import useProviderSetupStore from '../useProviderSetupStore';
import DiscountScheduleSelect from './DiscountScheduleSelect';
import { NSOCreationUtil } from 'util/data/nsoCreationUtil';
import { isGuid } from 'util/guidUtil';

const loadingKey = 'ProviderOnboardingDiscountScheduleForm';

export default function DiscountScheduleForm() {
  const {
    cadenceType,
    currentDiscountSchedule,
    currentServiceOffering,
    init,
    serviceType,
    setCurrentDiscountSchedule,
  } = useProviderSetupStore();
  const { market } = useMarkets();
  const { providerId, serviceOfferingId, discountScheduleId } = useParams();

  const formContext = useForm<IDiscountScheduleWithStandardOption>(
    {
      mode: 'onTouched',
      defaultValues: currentDiscountSchedule?.id ?
        currentDiscountSchedule :
        createDefaultDiscSchedByServiceAndCadence(serviceType, cadenceType),
    },
  );

  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    void init(market);
  }, [market]);

  useEffect(() => {
    if (currentDiscountSchedule?.id) {
      if (isGuid(currentDiscountSchedule.id)) {
        formContext.reset({
          ... currentDiscountSchedule,
          priceSuffix: currentDiscountSchedule.priceSuffix || '', // for scenarios where priceSuffix is null on backend
          priceSubText: currentDiscountSchedule.priceSubText || '', // for scenarios where priceSubText is null on backend
          standardOption: DiscountScheduleStandardOptions.Existing,
        });
      } else {
        formContext.reset({
          ... currentDiscountSchedule,
          priceSuffix: currentDiscountSchedule.priceSuffix || '', // for scenarios where priceSuffix is null on backend
          priceSubText: currentDiscountSchedule.priceSubText || '', // for scenarios where priceSubText is null on backend
          standardOption: getDiscountScheduleStandardOptionFromSchedule(currentDiscountSchedule),
        });
      }

    }
  }, [currentDiscountSchedule]);


  const onSubmit : SubmitHandler<IDiscountScheduleWithStandardOption> = async data => {
    try {
      onLoading();
      if (data.noPriceTextField && data.priceLabel) {
        createErrorToast('You cannot have both price and no price fields set');
        return;
      }
      const response = await createDiscountSchedule(data, providerId, currentServiceOffering);
      if (response) {
        if (!currentDiscountSchedule?.id) {
          setCurrentDiscountSchedule(response.data);
          await NSOCreationUtil.generateNeighborvilleNso({
            serviceProviderId: providerId!,
            serviceOfferingId: currentServiceOffering?.id!,
            serviceType: serviceType,
            discountScheduleId: response.data.id,
          });
        } else {
          setCurrentDiscountSchedule(response.data);
        }
      }
      doneLoading();

      if (response) {
        navigate(`/providerOnboarding/${providerId}/summary`);
      }

    } catch (error:any) {
      doneLoading();
      createErrorToast('Error creating discount schedule: ', error.message);
      console.error('error creating discount schedule:', { error, data });
    }
  };


  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
          <Card sx={{ width: '70%', paddingLeft: '50px', paddingBottom: '50px' }}>
            <PriceFormTabs defaultHasPrice={shouldServiceTypeHavePrice(serviceType)} />
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ marginTop: '20px', marginBottom: '20px' }}>Schedule Details</Typography>
            </Grid>
            <Grid item xs={5}>
              <DiscountScheduleSelect/>
            </Grid>
          </Card>
          <Grid item xs={4}>
            <PrimaryButton type='submit' sx={{ marginTop: '40px' }}>Save & Continue</PrimaryButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}