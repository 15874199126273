import { format } from 'date-fns';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';


export function formatLocalServiceDate(date: string | Date):string {
  const normalized = normalize(date);
  return format(normalized, 'yyyy-MM-dd');
}

function normalize(date:string | Date):Date {
  if (typeof date === 'string') {
    return parseISO(date);
  }
  return date;
}


export function createDateIgnoreTimezone(date) {
  let originalDate = new Date(date);
  return new Date(originalDate.valueOf() + originalDate.getTimezoneOffset() * 60 * 1000);
}

export function formatMonthDayTime(date:string | Date | null):string {
  if (!date) {
    return '';
  }
  let normalized = normalize(date);
  return formatDate(normalized, 'MMM dd, h:mm aa');
}


export function formatMonthDayYearTime(date:string | Date | null):string {
  if (!date) {
    return '';
  }
  let normalized = normalize(date);
  return formatDate(normalized, 'MMM dd yyyy, h:mm aa');
}

export function formatMonthDay(date) {
  if (!date) {return '';}
  return formatDate(parseISO(date), 'MMM-dd');
}

export function formatMonthDayYear(date) {
  if (!date) {return '';}
  return formatDate(parseISO(date), 'MM/dd/yyyy');
}

/**
 * Formats like Nov 1, 2021
 * @param date
 * @returns
 */
export function formatLongMonthDayYear(date) {
  if (!date) {return '';}
  const normalized = normalize(date);
  return formatDate(normalized, 'MMM dd, yyyy');
}