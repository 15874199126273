import Spacer from 'components/Layout/Spacer';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { Button, Grid } from '@mui/material';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import GenericReferAProConfirmationModal, { GenericReferAProConfirmationModalKey } from './GenericReferAProConfirmationModal';

export default function SendGenericReferAProEmailForm(props: any) {
  const { openModal } = useConfirmationModal(GenericReferAProConfirmationModalKey);

  const onSend = () => {
    openModal({ sendToMeAsTest: false });
  };

  const onSendToMeAsTest = () => {
    openModal({ sendToMeAsTest: true });
  };
  return (
    <Grid container item xs={12} spacing={1} className='pageGridContainer' paddingBottom={'25px'} paddingTop={'200px'}>
      <Grid container item xs={12}>
        <PageHeader headerText={'Generic Refer a Pro email'}/>
      </Grid>
      <Grid item xs={12}>
        <Grid container item spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              onClick={onSend}
            >
              <span>Send</span>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={onSendToMeAsTest}
            >
              <span>Send to me as test</span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Spacer/>
      <GenericReferAProConfirmationModal/>
    </Grid>
  );
}