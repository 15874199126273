
import { Grid, Typography } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import useToast from 'components/toast/useToast';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { HookTextField } from 'components/reactHookForm';
import useReferAProPostReviewTesting from './useReferAProPostReviewTesting';
import { IReferAProPostReviewTestingForm } from 'model/testing/refer_a_pro/refer_a_pro';

export default function ReferAProPostReviewTestingForm() {

  const { onSubmitForm } = useReferAProPostReviewTesting();
  const { createErrorToast } = useToast();
  const formContext = useForm<IReferAProPostReviewTestingForm>({
    mode: 'onTouched',
    defaultValues: {
      customerId: '',
    },
  });
  const onSubmit : SubmitHandler<IReferAProPostReviewTestingForm> = data => {
    onSubmitForm(data).catch((err:any) => {
      createErrorToast(err.response.data.message);
    });
  };


  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <Grid container spacing={1} >
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography variant='body1'>This customer must be complete and have left a positive rating for the provider</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <HookTextField
                  required
                  name='customerId'
                  label='Customer Id'
                />
              </Grid>
            </Grid>
            <Grid item xs={11} sx={{ marginTop: '20px' }}>
              <PrimaryButton type='submit'>Submit</PrimaryButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}