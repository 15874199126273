import { FormControl, FormHelperText, Grid, IconButton, InputLabel, TextField } from '@mui/material';
import { RemoveButton } from 'components/buttons';
import AddButton from 'components/buttons/AddButton';
import Spacer from 'components/Layout/Spacer';
import { HookSelect, HookTextField } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import useServiceOfferingForm from 'hooks/serviceOffering/useServiceOfferingForm';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { ServiceOfferingCardContainer, StepHeader } from '../common';
import { ServiceDetailListItems } from './ServiceDetailListItems';
import ServiceDetailItems from './ServiceDetailItems';

export interface ISortChangeProps {
  items:any[];
  index:number;
  move:any;
  onMove:any;
  setMoved:any;
  moved:boolean;
}

export default function ServiceDetailSections() {
  const formCtx = useFormContext();
  const { control } = formCtx;
  const { createEmptyServiceDetailSection } = useServiceOfferingForm(null);
  const { fields: serviceDetailSections, replace, remove, move, append } = useFieldArray({
    control,
    name: 'serviceDetailSections' as const,
  });
  const watchServiceDetailSections = useWatch({ control, name: 'serviceDetailSections' });
  useEffect(() => {
    if (serviceDetailSections.length !== watchServiceDetailSections.length) {
      replace(watchServiceDetailSections);
    }
  }, [watchServiceDetailSections]);

  const { isError, getErrorMessage } = useErrorState(formCtx);
  const sectionIconOptions = [
    { id: 'listIcon', name: 'List' },
    { id: 'calendarIcon', name: 'Calendar' },
    { id: 'receiptIcon', name: 'Receipt' },
  ];

  return (
    <Grid container item alignItems='center' sx={{ width: '100%' }}>
      <Grid item xs='auto'>
        <StepHeader>Add Service Detail Section</StepHeader>
      </Grid>
      <Grid item xs='auto'>
        <AddButton
          tooltipTitle="Add a service detail section"
          onClick={() => append(createEmptyServiceDetailSection())}
        />
      </Grid>
      <Spacer/>
      <ServiceOfferingCardContainer
        items={serviceDetailSections}
        render={(index:number, onDelete:any, sortOrder:number, onSortOrderChange:any, onMove:any) => (
          <Grid container spacing={1}>
            <Grid container item xs={12} alignItems='center'>
              <Grid item xs>
                <HookTextField
                  name={`serviceDetailSections.${index}.sectionHeaderName`}
                  label='Header name'
                  required
                />
              </Grid>
              <Grid item xs='auto'>
                <RemoveButton
                  tooltipTitle="Delete this section"
                  onClick={() => onDelete(() => remove(index))}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems='center'>
              <Grid item xs>
                <FormControl variant='standard' fullWidth error={isError(`serviceDetailSections.${index}.sectionIcon`)}>
                  <InputLabel htmlFor={`serviceDetailSections.${index}.sectionIcon`}>Section Icon</InputLabel>
                  <HookSelect
                    control={formCtx.control}
                    name={`serviceDetailSections.${index}.sectionIcon`}
                    id={`serviceDetailSections.${index}.sectionIcon`}
                    menuItems={sectionIconOptions}
                  />
                  <FormHelperText>{getErrorMessage(`serviceDetailSections.${index}.sectionIcon`)}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ServiceDetailItems
                serviceDetailSection={`serviceDetailSections.${index}`}
              />
            </Grid>
            <Grid item xs={12}>
              <ServiceDetailListItems
                serviceDetailSection={`serviceDetailSections.${index}`}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='standard'
                size='small'
                label='sort order'
                value={sortOrder}
                onChange={onSortOrderChange}
                sx={{
                  width: '200px',
                }}
              />
              <IconButton
                type='button'
                onClick={() => {
                  if (sortOrder >= 0 && sortOrder < serviceDetailSections.length) {
                    move(index, sortOrder);
                    onMove();
                  }
                }}>
                <SwapHorizOutlinedIcon/>
              </IconButton>
            </Grid>
          </Grid>
        )}/>
    </Grid>
  );
}