import { Box } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { ServiceProviderFormContext } from 'contexts/serviceProvider/useServiceProviderForm';
import { useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export default function FormButtons() {
  const navigate = useNavigate();
  const { serviceProviderId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const providerSetup = queryParams.get('provider-setup');
  const { isImageUploading } = useContext(ServiceProviderFormContext);
  return (
    <>
      <PrimaryButton
        type='submit'
        disabled={isImageUploading}
      >
        Save
      </PrimaryButton>
      <Box sx={{ width: '10px', display: 'inline-block' }}/>
      <SecondaryButton
        onClick={() => {
          if (providerSetup == 'true') {
            navigate(`/providerOnboarding/${serviceProviderId}/summary`);
          } else {
            navigate('/service-providers');
          }
        }}
      >
        Cancel
      </SecondaryButton>
    </>
  );
}