import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import ClaimBusinessLink from './ClaimBusinessLink';
import { CadenceType, IServiceOffering } from 'model/serviceOffering';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { IDiscountSchedule } from 'model/discountSchedule';
import DetailItem from './DetailItem';
import AssignCoverageArea from './AssignCoverageArea';
import CloneFromButton from './CloneFromButton';
import EditServiceOfferingLink from './EditServiceOfferingLink';
import NeighborvilleNsoLink from './NeighborvilleNsoLink';
import EditDiscountScheduleLink from './EditDiscountScheduleLink';

interface IServiceOfferingSummaryCardProps {
  serviceOffering: IServiceOffering;
  index: number;
  nso: INeighborhoodServiceOffering;
  discountSchedule: IDiscountSchedule;
}

export default function ServiceOfferingSummaryCard({ serviceOffering, index, nso, discountSchedule }:IServiceOfferingSummaryCardProps) {
  return (
    <Grid container item xs={12} sm='auto' sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
      <Card sx={{ padding: '15px', margin: '8px', minWidth: '400px' }}>
        <Grid container alignItems={'center'} sx={{ paddingBottom: '8px' }}>
          <Grid item xs sm>
            <Typography sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>{serviceOffering?.serviceType}</Typography>
          </Grid>
          <Grid item xs='auto' sm='auto' sx={{ paddingRight: '8px' }}>
            <EditServiceOfferingLink serviceOffering={serviceOffering}/>
          </Grid>
          <Grid item xs='auto' sm='auto' sx={{ paddingRight: '8px' }}>
            <NeighborvilleNsoLink nso={nso}/>
          </Grid>
          <Grid item xs='auto' sm='auto' sx={{ paddingRight: '8px' }}>
            <ClaimBusinessLink nso={nso}/>
          </Grid>
          <Grid item xs='auto' sm='auto' sx={{ paddingRight: '8px' }}>
            <CloneFromButton serviceOffering={serviceOffering}/>
          </Grid>
        </Grid>
        <DetailItem
          label="Name:"
          value={serviceOffering?.name}
        />
        <DetailItem
          label="Discount: "
          value={discountSchedule?.name}
          linkWidget={
            <Grid item xs='auto' sx={{ paddingLeft: '8px' }}>
              <EditDiscountScheduleLink serviceOffering={serviceOffering}/>
            </Grid>
          }
        />
        <Box sx={{ padding: '20px 0' }}>
          <Divider />
        </Box>
        <Grid container item sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
          <AssignCoverageArea serviceOffering={serviceOffering}/>
        </Grid>
      </Card>
    </Grid>
  );
}