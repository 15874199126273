import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { findMarkets } from 'api/marketsApi';
import { IDropdownOption } from 'model/dropdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useToast from 'components/toast/useToast';
import { IMarket, MarketUtils } from 'model/markets';
import { findInternalServiceProviderById, saveServiceProvider } from 'api/serviceProviderApi';
import { convertFormattedPercentage, formatPercent } from 'util/numberUtil';
import { IInternalServiceProvider, PaymentTypes, createEmptyInternalServiceProvider, createEmptyInternalServiceProviderWithDefaults } from 'model/serviceProvider';
import useMarkets from 'hooks/useMarkets';
import { checkNumbersAgainstDNCList } from 'api/phoneNumberValidatorApi';
import { ICheckNumbersAgainstDNCListRequest } from 'model/phoneNumberValidation';
import { getAllServiceProviderPhoneNumbersList } from 'util/serviceProviderUtil';
import { findExistingProviderUsers } from 'api/userApi';
import { createEmptyExistingProviderUserResponse, IExistingProviderUserResponse } from 'model/user';


const loadingKey = 'ServiceProviderDetailStore';
type ServiceProviderDetailStore = {
  formData: IInternalServiceProvider;
  markets: IDropdownOption[];
  initialPhoneNumbers: string[];
  existingUsersDto: IExistingProviderUserResponse;
}

const { get, update, registerListener, unregisterListener } = createStore<ServiceProviderDetailStore>('ServiceProviderDetailStore', {
  formData: createEmptyInternalServiceProvider(),
  markets: [],
  initialPhoneNumbers: [],
  existingUsersDto: createEmptyExistingProviderUserResponse(),
});


export default function useServiceProviderDetail() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const providerSetup = queryParams.get('provider-setup');
  const { createErrorToast, createErrorHandlingToast, createInfoToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        existingUsersDto: createEmptyExistingProviderUserResponse(),
      });
      unregisterListener(setState);
    };
  }, []);

  async function init(market:IMarket | null) {

    if (market == null) {
      console.log('waiting to load market');
      //we should wait for the market to load before making any requests
      return;
    }
    onLoading();
    try {
      let formData = createEmptyInternalServiceProvider();
      if (serviceProviderId) {
        const response = await findInternalServiceProviderById(serviceProviderId);

        if (response.data) {
          formData = response.data;
          formData.transientAdditionalContactPhones = response.data.additionalContactPhones.map( x=> ({ value: x }));
          formData.acceptsCreditCard = response.data.paymentTypesAccepted.includes(PaymentTypes.CREDITCARD);
          formData.acceptsCashApp = Boolean(response.data.cashAppId);
          formData.acceptsVenmo = Boolean(response.data.venmoId);
          formData.recurringPercent = formatPercent(response.data.recurringPercent);
          formData.oneTimePercent = formatPercent(response.data.oneTimePercent);
          formData.takeRateCap = !response.data.takeRateCap && response.data.takeRateCap !== 0 ? '' : response.data.takeRateCap.toString();
          if (!response.data.marketId) {
            formData.marketId = market.id;
            createInfoToast(`This service provider is not associated with a market. Defaulting to ${market.name}.`);
          }
        } else {
          createErrorToast('Error loading service provider');
        }
      } else {
        formData.marketId = market.id;
      }

      const res = await findMarkets();
      const markets = MarketUtils.convertToDropdownOptions(res.data);
      const initialPhoneNumbers = getAllServiceProviderPhoneNumbersList(formData);

      update({
        ...get(),
        formData,
        markets,
        initialPhoneNumbers: initialPhoneNumbers,
      });

    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }


  async function onSaveServiceProvider(data:any) {
    try {
      const dataToSave = { ...data };
      if (data.transientAdditionalContactPhones && data.transientAdditionalContactPhones.length > 0) {
        dataToSave.additionalContactPhones = data.transientAdditionalContactPhones.map(x => x.value);
      } else if (data.transientAdditionalContactPhones?.length === 0 ) {
        dataToSave.additionalContactPhones = [];
      }

      let _paymentTypesAccepted:string[] = [];
      if (dataToSave.acceptsCreditCard) {
        _paymentTypesAccepted.push(PaymentTypes.CREDITCARD);
      }
      if (dataToSave.acceptsVenmo) {
        _paymentTypesAccepted.push(PaymentTypes.VENMO);
      } else {
        delete dataToSave.venmoId;
      }
      if (dataToSave.acceptsCashApp) {
        _paymentTypesAccepted.push(PaymentTypes.CASHAPP);
      } else {
        delete dataToSave.cashAppId;
      }
      dataToSave.paymentTypesAccepted = _paymentTypesAccepted;
      if (data.recurringPercent) {
        dataToSave.recurringPercent = convertFormattedPercentage(data.recurringPercent);
      }
      if (data.oneTimePercent) {
        dataToSave.oneTimePercent = convertFormattedPercentage(data.oneTimePercent);
      }
      await saveServiceProvider(dataToSave);
      if (providerSetup == 'true') {
        navigate(`/providerOnboarding/${serviceProviderId}/summary`);
      } else {
        navigate('/service-providers');
      }
    } catch (e:any) {
      console.error(e);
      createErrorHandlingToast('Error saving service provider. Data refreshed. ', e);
      await init(data.marketId);
    }
  }

  function werePhoneNumbersUpdated(newPhoneNumbers:string[]) {
    var initialPhoneNumbers = get().initialPhoneNumbers;
    return JSON.stringify(initialPhoneNumbers) !== JSON.stringify(newPhoneNumbers);

  }

  async function maybeCheckNumbersAgainstDNCList(data:any) {
    var phoneNumbersArray = getAllServiceProviderPhoneNumbersList(data);

    if (phoneNumbersArray.length > 0 && werePhoneNumbersUpdated(phoneNumbersArray)) {
      var request:ICheckNumbersAgainstDNCListRequest = {
        phoneNumbers: phoneNumbersArray,
      };
      var resp = await checkNumbersAgainstDNCList(request);

      return resp.data.doNotCallNumbers;
    }
  }

  async function queryExistingProviderUsers() {

    if (!serviceProviderId) {
      return;
    }
    try {
      const res = await findExistingProviderUsers(serviceProviderId);
      if (res.data) {
        update({
          ...get(),
          existingUsersDto: res.data,
        });
        createInfoToast('Existing provider users loaded');
      }
    } catch (e) {
      console.error(e);
    }
  }

  return {
    ...get(),
    loadingKey,
    init,
    onSaveServiceProvider,
    maybeCheckNumbersAgainstDNCList,
    queryExistingProviderUsers,
  };
}