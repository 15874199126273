import { Button } from '@mui/material';
import styles from './PrimaryButton.module.css';

export default function PrimaryOutlinedButton({ children, onClick, ...buttonProps }:any) {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      className={`${styles.primaryButton} ${styles.outlined}`}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}