import { Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { EditButton } from 'components/buttons';
import useProviderSetupStore from '../useProviderSetupStore';
import { Replay } from '@mui/icons-material';
import StatusTypeSelect from './serviceProviderForm/StatusTypeSelect';

export default function ProviderSummary() {
  const { provider, init, hasSupplyManagerRole } = useProviderSetupStore();

  return (
    <>
      <Grid container item sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Grid item>
          <Typography variant="body1" sx={{ alignSelf: 'center' }}>
            <a target="_blank" href={`${window.REACT_APP_PROVIDER_BASE_URI}/v1/${provider?.id}`} >{provider?.name}</a>
          </Typography>
        </Grid>
        <Grid item >
          <Link href={`/service-providers/${provider?.id}/service-provider-details?provider-setup=true`}>
            <EditButton
              tooltipTitle={'View and edit the full provider details on the Service Provider Details page'}
              onClick={() => {}}
            />
          </Link>
          {hasSupplyManagerRole && (
            <Link href={`/providerOnboarding/${provider?.id}`} rel='noreferrer'>
              <Tooltip title={'Revisit this provider in step 1 of this tool'}>
                <IconButton >
                  <Replay />
                </IconButton>
              </Tooltip>
            </Link>
          )}
        </Grid>
        <Grid item xs={12} ></Grid>
        <Grid item xs={12} sm={12} md={9} lg={8}>
          <StatusTypeSelect/>
        </Grid>
      </Grid>
    </>
  );
}