import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { IDropdownOption } from 'model/dropdown';
import useToast from 'components/toast/useToast';
import { resetProviderFromSeedingTemplates, seedNeighborhoodFromTemplate, seedProviderFromTemplates } from 'api/territorySeedingTemplatesApi';

const loadingKey = 'SeedFromTemplate';
type SeedFromTemplate = {
  selectedNeighborhood: IDropdownOption | null;
  selectedServiceProvider: IDropdownOption | null;
  selectedServiceProviderReseed: IDropdownOption | null;
}

const { get, update, registerListener, unregisterListener } = createStore<SeedFromTemplate>('SeedFromTemplate', {
  selectedNeighborhood: null,
  selectedServiceProvider: null,
  selectedServiceProviderReseed: null,
});

export default function useSeedFromTemplate() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function onNeighborhoodSelect(neighborhoodSelection: IDropdownOption | null) {
    try {
      update({
        ...get(),
        selectedNeighborhood: neighborhoodSelection,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function onServiceProviderSelect(serviceProviderSelection: IDropdownOption | null) {
    try {
      update({
        ...get(),
        selectedServiceProvider: serviceProviderSelection,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function onServiceProviderReseedSelect(serviceProviderSelection: IDropdownOption | null) {
    try {
      update({
        ...get(),
        selectedServiceProviderReseed: serviceProviderSelection,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function onConfirmNeighborhoodSeeding() {
    onLoading();
    try {
      const neighborhood = get().selectedNeighborhood;
      if (!neighborhood || !neighborhood.optionValue) {
        createErrorToast('Please select a neighborhood');
        doneLoading(300);
        return;
      }

      await seedNeighborhoodFromTemplate(neighborhood.optionValue);
      createSuccessToast('Successfully initiated neighborhood seeding process!');
    } catch (e : any) {
      console.error(e);
      createErrorToast(`Error seeding neighborhood: ${e.response?.data?.message}`);
    }
    doneLoading(300);
  }

  async function onConfirmProviderSeeding() {
    onLoading();
    try {
      const provider = get().selectedServiceProvider;
      if (!provider || !provider.optionValue) {
        createErrorToast('Please select a Service Provider');
        doneLoading(300);
        return;
      }

      await seedProviderFromTemplates(provider.optionValue);
      createSuccessToast('Successfully initiated service provider seeding process!');
    } catch (e:any) {
      console.error(e);
      createErrorToast(`Error seeding provider: ${e.response?.data?.message}`);
    }
    doneLoading(300);
  }

  async function onConfirmProviderReSeeding() {
    onLoading();
    try {
      const provider = get().selectedServiceProviderReseed;
      if (!provider || !provider.optionValue) {
        createErrorToast('Please select a Service Provider');
        doneLoading(300);
        return;
      }

      await resetProviderFromSeedingTemplates(provider.optionValue);
      createSuccessToast('Successfully initiated service provider reseeding process!');
    } catch (e:any) {
      console.error(e);
      createErrorToast(`Error seeding provider: ${e.response?.data?.message}`);
    }
    doneLoading(300);
  }

  return {
    ...get(),
    onConfirmNeighborhoodSeeding,
    onConfirmProviderSeeding,
    onConfirmProviderReSeeding,
    onNeighborhoodSelect,
    onServiceProviderSelect,
    onServiceProviderReseedSelect,
    loadingKey,
  };
}