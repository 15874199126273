import { Grid, Typography } from '@mui/material';
import { AddButton, RemoveButton } from 'components/buttons';
import Spacer from 'components/Layout/Spacer';
import { HookTextField } from 'components/reactHookForm';
import HookPhoneField from 'components/reactHookForm/HookPhoneField';
import { useEffect } from 'react';
import { createValidatePhone, createValidateEmail, createValidateEmailNotRequired } from 'util/rhfValidateFunctions';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import styles from './serviceProviderDetails.module.css';
import { activeOrGreater } from 'util/serviceProviderUtil';

const validateEmail = createValidateEmail();
const validateEmailNotRequired = createValidateEmailNotRequired('Not a valid email');

export default function AdditionalContactEmails() {
  const { control, getValues } = useFormContext();

  const { fields: additionalContacts, append, replace, remove } = useFieldArray({
    name: 'additionalContacts',
  });
  const watchStatus = useWatch({ control, name: 'status' });
  var isActiveOrGreater = activeOrGreater(watchStatus);
  const watchAdditionalContacts = useWatch({ control, name: 'additionalContacts' });
  useEffect(() => {
    if (additionalContacts.length !== watchAdditionalContacts.length) {
      replace(watchAdditionalContacts);
    }
  }, [watchAdditionalContacts]);

  return (
    <Grid container item spacing={1} alignItems='center'>

      <Grid container alignItems='center' item xs='auto'>
        <Typography>Add Contact</Typography>
        <Grid item xs='auto'>
          <AddButton
            tooltipTitle="Add a contact"
            onClick={() => append({ firstName: '', email: '' })}
          />
        </Grid>
        <Spacer/>
      </Grid>
      {additionalContacts.map((item, index) => {
        return (
          <Grid container item spacing={1} alignItems='center' key={item.id}>
            <Grid container item xs={10} md={4} alignItems='center'>
              <HookTextField
                name={`additionalContacts.${index}.firstName`}
                label={`Additional Contact Name ${index+1}`}
              />
            </Grid>
            <Grid container item xs={10} md={6} alignItems='center'>
              <HookTextField
                name={`additionalContacts.${index}.email`}
                label={`Additional Contact Email ${index+1}`}
                validationRules={{
                  validate: validateEmail,
                }}
              />
            </Grid>

            <Grid container item xs='auto' alignSelf='center' >
              <RemoveButton
                tooltipTitle="Delete this contact"
                onClick={() => {
                  remove(index);
                }}
              />
            </Grid>
          </Grid>
        );
      })}

    </Grid>
  );
}