import { Button, TextField, Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import { sendTestInvite } from 'api/neighborConnectionsApi';
import { IInviteNearbyNeighborTestDTO } from 'model/neighborConnections';
import { useState } from 'react';

export default function InviteNearbyNeighborTest() {

  const [inviteEmail, setInviteEmail] = useState('');
  const [individualId, setIndividualId] = useState('admin+consumer@example.com');
  const [isChecked, setIsChecked] = useState(false);

  const handleIndividualIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIndividualId(e.target.value);
  };

  const handleInviteEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInviteEmail(e.target.value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Grid container item xs={12}>
      <h1>Invite Nearby Neighbor Test</h1>
      <Grid container spacing={1}>

        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label='invite email'
            variant='outlined'
            onChange={handleInviteEmailChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            defaultValue={individualId}
            fullWidth
            label='Referrer Individual ID'
            variant='outlined'
            onChange={handleIndividualIdChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            }
            label="Send to Welcome Page"
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant='outlined'
            onClick={async () => {
              if (!inviteEmail || !individualId) {
                return;
              }
              var data: IInviteNearbyNeighborTestDTO = {
                referringUserEmail: individualId,
                email: inviteEmail,
                sendToWelcomePage: isChecked,
              };
              await sendTestInvite(data);
            }}
          >
            <span>test invite nearby neighbor</span>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}