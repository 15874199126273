import { useFlags } from 'launchdarkly-react-client-sdk';
import OldProviderOnboarding from './old/OldProviderOnboarding';
import ProviderOnboarding from './ProviderOnboarding';

export enum ProviderSetupMode {
  edit = 'edit',
  create = 'create',
}

export interface IProviderSetupChooserProps {
  step?:number;
  mode?:string;
}

export default function ProviderSetupChooser({ step, mode }: IProviderSetupChooserProps) {
  const {
    adminPortalCleanupDec2024,
  } = useFlags();
  if (adminPortalCleanupDec2024) {
    return <ProviderOnboarding step={step} mode={mode} />;
  }
  return <OldProviderOnboarding step={step} />;
}