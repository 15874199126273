/* eslint-disable @typescript-eslint/no-shadow */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, AutocompleteInputChangeReason, AutocompleteProps, Button, Checkbox, TextField } from '@mui/material';
import { IDropdownOption } from 'model/dropdown';
import { Controller, useFormContext } from 'react-hook-form';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface IHookAsyncAutocompleteProps {
  name: string;
  dropdownOptions:IDropdownOption[];
  label:string;
  placeholder:string;
  rules?:any;
  postOnChange?:Function;
  postOnBlur?:Function;
  extraProps?:any;
  onInputChange: Function;
}

export default function HookAsyncAutocomplete({
  name,
  label,
  placeholder,
  rules,
  dropdownOptions,
  postOnChange,
  postOnBlur,
  extraProps,
  onInputChange,
}:IHookAsyncAutocompleteProps) {
  const formContext = useFormContext();
  const { control } = formContext;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => {
        return (
          <Autocomplete
            multiple
            id={`${name}-multiautocomplete`}
            onChange={(e, values:any, reason, details) => {
              let rawValues: IDropdownOption[] = [];
              for (let el of values) {
                if (el.optionValue !== undefined && el.optionValue !== null) {
                  rawValues.push(el);
                }
              }
              if (reason === 'selectOption') {
                let nextValues = values.map(x => x.optionValue ?? x);
                onChange(nextValues);
                if (postOnChange) {
                  postOnChange(formContext, nextValues, reason, details, rawValues);
                }
              } else if (reason === 'createOption') {

              } else if (reason === 'removeOption') {
                let nextValues = values.filter(x => x !== x.optionValue).map(x => x.optionValue ?? x);
                onChange(nextValues);
                if (postOnChange) {
                  postOnChange(formContext, nextValues, reason, details, rawValues);
                }
              } else if (reason === 'blur') {

              } else if (reason === 'clear') {
                onChange([]);
                if (postOnChange) {
                  postOnChange(formContext, [], reason, details, []);
                }
              }

            }}
            isOptionEqualToValue={(option, value) => {
              return option.optionValue === value;
            }}
            ref={ref}
            value={value}
            options={dropdownOptions}
            filterOptions={(x) => x}
            disableCloseOnSelect
            getOptionLabel={(option) => {
              return option?.optionText ?? '';
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.optionText}
              </li>
            )}
            renderTags={(value, getTagProps) => {
              return dropdownOptions.filter(x => {
                return value.indexOf(x.optionValue) > -1;
              }).map(x => (
                <Button
                  key={x.key}
                  variant='outlined'
                  endIcon={<DeleteIcon/>}
                  type='button'
                  size='small'
                  onClick={(e) => {
                    let nextValues = value.filter(y => y !== x.optionValue).map(z => z.optionValue ?? z);
                    let nextRawValues = value.filter(y => y !== x.optionValue);
                    onChange(nextValues);
                    if (postOnChange) {
                      postOnChange(formContext, nextValues, 'removeOption', x, nextRawValues);
                    }
                  }}
                  sx={{
                    fontSize: '11px',
                    margin: '0 2px',
                  }}
                >
                  <span>{x.optionText}</span>
                </Button>
              ));
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label={label} placeholder={placeholder} />
            )}
            onInputChange={(event, newInputValue, reason:AutocompleteInputChangeReason) => {
              onInputChange(newInputValue, reason);
            }}
            {...extraProps}
          />
        );
      }
      }
    />
  );
}