import useIndividualsHookStore from '../individualsHookStore';
import useToast from 'components/toast/useToast';
import CopyButton from 'components/buttons/CopyButton';
import { InfoOutlined } from '@mui/icons-material';
import { Grid, Typography, Tooltip, TextField, InputAdornment } from '@mui/material';
import { generateRefereeGiftReferralLink } from 'api/individualApi';
import { PrimaryButton } from 'components/buttons';
import { useState } from 'react';
import { createValidateNumber } from 'util/rhfValidateFunctions';
import { useLoading } from 'components/Layout/Loading';
import DetailItem from '../_components/DetailItem';
import { useParams } from 'react-router-dom';

const validateNumber = createValidateNumber('Please enter a valid number.');

export default function GiftNeighborReferralLinkGenerator() {
  const {
    individual,
    setNeighorReferralLink,
    neighborReferralLink,
    loadingKey,
  } = useIndividualsHookStore();
  const [isError, setIsError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();
  const { individualId } = useParams();
  let defaultAmountValue = 10;
  let defaultMc = 'og10';
  const [amount, setAmount] = useState(10);
  const [mc, setMc] = useState(defaultMc);

  async function onGenerateButtonClick() {
    try {
      onLoading();
      const resp = await generateRefereeGiftReferralLink(individualId!, amount, mc);
      if (resp?.data?.link) {
        setNeighorReferralLink(resp.data.link);
      }
    } catch (error) {
      console.error('Error generating referral link: ', { error, individualId: individual.id, amount });
      createErrorToast('Error generating referral link');
    }
    doneLoading(300);
  }

  console.log('neighborReferralLink', neighborReferralLink);
  return (
    <Grid container spacing={3}>
      <Grid container item flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant="h6">Gift Neighbors Referral Link Generator</Typography>
        <Tooltip title="This tool will generate a referral link that will include a referee-only reward to be paid out once the users signs up.">
          <InfoOutlined sx={{ fontSize: '20px', marginBottom: '10px', marginLeft: '5px' }}/>
        </Tooltip>
      </Grid>
      <Grid container item spacing={2}>
        <Grid container item flexDirection={'row'} columnSpacing={1}>
          <Grid item>

            <TextField
              label="Reward Amount"
              variant="standard"
              helperText={helperText}
              defaultValue={defaultAmountValue}
              onChange={(event) => {
                const validation = validateNumber(event.target.value);
                if (validation !== true) {
                  setIsError(true);
                  setHelperText(validation);
                  return;
                }
                setAmount(parseFloat(event.target.value));
                setIsError(false);
                setHelperText('');
              }}
              required
              error={isError}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Marketing Code"
              variant="standard"
              defaultValue={defaultMc}
              onChange={(event) => {
                setMc(event.target.value);
              }}
              required
              error={isError}
            />
          </Grid>
          <Grid item>

            <PrimaryButton
              disabled={!individual.id || isError || !amount}
              onClick={()=> onGenerateButtonClick()}
              sx={{ marginLeft: '25px' }}
            >
            Generate
            </PrimaryButton>
          </Grid>
        </Grid>
        { neighborReferralLink && (
          <Grid item container flexDirection='row' justifyContent='flex-start'>
            <Grid item>
              <DetailItem label="Link" value={neighborReferralLink} />
            </Grid>
            <Grid item>
              <CopyButton message={neighborReferralLink} />
            </Grid>
          </Grid>
        )
        }
      </Grid>
    </Grid>
  );
}