import { Grid, Typography } from '@mui/material';

export interface IDetailItemProps {
  label: string;
  value: string;
  linkWidget?: JSX.Element;
}

export default function DetailItem ({ label, value, linkWidget }: IDetailItemProps) {
  return (
    <>
      <Grid container item xs={12} sx={{ flexDirection: 'rox', alignItems: 'center' }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '5px' }}>{label}</Typography>
        <Typography variant="body2">{value}</Typography>
        {linkWidget}
      </Grid>
    </>
  );
}