import { FormControl, InputLabel, Grid, Tooltip } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { sortByString } from 'util/sortFunctions';
import SuggestionPillButton from './SuggestionPillButton';
import { InfoOutlined } from '@mui/icons-material';


export default function CustomFieldOptionsSuggestions({ append, customFieldKey }) {
  const [pillItems, setPillItems] = useState<String[]>([]);
  const formCtx = useFormContext();
  const menuItems = formCtx
    .getValues('serviceDetailSections')
    .map((section, i) => ({
      key: `${section.sectionHeaderName}-${i}`,
      optionText: `${section.sectionHeaderName}`,
      optionValue: `${section.sectionHeaderName}`,
      ancillary: section,
    }))
    .sort(sortByString('optionText'))
    .concat([
      {
        key: `No-suggestions-${customFieldKey}`,
        optionText: 'No Suggestions',
        optionValue: 'No Suggestions',
        ancillary: { sectionListItems: [] },
      },
    ]);

  function onClickHandler(item) {
    append({ optionText: item, optionValue: item });
  }

  return (
    <Grid container>
      <Grid container direction='row' alignItems={'flex-end'} marginBottom='10px'>
        <Grid item xs={3}>
          <FormControl variant='standard' fullWidth>
            <InputLabel htmlFor={customFieldKey}>Service Details Suggestions</InputLabel>
            <HookSelect
              control={formCtx.control}
              name={customFieldKey}
              id={customFieldKey}
              menuItems={menuItems}
              postOnChange={async (event, x) => {
                const serviceDetailSection = menuItems.find((option) => option.optionValue === event.target.value)?.ancillary;
                const items = serviceDetailSection.sectionListItems.map( listItem => listItem.val);
                setPillItems(items);
              }}
              menuItemKeys={{
                key: 'id',
                value: 'optionValue',
                displayValue: 'optionText',
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1} sm={1} alignSelf={'center'}>
          <Tooltip title="Select a 'Service Details' section from the dropdown to generate a list of available custom field option suggestions. Click on the resulting buttons to add that item as an option.">
            <InfoOutlined color='info' fontSize='small' />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container item direction={'row'} spacing={1}>
        {pillItems.map((item, i) =>
          <Grid item key={`${customFieldKey}-${item}-grid-${i}`}>
            <SuggestionPillButton key={`${customFieldKey}-${item}-pill-${i}`} text={item} onClick={() => { onClickHandler(item);}} />
          </Grid>)}
      </Grid>
    </Grid>
  );
};