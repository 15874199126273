import { Alert, Grid, IconButton } from '@mui/material';
import Loading from 'components/Layout/Loading';
import ConfirmationModal, { ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useEffect } from 'react';
import styles from './customerUpload.module.css';
import useCustomerUpload from './hookStore/customerUploadHookStore';
import HelpIcon from '@mui/icons-material/Help';
import { BigTooltip } from 'components/tooltip/BigTooltip';
import StepOneOperations from './step_one_operations';
import ConfirmEditAllocationsModal from './edit_allocations/ConfirmEditAllocationsModal';


export default function CustomerUploadV2() {
  const { onUpload, init, loadingKey } = useCustomerUpload();

  useEffect(() => {
    void init();
  }, []);

  return (
    <ConfirmationModalContextProvider onConfirm={(args) => onUpload(args)}>
      <Loading loadingKey={loadingKey} size={100}>
        <Grid container spacing={2} className='pageGridContainer' justifyContent='center'>
          <Grid container item xs={11} >
            <Grid item xs={12}>
              <PageHeader headerText={'Upload Customers'}
                suppressHeaderMargin
                sx={{ paddingTop: '20px' }}
                rightChildren={
                  <BigTooltip title={
                    'Will find a latitude and longitude for each customer address, but will not associate the customer to a neighborhood. '+
                    'These customers are saved to a separate table in postgres instead of the Customer or Subscriber table'
                  }>
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </BigTooltip>}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <StepOneOperations/>
            </Grid>
            <Grid item xs={12}>
              <Alert severity='warning' sx={{ marginBottom: '20px' }}>
                Customer data is automatically processed at the end of customer staging. You no longer need to process staged customers.
              </Alert>
            </Grid>

          </Grid>
        </Grid>
      </Loading>
      <ConfirmationModal
        title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Upload customers</div>)}
        message={(onConfirmArgs:any) => {
          if (onConfirmArgs.shouldUpload) {
            return (
              <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmModal}>
                <Grid item xs>
                  You are about to upload {onConfirmArgs.fileName}. You will receive an email with results when complete.
                </Grid>
              </Grid>
            );
          } else if (onConfirmArgs.shouldUpload === false) {
            return (
              <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmModal}>
                <Grid item xs>
                  You are about to delete customer upload results for {onConfirmArgs.data.requestId}.
                </Grid>
              </Grid>
            );
          }
        }}
      />
      <ConfirmEditAllocationsModal/>
    </ConfirmationModalContextProvider>
  );
}