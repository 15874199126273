
import { useFormContext, useFieldArray } from 'react-hook-form';
import ServiceDetailCommonItems from './ServiceDetailCommonItems';

export function ServiceDetailListItems({ serviceDetailSection }: any) {
  const { control, watch } = useFormContext();

  const { fields: sectionListItems, append, move, remove } = useFieldArray({
    control,
    name: `${serviceDetailSection}.sectionListItems`,
  });
  return (
    <ServiceDetailCommonItems
      droppableId='provider-setup-service-detail-list-items'
      onDragEnd={(result:any) => {
        if (result.source && result.destination) {
          move(result.source.index, result.destination.index);
        }
      }
      }
      items={sectionListItems}
      labelSuffix='list item'
      namePrefix={`${serviceDetailSection}.sectionListItems`}
      onClickAdd={(field: any, index: number) => {
        if (index >= 0) {
          append({ val: '' }, { shouldFocus: true, focusIndex: (index || 0) + 1 });
        } else {
          append({ val: '' }, { shouldFocus: true });
        }
      }}
      onClickDelete={(index: number) => remove(index)}
      move={move} />
  );
}
