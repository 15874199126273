
import { IServiceOfferingImageRef } from 'model/serviceOffering';
import { useCallback, useState } from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useFormContext } from 'react-hook-form';
import { RemoveButton } from 'components/buttons';
import PrimaryOutlinedButton from 'components/buttons/PrimaryOutlinedButton';

export interface IExistingImage {
  unmarkForDelete: (x:IServiceOfferingImageRef, formCtx:any) => void;
  markForDelete: (x:IServiceOfferingImageRef, formCtx:any) => void;
  imageWrapper: IServiceOfferingImageRef;
}

export default function ExistingImage({ unmarkForDelete, markForDelete, imageWrapper }:IExistingImage) {
  const formCtx = useFormContext();

  const onUnmark = useCallback(() => {
    unmarkForDelete(imageWrapper, formCtx);
  }, []);

  const onMark = useCallback(() => {
    markForDelete(imageWrapper, formCtx);
  }, []);


  return (

    <div style={{
      width: 'auto',
      border: '1px solid gainsboro',
      borderRadius: '16px',
      padding: '8px',
      margin: '4px',
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
        <DragHandleIcon/>
        {imageWrapper.markedForDeletion && (
          <PrimaryOutlinedButton onClick={onUnmark} >undo delete?</PrimaryOutlinedButton>
        )}
        {!imageWrapper.markedForDeletion && (
          <RemoveButton onClick={onMark} tooltipTitle='Delete this image'/>
        )}
      </div>

      <img
        src={`${window.REACT_APP_SERVICE_OFFERING_IMAGE_URI}${imageWrapper.thumbnailImageFileName}`}
        width={200}
        style={{ display: 'block' }}
      />
    </div>
  );
}