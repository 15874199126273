import { Accordion, AccordionSummary, Typography, AccordionDetails, Grid, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HookCheckbox, HookIntegerField, HookTextField } from 'components/reactHookForm';
import { useFormContext, useWatch } from 'react-hook-form';
import DiscountScheduleSelect from './details/DiscountScheduleSelect';
import useServiceOfferingDetails from './details/hookStore/useServiceOfferingDetails';
import styles from './serviceOfferingDetails.module.css';
import InfoIcon from '@mui/icons-material/Info';

export default function ServiceOfferingAdvancedSettings() {
  const formContext = useFormContext();
  const {
    existingDefaultServiceOffering,
    selectedServiceProviderId,
    isDefaultForServiceType,
    originalServiceTypeId,
    onSaveServiceOffering,
    onServiceProviderChanged,
    onDefaultForServiceTypeChecked,
    setHasServiceProviderError,
  } = useServiceOfferingDetails();
  const watchedServiceTypeId = useWatch({ control: formContext.control, name: 'serviceTypeId' });
  return (
    <Accordion >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Advanced Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={9} md={4}>
            <HookTextField
              name='serviceTypeNameOverride'
              label='Service Type Name Override'
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <HookCheckbox
              name='territoryServiceOfferingEnabled'
              label='TSOI Enabled?'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <HookCheckbox
              name='hasDisabledMaxCustomerCount'
              label='Has Disabled Max Customer Count?'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DiscountScheduleSelect/>
          </Grid>
          <Grid item xs={12} md={4}>
            <HookIntegerField
              name='cutoffDayCount'
              label='Default Cutoff Day Count'
            />
          </Grid>
          <Grid container item xs={12} md={4}>
            <HookCheckbox
              name='isRebookEnabled'
              label='Rebook Enabled?'
            />
            <Grid item >
              <Tooltip title='Controls whether a user will be provided with the "Rebook for next year" modal after leaving a review in the Consumer app. The same flag will need to be enabled at the Service Type level in order for this setting to considered.'>
                <InfoIcon className={styles.infoIcon}/>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <HookCheckbox
              postOnChange={(e) => onDefaultForServiceTypeChecked(e, formContext)}
              name='defaultForServiceType'
              label='Is Default for Service Type'
              extraProps={{
                disabled: (
                  !selectedServiceProviderId ||
                                !watchedServiceTypeId ||
                                (isDefaultForServiceType && originalServiceTypeId === watchedServiceTypeId)
                ),
              }}
            />
            {isDefaultForServiceType && originalServiceTypeId === watchedServiceTypeId &&(
              <Typography variant='body1'>This service offering is the default for the service type. You may override this default with another service offering, but cannot uncheck.</Typography>
            )}
            {existingDefaultServiceOffering && (
              <>
                <Typography variant='body1'>There is an existing service offering that is the default for this service type. Saving will set this service offering as the new default.</Typography>
                <Typography variant='body2'>Service Offering Id: {existingDefaultServiceOffering.id}</Typography>
                <Typography variant='body2'>Service Offering Name: {existingDefaultServiceOffering.name}</Typography>
              </>
            )}
          </Grid>

        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}