import Loading, { useLoading } from 'components/Layout/Loading';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import useToast from 'components/toast/useToast';
import useIndividualsHookStore from 'pages/individuals/individualsHookStore';
import { Grid, Typography } from '@mui/material';
import { updateLaunchLeaderStatus } from 'api/individualApi';
import { LaunchLeaderStatusTypes } from 'model/individual';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const modalStoreKey = 'PauseApplicationModal';
const modalLoadingKey = 'PauseApplicationModalLoader';

export interface IPauseApplicationModal extends IModal {
}

export function createInitialPauseApplicationModal() :IPauseApplicationModal {
  return {
    open: false,
  };
}

export function createEmptyPauseApplicationModal() :IPauseApplicationModal {
  return {
    open: false,
  };
}

export default function PauseApplicationModal({ shouldOpen }) {
  // Internal State
  const { getModalState } = useModal(modalStoreKey);
  const state = getModalState() as IPauseApplicationModal;
  state.open = shouldOpen; // This modal will be used opened via a "modal route", and won't be opened via a button click, so setting the open state here
  const [requesting, setRequesting] = useState(false);
  var [wasSuccessful, setWasSuccessful] = useState(false);
  var [requestComplete, setRequestComplete] = useState(false);
  // External State
  const { individualId } = useParams();
  const { init, individual, loadingKey: individualLoadingKey, updateIndividual } = useIndividualsHookStore();
  var isIndividualALaunchLeader = !!individual.id && !!individual.launchLeaderStatus;
  // Hooks
  const navigate = useNavigate();
  const { createErrorToast } = useToast();
  const { onLoading, doneLoading } = useLoading(modalLoadingKey);
  // Effects
  useEffect(() => {
    void init();
  }, [individualId]);

  useEffect(() => {
    if (isIndividualALaunchLeader && !requesting) {
      void pauseApplication();
    }
  }, [individual]);

  // Methods
  const pauseApplication = async () => {
    try {
      setRequesting(true);
      onLoading();
      const payload = {
        status: LaunchLeaderStatusTypes.MANUAL_UPDATE_REQUIRED,
        markAsPaused: true,
      };
      const resp = await updateLaunchLeaderStatus(individual.id.toString(), payload);
      await updateIndividual(resp.data);
      setWasSuccessful(true);
      doneLoading();
    } catch (error:any) {
      console.error(error);
      createErrorToast('Error updating Launch Leader: ' + error.response?.data?.message);
    }
    doneLoading();
    setRequestComplete(true);
    setRequesting(false);
  };

  const onCloseModal = () => {
    if (!!individualId) {
      navigate(`/individuals/${encodeURIComponent(individualId)}`);
    }
  };

  var message = 'Loading...';
  if (requesting) {
    message = 'Updating...';
  }
  // success message
  if (requestComplete && wasSuccessful) {
    message = `Successfully paused application for individual ${individualId}!`;
  }
  // error message
  if (requestComplete && !wasSuccessful) {
    message = `Failed to update status for individual ${individualId}`;
  }
  // not a launch leader message
  if (!isIndividualALaunchLeader) {
    message = `Individual ${individualId} is not a launch leader`;
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Pause Neighborhood Lead Application'}
      modalContent={
        (
          <>
            <Loading loadingKey={individualLoadingKey}>
              <Loading loadingKey={modalLoadingKey}>
                <Grid container justifyContent={'flex-start'}>
                  <Grid container item justifyContent={'flex-start'} flexDirection={'column'}>
                    <Grid item xs={12}>
                      <Typography variant='body1'>{message}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ Loading>
            </Loading>
          </>
        )
      }
      onConfirm={onCloseModal}
      primaryButtonText='Close'
      primaryDisabled={requesting}
      hideSecondaryButton={true}
    />
  );
}