import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import Spacer from 'components/Layout/Spacer';
import GenerateInvoiceButton from './GenerateInvoiceButton';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import useInvoicing, { ALL_MONTHS_NO_INVOICED } from './hookStore/useInvoicing';


const CustomCancelIcon = styled(CancelOutlinedIcon)`
    color: grey;
    opacity:0.7;
    transition:color 0.3s, opacity 0.3s;

    :hover {
        cursor:pointer;
        color:#299b60;
        opacity:1;
    }
`;


const invoiceMonths = [
  { id: 0, name: 'Select one' },
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' },
  { id: ALL_MONTHS_NO_INVOICED, name: 'All Months (No invoice only)' },
];

const invoiceYears = [
  { id: 2022, name: '2022' },
  { id: 2023, name: '2023' },
  { id: 2024, name: '2024' },
  { id: 2025, name: '2025' },
];


export default function InvoiceTableFilters() {
  const {
    selectedRows,
    invoicingTableFilter,
    shouldShowInvoicedCustomers,
    onClearMonth,
    onClearYear,
    onMonthChange,
    onYearChange,
    postOnChange,
    onShouldShowInvoicedCustomers,
  } = useInvoicing();

  return (
    <Grid container spacing={1} item xs={12} alignItems={'center'} sx={{ marginTop: '10px' }}>
      <Grid item xs={2} >
        <ServiceProviderAutocomplete
          suppressEmptyStringSearch
          label='Service Provider'
          fieldName='providerId'
          formless={true}
          placeholder={''}
          required={false}
          controlledValue={invoicingTableFilter.selectedServiceProviderId}
          postOnChange={postOnChange}
        />
      </Grid>
      <Grid container alignItems='center' item xs='auto' sx={{ paddingBottom: '12px' }}>
        <FormControl variant="standard" sx={{ minWidth: 200 }}>
          <InputLabel>Year</InputLabel>
          <Select
            id="year-select"
            name="invoiceYear"
            autoWidth
            label="Year"
            onChange={onYearChange}
            value={invoicingTableFilter.selectedYear ? invoicingTableFilter.selectedYear : 0}
          >
            {
              invoiceYears.map((x: any, i) => {
                return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>;
              })
            }
          </Select>
        </FormControl>
        <CustomCancelIcon
          onClick={onClearYear}/>
      </Grid>
      <Grid container alignItems='center' item xs='auto' sx={{ paddingBottom: '12px' }}>
        <FormControl variant="standard" sx={{ minWidth: 200 }}>
          <InputLabel>Month</InputLabel>
          <Select
            id="month-select"
            name="invoiceMonth"
            autoWidth
            label="Month"
            onChange={onMonthChange}
            value={invoicingTableFilter.selectedMonth ? invoicingTableFilter.selectedMonth : 0}
          >
            {
              invoiceMonths && invoiceMonths.map((x: any, i) => {
                return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>;
              })
            }
          </Select>
        </FormControl>
        <CustomCancelIcon
          onClick={onClearMonth}/>
      </Grid>
      <Grid container item xs='auto' alignItems={'center'}>
        <FormControlLabel
          label="Show invoiced customers"
          control={(
            <Checkbox
              disabled={!invoicingTableFilter.selectedMonth || parseInt(invoicingTableFilter.selectedMonth) === ALL_MONTHS_NO_INVOICED}
              checked={shouldShowInvoicedCustomers}
              onChange={(e) => onShouldShowInvoicedCustomers(e.target.checked)}
            />
          )}
        />
      </Grid>
      <Spacer/>
      <Grid item xs='auto'>
        <GenerateInvoiceButton
          selectedCustomers={selectedRows}
          selectedMonth={parseInt(invoicingTableFilter.selectedMonth)}
          selectedYear={parseInt(invoicingTableFilter.selectedYear)}
          autoFinalizeIfPossible={true}
        />
      </Grid>
    </Grid>
  );
}