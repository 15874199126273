import { FormControl, InputLabel, FormHelperText, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { IServiceProviderStatusUpdateDTO, StatusType } from 'model/serviceProvider';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import useServiceProviderStatusSelect from './useServiceProviderStatusSelect';
import { Save } from '@mui/icons-material';

export default function StatusTypeSelect() {
  const { serviceProvider, init, onUpdateStatus } = useServiceProviderStatusSelect();
  const currentStatus = serviceProvider?.status;

  const [statusTypes, setStatusTypes] = useState<any[]>(StatusType.getListIncludingStatus(currentStatus));

  const formContext = useForm<IServiceProviderStatusUpdateDTO>({
    mode: 'onTouched',
    defaultValues: {
      status: currentStatus,
    },
  });

  useEffect(() => {
    void init();
  }, []);

  useEffect(() => {
    formContext.reset({ status: currentStatus });
  }, [serviceProvider]);

  useEffect(() => {
    setStatusTypes(StatusType.getListIncludingStatus(currentStatus));
  }, [currentStatus]);


  const { isError, getErrorMessage } = useErrorState(formContext);

  const onSubmit : SubmitHandler<IServiceProviderStatusUpdateDTO> = data => {
    return onUpdateStatus(data);
  };
  const { isSubmitting, isValid } = formContext.formState;

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm>
            <FormControl variant='standard' fullWidth error={isError('status')}>
              <InputLabel htmlFor="status-type">Status</InputLabel>
              <HookSelect
                control={formContext.control}
                rules={{
                  required: { value: true, message: 'Required' },
                }}
                name='status'
                id='status-type'
                menuItems={statusTypes}
              />
              <FormHelperText>{getErrorMessage('status')}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm='auto'>
            <Tooltip title={'Assign coverage area'} >
              <IconButton
                color="primary"
                type='submit'
              >
                {isSubmitting ? <CircularProgress size={24} /> : <Save />}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}