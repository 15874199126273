import BatchSummaryDataTable from './_components/BatchSummaryDataTable';
import PageHeader from 'components/SectionHeaders/PageHeader';
import useMarkets from 'hooks/useMarkets';
import useTsoiManagementBatch from './hookStore/useTsoiManagementBatch';
import { Grid, Typography } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import NextTerritorySelect from './_components/NextTerritorySelect';
import Loading from 'components/Layout/Loading';
import DetailItem from './_components/DetailItem';
import SendHappeningSoonInvitesModal, { modalStoreKey } from './_components/SendHappeningSoonInvitesModal';
import useModal from 'components/modal/useModal';
import SendHappeningSoonEnrollmentNotificationsModal, { enrollModalStoreKey } from './_components/SendHappeningSoonEnrollmentNotificationsModal';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function TsoiManagementBatchSummary() {
  const { market } = useMarkets();
  const { init, batch, loadingKey } = useTsoiManagementBatch();
  const { automatedUpcomingTsoisDec2024 } = useFlags();
  const { batchId } = useParams();
  const { openModal: openInviteOnlyModal } = useModal(modalStoreKey);
  const { openModal: openEnrollModal } = useModal(enrollModalStoreKey);

  useEffect(() => {
    if (batchId) {
      void init(batchId);
    }
  }, [batchId]);


  const onSubmitBatch = async () => {
    openEnrollModal({});
  };

  const onSubmitInviteOnlyBatch = async () => {
    openInviteOnlyModal({});
  };

  const batchStartDay = batch?.startDate;
  const batchEndDay = batch?.endDate;

  return (
    <Loading loadingKey={loadingKey}>
      <Grid container sx={{ flexDirection: 'column', paddingLeft: '10px', paddingRight: '10px' }}>
        <Grid item xs={12} paddingTop={'25px'} paddingBottom={'25px'}>
          <PageHeader headerText="Batch Summary" />
        </Grid>
        <Grid container item xs={12} paddingTop={'25px'} paddingBottom={'25px'}>
          <Grid container item xs={6} flexDirection={'column'}>
            <DetailItem label={'Market'} value={market?.name} />
            <DetailItem label={'Week'} value={`${batchStartDay} to ${batchEndDay}`} />
          </Grid>
          <Grid container item xs={6} justifyContent={'flex-end'}>
            <Grid item xs={12}>
              <NextTerritorySelect />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BatchSummaryDataTable />
        </Grid>
        {batch?.status == 'SENT' && (
          <Grid container item xs={12} spacing={1} justifyContent={'center'} paddingTop={'25px'}>
            <Typography variant='body1'>Batch Processed</Typography>
          </Grid>
        )}
        {batch?.status != 'SENT' && (
          <Grid container item xs={12} spacing={1} justifyContent={'center'} paddingTop={'25px'}>
            {automatedUpcomingTsoisDec2024 && (
              <Grid item xs='auto'>
                <PrimaryButton onClick={onSubmitBatch} disabled={batch?.status === 'SENT' }>
                Send Notifications
                </PrimaryButton>
              </Grid>
            )}

            <Grid item xs='auto'>
              <PrimaryButton onClick={onSubmitInviteOnlyBatch} disabled={batch?.status === 'SENT' }>
                Send Invites
              </PrimaryButton>
            </Grid>
          </Grid>
        )}

        <SendHappeningSoonInvitesModal batchId={batchId} />
        <SendHappeningSoonEnrollmentNotificationsModal batchId={batchId} />
      </Grid>
    </Loading>
  );
}