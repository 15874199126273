import { Grid, Typography } from '@mui/material';
import { HookCheckbox, HookPriceField, HookTextField } from 'components/reactHookForm';
import HookPercentField from 'components/reactHookForm/HookPercentField';
import SectionWrapper from './styling/SectionWrapper';

export default function TermsAndConditions() {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h6' sx={{ textDecoration: 'underline' }}>Terms and Conditions</Typography>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12} sm={6} md={4}>
        <HookPercentField
          name='oneTimePercent'
          label='One-Time Percent'
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <HookPercentField
          name='recurringPercent'
          label='Recurring Percent'
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <HookPriceField
          name='takeRateCap'
          label='Take Rate Cap'
        />
      </Grid>
      <Grid item xs={8}></Grid>
      <Grid item xs={12} sx={{ padding: '0 12px' }}>
        <HookTextField
          multiline
          minRows={3}
          name='customConditions'
          label='Custom conditions'
        />
      </Grid>
    </>
  );
}