
import { testReferAProPostReview } from 'api/communicationsApi';
import { testReferralFunding } from 'api/userApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { IReferAProPostReviewTestingForm } from 'model/testing/refer_a_pro/refer_a_pro';

import { useEffect, useState } from 'react';

type ReferAProPostReviewTesting = {

}
const loadingKey = 'ReferAProPostReviewTesting';

const { get, update, registerListener, unregisterListener } = createStore<ReferAProPostReviewTesting>('ReferAProPostReviewTesting', {

});

export default function useReferAProPostReviewTesting() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    update({
      ...get(),
    });
    doneLoading(300);
  }

  async function onSubmitForm(form: IReferAProPostReviewTestingForm) {
    onLoading();
    try {
      const res = await testReferAProPostReview(form);
      createSuccessToast('request received.');
    } catch (err:any) {
      createErrorToast(err.response.data.message);
    }
    doneLoading(300);
  }


  return {
    loadingKey,
    ...get(),
    init,
    onSubmitForm,
  };
}