import { saveDiscountSchedule } from 'api/discountScheduleApi';
import { DiscountScheduleTypes } from 'model/discountSchedule';
import { createDefaultDiscSchedByServiceAndCadence } from '../helper';
import { IFormFriendlyServiceDetailSection, IServiceDetailSection, IServiceOffering, IServiceOfferingFormDTO } from 'model/serviceOffering';
import { findServiceTypeInfo } from 'api/serviceCategoryApi';
import { ServiceTypeInfoUtil } from 'util/serviceType/serviceTypeInfoUtil';

export interface ICreateDiscountScheduleParams {
  providerId: string;
  serviceType: string;
  cadenceType: string;
  currentServiceOffering?: IServiceOffering;
}

export class ProviderSetupServiceOfferingUtil {

  static async createDefaultDiscountSchedule({ providerId, serviceType, cadenceType, currentServiceOffering }: ICreateDiscountScheduleParams) {
    let data = createDefaultDiscSchedByServiceAndCadence(serviceType, cadenceType);

    if (data.type === DiscountScheduleTypes.NoDiscount) {
      delete data.discountSchedule;
      delete data.pricingSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarPerCustomer) {
      delete data.discountSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarSavePerCustomer) {
      delete data.pricingSchedule;
      delete data.flatRateSchedule;
    } else if (data.type === DiscountScheduleTypes.DollarFlatRate) {
      delete data.pricingSchedule;
      delete data.discountSchedule;
    } else if (data.type === DiscountScheduleTypes.PercentFlatRate) {
      delete data.pricingSchedule;
      delete data.discountSchedule;
    }
    if (providerId) {
      data.providerId = providerId;
    }
    if (currentServiceOffering?.serviceTypeId) {
      data.serviceTypeId = currentServiceOffering.serviceTypeId;
    }
    return saveDiscountSchedule(data);
  }

  /**
   *
   * @param data service offering to modify for clone
   * @param omitImages
   * @returns
   */
  static modifyForClone(data:IServiceOffering & any, omitImages:boolean, cloningFromSameProvider:boolean):any {
    data.name = `${data.serviceCategory}: ${data.serviceType}`;
    data.defaultForServiceType = true; //we validate this on the backend. the user receives an error message indicating it can't be the default
    data.territoryServiceOfferingEnabled = true; // validated on backend
    delete data.itemMetadata;
    data.defaultDiscountScheduleId = '';
    data.cutoffDayCount = '';
    if (!cloningFromSameProvider) {
      data.providerId = '';
      data.providerCoverageAreaId = '';
    }

    data.clonedFromId = data.id;
    if (omitImages) {
      data.imageRefs = [];
    }
    delete data.id;
    if (!data.subscriptionOptions) {
      data.subscriptionOptions = {
        label: '',
        options: [
          {
            optionName: '',
            optionValue: '',
            isDefaultOption: false,
            isOneTime: false,
            isNew: true,
          },
        ],
        subscriptionTermInMonths: 0,
      };
    }
    // format serviceDetailsSections
    data.serviceDetailSections = data.serviceDetailSections.map((section) => {
      const updatedSectionItems = section.sectionItems.map((item) => ({ val: item }));
      const updatedSectionListItems = section.sectionListItems.map((item) => ({ val: item }));

      return {
        ...section,
        sectionItems: updatedSectionItems,
        sectionListItems: updatedSectionListItems,
      };
    });

    return data;
  }


  static async convertDataToSave(
    data:IServiceOffering,
    providerId:string,
    serviceOfferingId?:string | null,
  ) : Promise<IServiceOfferingFormDTO> {
    const dataToSave = { ...data };
    const originalProviderId = data.providerId;
    dataToSave.providerId = providerId;
    if (dataToSave.cadenceType === 'ONETIME_ONLY') {
      delete dataToSave.subscriptionOptions;
    }

    if (!serviceOfferingId && (originalProviderId != providerId)) {
      //if we are creating/cloning a service offering based on another service provider, we don't want to carry over the providerCoverageAreaId
      dataToSave.providerCoverageAreaId = null;
    }

    //convert serviceDetailSections.sectionItems and serviceDetailSections.sectionListItems back to
    //original form
    dataToSave.serviceDetailSections = data.serviceDetailSections
      .map(x => ProviderSetupServiceOfferingUtil.convertSectionDetailSectionOriginal(x));
    if (dataToSave.customFields) {
      dataToSave.customFields.forEach((customField) => {
        if (customField.customFieldGroupType === '') {
          //empty string won't deserialize into an enum
          customField.customFieldGroupType = null;
        }
        customField.customFieldOptions.forEach((customFieldOption) => {
          customFieldOption.optionValue = customFieldOption.optionText;
        });
      });
    }


    const serviceTypeInfoRes = await findServiceTypeInfo();
    const serviceTypeInfo = serviceTypeInfoRes.data;
    let sampleNames = ServiceTypeInfoUtil.findMatchingServiceCategoryAndServiceTypeNames(dataToSave.serviceTypeId, serviceTypeInfo);
    dataToSave.serviceType = sampleNames?.serviceTypeName ?? '';
    dataToSave.serviceCategory = sampleNames?.serviceCategoryName ?? '';


    let formDto:IServiceOfferingFormDTO = {
      serviceOffering: dataToSave,
      existingDefaultServiceOffering: null,
      hardDeletedImageRefs: [],
    };
    return formDto;
  }

  static convertSectionDetailSectionOriginal(serviceDetailSection:IFormFriendlyServiceDetailSection):IServiceDetailSection {
    return {
      sectionHeaderName: serviceDetailSection.sectionHeaderName,
      sectionIcon: serviceDetailSection.sectionIcon,
      sectionItems: serviceDetailSection.sectionItems ? serviceDetailSection.sectionItems.map(x => (x.val)) : [],
      sectionListItems: serviceDetailSection.sectionListItems ? serviceDetailSection.sectionListItems.map(x => (x.val)) : [],
    };
  }

}

