import useErrorState from 'hooks/reactHookForm/useErrorState';
import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import { useFormContext } from 'react-hook-form';
import useProviderSetupStore from '../useProviderSetupStore';
import { useLocation, useParams } from 'react-router-dom';
import { ProviderSetupServiceOfferingUtil } from './providerSetupServiceOfferingUtil';
import { useEffect } from 'react';


export default function ServiceOfferingCloneOptionsSelect() {
  const formCtx = useFormContext();
  const { serviceOfferingCloneOptions, setServiceType, setCadenceType } = useProviderSetupStore();
  const { getErrorMessage } = useErrorState(formCtx);
  const { providerId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cloneServiceProviderId = queryParams.get('cloneServiceProviderId');
  const cloneServiceOfferingId = queryParams.get('cloneServiceOfferingId');

  useEffect(() => {
    if (cloneServiceProviderId && cloneServiceOfferingId && serviceOfferingCloneOptions?.length > 0) {
      formCtx.setValue('serviceOfferingIdToClone', cloneServiceOfferingId);
      const serviceOffering = serviceOfferingCloneOptions.find((option) => option.optionValue === cloneServiceOfferingId)?.ancillary;
      if (serviceOffering) {
      //we cannot use the same coverage area across different service providers due the structure in dynamodb.
        const cloningFromSameProvider = serviceOffering?.providerId === providerId;
        const cloneData = ProviderSetupServiceOfferingUtil.modifyForClone(serviceOffering, false, cloningFromSameProvider);
        cloneData.providerId = providerId;
        cloneData.serviceOfferingIdToClone = cloneServiceOfferingId;
        formCtx.reset(cloneData);
        // set other relevant state values
        setServiceType(cloneData.serviceType);
        setCadenceType(cloneData.cadenceType);
      }

    }
  }, [cloneServiceOfferingId, serviceOfferingCloneOptions]);

  if (!serviceOfferingCloneOptions || serviceOfferingCloneOptions.length === 0) {
    return <></>;
  }

  return (
    <FormControl variant='standard' fullWidth>
      <InputLabel htmlFor="service-offering-id-to-clone">Service Offering to Clone</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: false, message: 'Required' },
        }}
        postOnChange={async (event, x) => {
          const serviceOffering = serviceOfferingCloneOptions.find((option) => option.optionValue === event.target.value)?.ancillary;
          //we cannot use the same coverage area across different service providers due the structure in dynamodb.
          const cloningFromSameProvider = serviceOffering?.providerId === providerId;
          const cloneData = ProviderSetupServiceOfferingUtil.modifyForClone(serviceOffering, false, cloningFromSameProvider);
          cloneData.providerId = providerId;
          cloneData.serviceOfferingIdToClone = event.target.value;
          formCtx.reset(cloneData);
          // set other relevant state values
          setServiceType(cloneData.serviceType);
          setCadenceType(cloneData.cadenceType);

        }}
        name='serviceOfferingIdToClone'
        id='service-offering-id-to-clone'
        menuItems={serviceOfferingCloneOptions}
        menuItemKeys={{
          key: 'id',
          value: 'optionValue',
          displayValue: 'optionText',
        }}
      />
      <FormHelperText>{getErrorMessage('serviceTypeId')}</FormHelperText>
    </FormControl>
  );
}