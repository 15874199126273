import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMarket, IMarketForm, StateAbbreviations, createEmptyMarketForm } from 'model/markets';
import { findMarketById, saveMarket } from 'api/marketsApi';
import useToast from 'components/toast/useToast';

const loadingKey = 'MarketDetails';
type MarketDetails = {
  formData: IMarketForm;
}

const { get, update, registerListener, unregisterListener } = createStore<MarketDetails>('MarketDetails', {
  formData: createEmptyMarketForm(),
});


export default function useMarketDetails() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { marketId } = useParams();
  const { createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(isNew:boolean) {
    onLoading();
    try {

      try {
        if (isNew) {

          update({
            ...get(),
            formData: createEmptyMarketForm(),
          });
        } else {
          const res = await findMarketById(marketId!);
          if (res.data) {
            update({
              ...get(),
              formData: convertForForm(res.data),
            });
          }
        }

      } catch (err:any) {
        console.error(err);
      }

    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function save(data: IMarketForm) {
    onLoading();
    try {
      const res = await saveMarket(data.id, data);
    } catch (err:any) {
      console.error(err);
      createErrorToast('Unable to save market');
    }
    doneLoading(300);
  }

  function convertForForm(market:IMarket):IMarketForm {

    return {
      id: market.id,
      name: market.name,
      stateAbbr: market.stateAbbr,
      parentMarket: market.parentMarket,
      marketMapUrl: market.marketMapUrl,
    };
  }


  return {
    ...get(),
    loadingKey,
    init,
    save,
  };
}


