import TabPanel from 'components/tabs/SimpleTabPanel';
import { Grid, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import Loading from 'components/Layout/Loading';
import SeedNeighborhoodFromTemplate from './SeedNeighborhoodFromTemplate';
import SeedProviderFromTemplate from './SeedProviderFromTemplate';
import ReSeedProviderFromTemplate from './ReSeedProviderFromTemplate';

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function IndividualTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Loading loadingKey={'TODOReplaceMe'}>
      <Grid item xs={12} marginTop={'50px'}>
        <>
          <Tabs value={value} onChange={handleChange} aria-label='tabs'>
            <Tab label="Seed Neighborhood" {...a11yProps(0)} />
            <Tab label="Seed Provider" {...a11yProps(1)} />
            <Tab label="ReSeed Provider" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <SeedNeighborhoodFromTemplate />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SeedProviderFromTemplate />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ReSeedProviderFromTemplate />
          </TabPanel>
        </>
      </Grid>
    </Loading>
  );
}