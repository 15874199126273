import { Grid } from '@mui/material';
import { TooltipButton } from 'components/buttons';
import { HookCheckbox } from 'components/reactHookForm';

export default function HappeningSoonInvitePreferred() {
  return (
    <Grid container item xs={12} md='auto'>
      <Grid item xs>
        <HookCheckbox
          label='Happening Soon Invite Preferred?'
          name='happeningSoonInvitePreferred'
          defaultValue={false}
        />
      </Grid>
      <Grid item xs='auto'>
        <TooltipButton
          tooltipTitle="This disables TSOI happening soon auto-enrollment for the service provider."
        />
      </Grid>
    </Grid>
  );
}