import useErrorState from 'hooks/reactHookForm/useErrorState';
import {
  FormControl, InputLabel, FormHelperText,
} from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import { get, useFormContext } from 'react-hook-form';
import useProviderSetupStore from '../useProviderSetupStore';

export default function PriceLabel() {
  const formContext = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formContext);
  const {
    priceLabelOptions,
  } = useProviderSetupStore();

  if (priceLabelOptions.length === 0) {
    return null;
  }
  return (
    <FormControl variant='standard' fullWidth error={isError('priceLabel')}>
      <InputLabel htmlFor="price-label">Price Label</InputLabel>
      <HookSelect
        control={formContext.control}
        rules={{
          validate: function(value) {
            if (!value) {
              return 'Required';
            }
            const noPriceTextField = get(formContext.getValues(), 'noPriceTextField');
            if (noPriceTextField) {
              return 'You must clear the "No Price Text Field" before setting a price';
            }
          },
        }}
        name='priceLabel'
        id='price-label'
        menuItems={priceLabelOptions}
        menuItemKeys={{
          key: 'id',
          value: 'optionValue',
          displayValue: 'optionText',
        }}

      />
      <FormHelperText>{getErrorMessage('priceLabel') || ' '}</FormHelperText>
    </FormControl>
  );
}