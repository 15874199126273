import Loading, { useLoading } from 'components/Layout/Loading';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import useToast from 'components/toast/useToast';
import useIndividualsHookStore from 'pages/individuals/individualsHookStore';
import { Grid, Typography } from '@mui/material';
import { getCustomerIoLinkDto, updateLaunchLeaderStatus } from 'api/individualApi';
import { LaunchLeaderStatusTypes } from 'model/individual';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const modalStoreKey = 'ViewInCustomerIoModal';
const modalLoadingKey = 'ViewInCustomerIoModalLoader';

export interface IViewInCustomerIoModal extends IModal {
}

export function createInitialViewInCustomerIoModal() :IViewInCustomerIoModal {
  return {
    open: false,
  };
}

export function createEmptyViewInCustomerIoModal() :IViewInCustomerIoModal {
  return {
    open: false,
  };
}

export default function ViewInCustomerIoModal({ shouldOpen }) {
  // Internal State
  const { getModalState } = useModal(modalStoreKey);
  const state = getModalState() as IViewInCustomerIoModal;
  state.open = shouldOpen; // This modal will be used opened via a "modal route", and won't be opened via a button click, so setting the open state here
  const [requesting, setRequesting] = useState(false);
  var [wasSuccessful, setWasSuccessful] = useState(false);
  var [requestComplete, setRequestComplete] = useState(false);
  // External State
  const { individualId } = useParams();
  const { init, individual, loadingKey: individualLoadingKey } = useIndividualsHookStore();
  // Hooks
  const navigate = useNavigate();
  const { createErrorToast } = useToast();
  const { onLoading, doneLoading } = useLoading(modalLoadingKey);
  // Effects
  useEffect(() => {
    void init();
  }, [individualId]);

  useEffect(() => {
    if (!requesting) {
      void getCustomerIoLink();
    }
  }, [individual]);

  // Methods
  const getCustomerIoLink = async () => {
    try {
      setRequesting(true);
      onLoading();

      const resp = await getCustomerIoLinkDto(individual.id.toString());
      if (resp?.data?.link) {
        window.location.href = resp.data.link;
      }

      setWasSuccessful(true);
      doneLoading();
    } catch (error:any) {
      console.error(error);
      createErrorToast('Error getting customer.io link: ' + error.response?.data?.message);
    }
    doneLoading();
    setRequestComplete(true);
    setRequesting(false);
  };

  const onCloseModal = () => {
    if (!!individualId) {
      navigate(`/individuals/${encodeURIComponent(individualId)}`);
    }
  };

  var message = 'Loading...';

  // error message
  if (requestComplete && !wasSuccessful) {
    message = `Failed to redirect to customer.io for ${individualId}`;
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Redirect to Customer.io...'}
      modalContent={
        (
          <>
            <Loading loadingKey={individualLoadingKey}>
              <Loading loadingKey={modalLoadingKey}>
                <Grid container justifyContent={'flex-start'}>
                  <Grid container item justifyContent={'flex-start'} flexDirection={'column'}>
                    <Grid item xs={12}>
                      <Typography variant='body1'>{message}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ Loading>
            </Loading>
          </>
        )
      }
      onConfirm={onCloseModal}
      primaryButtonText='Close'
      primaryDisabled={requesting}
      hideSecondaryButton={true}
    />
  );
}