import BatchOptionsDropdown from './BatchOptionsDropdown';
import CreateButton from './CreateButton';
import useNeighborhoodBatchForNeighborhoodLead, { IBatchSelectionForm } from './hookStore/useLetterBatchNeighborhoodForNeighborhoodLead';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';

export default function FormRow() {
  const { onSubmit, selectedBatch } = useNeighborhoodBatchForNeighborhoodLead();

  const formContext = useForm<IBatchSelectionForm>({
    mode: 'onChange',
    defaultValues: {
      letterBatch: selectedBatch,
    },
  });

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container item xs={12} alignItems={'center'}>
          <Grid item xs={8} md={3}>
            <BatchOptionsDropdown />
          </Grid>
          <Grid item paddingLeft={'20px'}>
            <CreateButton />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}