import { Box, Grid, GridSize } from '@mui/material';
import { ReactNode } from 'react';

interface SectionWrapperProps {
  xs?: boolean | GridSize;
  sm?: boolean | GridSize;
  md?: boolean | GridSize;
  lg?: boolean | GridSize;
  xl?: boolean | GridSize;
  children: ReactNode;
}

export default function SectionWrapper({
  xs,
  sm,
  md,
  lg,
  xl,
  children,
}: SectionWrapperProps) {
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}
      alignContent={'flex-start'}
      sx={{

        padding: '10px',
      }}
    >
      <Grid container spacing={1}>
        {children}
      </Grid>
    </Grid>
  );
}