import { Link, MenuItem, Tooltip, Typography } from '@mui/material';
import { IServiceOffering } from 'model/serviceOffering';
import { ProviderOnboardingQueryParams } from 'pages/providerSetup/ProviderOnboardingStepper';


export interface IEditDiscountScheduleProps {
  serviceOffering: IServiceOffering;
}

export default function EditDiscountSchedule({ serviceOffering } : IEditDiscountScheduleProps) {
  return (
    <Link href={`/providerOnboarding/${serviceOffering?.providerId}/service-offerings/${serviceOffering?.id}/discount-schedules/${serviceOffering.defaultDiscountScheduleId}?${ProviderOnboardingQueryParams.editDiscountSchedule}=true`} rel='noreferrer'>
      <Tooltip title={'Edit this discount schedule'} >
        <Typography>Edit</Typography>
      </Tooltip>
    </Link>
  );
}