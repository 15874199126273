import { Grid } from '@mui/material';
import { RemoveButton } from 'components/buttons';
import { HookTextField } from 'components/reactHookForm';

export default function ServiceDetailItem({
  field,
  index,
  labelSuffix,
  namePrefix,
  onClickAdd,
  onClickDelete,
}:any) {
  return (
    <Grid container item xs={12}>
      <Grid item xs>
        <HookTextField
          name={`${namePrefix}.${index}.val`}
          label={labelSuffix}
          required
          multiline
          onKeyDown={(event)=>{
            if (event.key== 'Enter') {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
          onKeyUp={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (event.key== 'Enter') {onClickAdd(field, index);}
          }}
        />
      </Grid>
      <Grid item xs='auto'>
        <RemoveButton
          tooltipTitle={`Delete this ${labelSuffix}`}
          onClick={()=> {
            onClickDelete(index);
          }}
        />
      </Grid>
    </Grid>
  );
}