import useSeedFromTemplate from '../_hookStore/useSeedFromTemplate';
import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import ReSeedProviderConfirmationModal, { ReSeedProviderConfirmationModalKey } from './ReSeedProviderConfirmationModal';


export default function ReSeedProviderFromTemplate() {
  const { onServiceProviderReseedSelect } = useSeedFromTemplate();
  const { openModal } = useConfirmationModal(ReSeedProviderConfirmationModalKey);
  return (
    <>
      <Grid container flexDirection={'row'} alignItems={'flex-start'} columnSpacing={3}>
        <Grid item xs={3}>
          <ServiceProviderAutocomplete
            label={'Service Provider'}
            formless={true}
            postOnChange={(_formCtx, value) => {
              void onServiceProviderReseedSelect(value);
            }}
            fieldName='serviceProvider'
            placeholder={''}
            required={true}
          />
        </Grid>
        <Grid item xs={4}>
          <PrimaryButton
            sx={{ height: '56px' }}
            onClick={() => {
              openModal({});
            }}
          >
            Seed
          </PrimaryButton>
        </Grid>
      </Grid>
      <ReSeedProviderConfirmationModal />
    </>
  );
}