import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { DiscountScheduleStandardOptions, IDiscountScheduleDetails } from 'model/discountSchedule';
import { useFormContext } from 'react-hook-form';
import useProviderSetupStore from '../useProviderSetupStore';


export default function DiscountScheduleSelect() {
  const formContext = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formContext);
  const {
    currentDiscountSchedule,
    discountScheduleOptions,
  } = useProviderSetupStore();

  function onScheduleTypeChange(ev) {
    const { options, discountType, name } = DiscountScheduleStandardOptions.standardOptionsMap[ev.target.value];
    formContext.setValue('name', name);
    formContext.setValue('discountSchedule', options as IDiscountScheduleDetails);
    formContext.setValue('type', discountType);
  }

  return (
    <FormControl variant='standard' fullWidth error={isError('type')}>
      <InputLabel htmlFor="discount-schedule-standard-option">Schedule</InputLabel>
      <HookSelect
        control={formContext.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name='standardOption'
        id='discount-schedule-standard-option'
        menuItems={discountScheduleOptions}
        postOnChange={onScheduleTypeChange}
      />
      <FormHelperText>{getErrorMessage('type')}</FormHelperText>
    </FormControl>
  );
}