import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useCoverageAreaAssignment from 'components/assignCoverageArea/useCoverageAreaAssignment';
import ServiceOfferings from './ServiceOfferings';

export interface ICoverageAreaAssignmentProps {
  serviceProviderId?:string;
}

export default function CoverageAreaAssignment({ serviceProviderId }:ICoverageAreaAssignmentProps) {
  const { providerCoverageAreaOptions, init } = useCoverageAreaAssignment();
  const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    if (serviceProviderId) {
      void init(serviceProviderId);
    }
  }, [serviceProviderId]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (providerCoverageAreaOptions.length === 0) {
    return null;
  }


  return (
    <Accordion expanded={expanded === 'coverage-area-assignment'} onChange={handleChange('coverage-area-assignment')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="coverage-area-assignment-content"
        id="coverage-area-assignment-header"
      >
        Coverage Area Assignment
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent={'center'} sx={{ overflowY: 'auto', maxHeight: '600px' }}>
          <ServiceOfferings serviceProviderId={serviceProviderId}/>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}