import { Grid } from '@mui/material';
import Loading from 'components/Layout/Loading';
import ConfirmationModal, { ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useEffect } from 'react';
import styles from './marcUpload.module.css';
import useMarcUpload from './marcUploadHookStore';
import MarcUploadForm from './marcUploadForm';

export default function MarcUpload() {
  const { onUpload, init, loadingKey } = useMarcUpload();

  useEffect(() => {
    void init();
  }, []);

  return (
    <ConfirmationModalContextProvider onConfirm={(args) => onUpload(args)}>
      <Loading loadingKey={loadingKey} size={100}>
        <Grid container spacing={2} className='pageGridContainer' justifyContent='center'>
          <Grid container item xs={11} >
            <Grid item xs={12}>
              <PageHeader headerText={'Minimum Acceptable Rate of Conversion Upload'} />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <MarcUploadForm/>
            </Grid>
          </Grid>
        </Grid>
      </Loading>
      <ConfirmationModal
        title={(onConfirmArgs) => (<div className={styles.confirmDeleteModalTitle}>Upload MARC file</div>)}
        message={(onConfirmArgs:any) => {
          if (onConfirmArgs.shouldUpload) {
            return (
              <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.confirmModal}>
                <Grid item xs>
                  You are about to upload {onConfirmArgs.fileName}. You will receive an email with results when complete.
                </Grid>
              </Grid>
            );
          }
        }}
      />
    </ConfirmationModalContextProvider>
  );
}