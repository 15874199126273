import { Grid } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';

export default function AboutProviderInfo() {
  return (
    <Grid item xs={12}>
      <HookTextField
        label='About The Provider'
        name='aboutUs'
        multiline
        minRows={3}
        maxRows={10}
      />
    </Grid>
  );
}