import { MenuItem, Tooltip, Typography } from '@mui/material';
import { IDiscountSchedule } from 'model/discountSchedule';
import { IServiceOffering } from 'model/serviceOffering';
import useProviderSetupStore from 'pages/providerSetup/useProviderSetupStore';
import { useParams, useNavigate, Link } from 'react-router-dom';


export interface ICloneFromButtonProps {
  serviceOffering: IServiceOffering;
}

export default function CloneFromButton({ serviceOffering }: ICloneFromButtonProps) {
  const {
    init,
    provider,
    setCurrentDiscountSchedule,
    setCurrentServiceOffering,
    setServiceType,
    setProvider,
    setServiceOfferingCloneOptions,
  } = useProviderSetupStore();
  const { providerId } = useParams();
  const navigate = useNavigate();

  const onCreateAnotherServiceOfferingClick = async () => {
    try {
      setCurrentServiceOffering({} as IServiceOffering);
      setCurrentDiscountSchedule({} as IDiscountSchedule);
      setServiceType('');
      setServiceOfferingCloneOptions([]);
      return navigate(`/providerOnboarding/${providerId}/service-offerings?cloneServiceProviderId=${provider?.id}&cloneServiceOfferingId=${serviceOffering?.id}`);
    } catch (error) {
      console.error('error attempting to create a new service offering: ', { error });
    }
  };
  return (

    <Typography
      onClick={onCreateAnotherServiceOfferingClick}
      component="a"
      style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
    >
        Clone
    </Typography>

  );
}