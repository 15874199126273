
import { Alert, Grid } from '@mui/material';
import { createEmptyProviderCoverageAreaLaunchToolingForm, IProviderCoverageAreaLaunchToolingForm } from 'model/neighborhoodLaunch';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useProviderCoverageAreaLaunchToolingStore from '../hookStore/useProviderCoverageAreaLaunchTooling';
import ReconcileButton from './components/ReconcileButton';
import SubmitButton from './components/SubmitButton';
import { formatMonthDayTime } from 'util/dateUtil';
import ServiceProviderLaunchForm from './components/ServiceProviderLaunchForm';
import ReconcileModalWrapper from './components/ReconcileModalWrapper';
import ModalWrapper from './components/ConfirmModalWrapper';
import { useParams } from 'react-router-dom';


export interface ILaunchProviderValidationResult {
  isValid:boolean;
}
export interface IProviderCoverageAreaLaunchToolingFormInternalProps {
  initialFormData:IProviderCoverageAreaLaunchToolingForm;
  providerId?:string;
}

function ProviderCoverageAreaLaunchToolingFormInternal({ initialFormData, providerId }:IProviderCoverageAreaLaunchToolingFormInternalProps) {
  const { lastReconciled } = useProviderCoverageAreaLaunchToolingStore();
  const formContext = useForm<IProviderCoverageAreaLaunchToolingForm>({ mode: 'onTouched', defaultValues: createEmptyProviderCoverageAreaLaunchToolingForm() });
  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);
  const formattedLastReconciled = formatMonthDayTime(lastReconciled);
  return (
    <FormProvider {...formContext}>
      <form >
        <Grid container >
          <Grid item xs={4} sx={{ marginBottom: '10px' }}>
            <Alert severity='info'>
              You can only launch NSOs with service offerings that have a provider coverage area set. Others will be disabled.
            </Alert>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <ServiceProviderLaunchForm providerId={providerId}/>
          <Grid container item xs={12} sm={12} md={8} sx={{ marginTop: '20px' }}>
            <Grid container item xs={12} spacing={1}>
              {!providerId && (
                <Grid item xs={12}>
                Last Reconciled: {formattedLastReconciled ? formattedLastReconciled : 'N/A'}
                </Grid>
              )}
              <Grid item xs='auto' >
                <SubmitButton/>
              </Grid>
              {!providerId && (
                <Grid item xs='auto'>
                  <ReconcileButton dryRun={true} text='Reconcile NSOs (Dry Run)'/>
                </Grid>
              )}
              {!providerId && (
                <Grid item xs='auto'>
                  <ReconcileButton dryRun={false} text='Reconcile NSOs'/>
                </Grid>
              )}
            </Grid>
          </Grid>
          <ModalWrapper/>
          <ReconcileModalWrapper/>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function ProviderCoverageAreaLaunchToolingForm() {
  const { formData, init, initWithProvider } = useProviderCoverageAreaLaunchToolingStore();
  const { providerId } = useParams();
  useEffect(() => {
    if (providerId) {
      void initWithProvider(providerId);
    } else {
      void init();
    }

  }, [providerId]);
  return (
    <ProviderCoverageAreaLaunchToolingFormInternal
      initialFormData={formData}
      providerId={providerId}
    />
  );
}