import { findMarkets } from 'api/marketsApi';
import { oldUploadHomeSales, uploadHomeSales } from 'api/neighborhoodApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IDropdownOption } from 'model/dropdown';
import { MarketUtils } from 'model/markets';
import { useEffect, useState } from 'react';

type HomeSaleUpload = {
  onDropFile:Function;
  acceptedFiles:any;
  markets: IDropdownOption[];
}
const loadingKey = 'HomeSaleUpload';

const { get, update, registerListener, unregisterListener } = createStore<HomeSaleUpload>('HomeSaleUpload', {
  onDropFile: () => {},
  acceptedFiles: [],
  markets: [],
});

export default function useHomeSaleUpload() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();
  const { homeSaleReworkJan2025 } = useFlags();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        acceptedFiles: [],
      });
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    const marketsResp = await findMarkets();
    const markets = MarketUtils.convertToDropdownOptions(marketsResp.data);
    update({
      ...get(),
      markets,
    });


    doneLoading(300);
  }

  async function onUpload(onConfirmArgs) {
    try {

      await oldUploadHomeSales(onConfirmArgs.data);
      await uploadHomeSales(onConfirmArgs.data);
      createSuccessToast('Request received.');
    } catch (e:any) {
      createErrorToast(e.response.data);
    }
  }

  async function onDropFile(acceptedFiles, ctx) {
    try {
      ctx.setValue('homeSaleTemplate', acceptedFiles[0]);
      ctx.setValue('fileName', acceptedFiles[0].name);
      update({
        ...get(),
        acceptedFiles,
      });
    } catch (e:any) {

      // setErrorMessage(e.response.data);
    }

  }


  return {
    loadingKey,
    ...get(),
    init,
    onDropFile,
    onUpload,
  };
}