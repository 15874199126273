import GenericModal from 'components/modal/GenericModal';
import useModal from 'components/modal/useModal';
import useToast from 'components/toast/useToast';
import useTsoiManagementBatch from '../hookStore/useTsoiManagementBatch';
import { Grid, Typography } from '@mui/material';
import { submitTsoiManagementBatch, submitTsoiManagementBatchInviteOnly } from 'api/tsoiManagementApi';
import { useLoading } from 'components/Layout/Loading';

export const enrollModalStoreKey = 'SendHappeningSoonEnrollmentNotificationsModal';

export interface ISendHappeningSoonEnrollmentNotificationsModal {
  batchId: string;
}
export function createEmptySendHappeningSoonEnrollmentNotificationsModal() :ISendHappeningSoonEnrollmentNotificationsModal {
  return {
    batchId: '',
  };
}

export default function SendHappeningSoonEnrollmentNotificationsModal({ batchId }) {
  const { closeModal } = useModal(enrollModalStoreKey);
  const { createSuccessToast, createErrorToast } = useToast();
  const { loadingKey } = useTsoiManagementBatch();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const onSubmit = async (data:any) => {
    onLoading();
    try {
      await submitTsoiManagementBatch(batchId);
      createSuccessToast('Messaging process started successfully!');
    } catch (error) {
      console.error(error);

      if ((error as any).response.data.message) {
        createErrorToast((error as any).response.data.message);
      } else {
        createErrorToast('Error starting messaging process :(');
      }

    }
    doneLoading(300);
    closeModal(createEmptySendHappeningSoonEnrollmentNotificationsModal());
  };
  return (
    <GenericModal
      modalStoreKey={enrollModalStoreKey}
      title={'Confirm'}
      modalContent={
        (
          <Grid container item justifyContent='center' rowSpacing={2}>
            <Grid item xs={10}>
              <Typography variant='body1'>Great job with this TSOI batch, you're awesome!</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant='body1'>Press the "Send" button to send text/email Happening Soon notifications to the service providers on this batch.
              </Typography>
              <Typography variant='body1' sx={{ paddingTop: '20px' }}>
                If auto-enrollment is available then that will be used. If you need a service provider to be sent
                the happening soon invite then either use the other button or check the 'Happening Soon Invite Preferred?' checkbox on service provider details.</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant='body1'>This can not be undone!</Typography>
            </Grid>
          </Grid>
        )
      }
      onConfirm={onSubmit}
      onSecondaryAction={() => closeModal(createEmptySendHappeningSoonEnrollmentNotificationsModal())}
      primaryButtonText='Send'
      secondaryButtonText='Cancel'
      overrideSx={{
        '& .MuiPaper-root': {
          width: '500px',
        },
      }}
    />
  );
}