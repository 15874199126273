import useErrorState from 'hooks/reactHookForm/useErrorState';
import {
  Box, FormControl, Grid, Tabs, Tab, InputLabel, Typography, FormHelperText,
} from '@mui/material';
import { HookSelect, HookTextField } from 'components/reactHookForm';
import { TextInput } from 'pages/discountSchedule/formComponents';
import { useEffect, useState } from 'react';
import { FormProvider, get, useFormContext, useWatch } from 'react-hook-form';
import { PrimaryButton } from 'components/buttons';
import useToast from 'components/toast/useToast';
import PriceLabel from './PriceLabel';

function a11yProps(index: number) {
  return {
    'id': `price-style-tab-${index}`,
    'aria-controls': `price-tabpanel-${index}`,
  };
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`price-tabpanel-${index}`}
      aria-labelledby={`price-style-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function PriceFormTabs({ defaultHasPrice }) {
  const defaultTabValue = defaultHasPrice ? 0 : 1;
  const [tabValue, setTabValue] = useState(defaultTabValue);
  const formContext = useFormContext();
  const { createInfoToast } = useToast();
  const { isError, getErrorMessage } = useErrorState(formContext);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (newValue === 0) {
      formContext.setValue('hasPrice', true);
      formContext.setValue('noPriceTextField', '');
    } else {
      formContext.setValue('hasPrice', false);
      formContext.setValue('noPriceTextField', '');
    }
  };

  const watchedHasPrice = useWatch({ control: formContext.control, name: 'hasPrice' });
  const watchedPriceAmount = useWatch({ control: formContext.control, name: 'priceAmount' });

  useEffect(() => {
    if (watchedHasPrice) {
      setTabValue(0);
    } else {
      setTabValue(1);
    }
  }, [watchedHasPrice]);

  useEffect(() => {
    if (watchedPriceAmount && watchedPriceAmount > 0) {
      formContext.setValue('hasPrice', true);
    }
  }, [watchedPriceAmount]);


  return (
    <FormProvider {...formContext}>
      <Tabs value={tabValue} onChange={handleChange} aria-label="price or no price tabs" sx={{ marginBottom: '20px' }}>
        <Tab label="Price" {...a11yProps(0)} />
        <Tab label="No Price" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Grid container item spacing={2} sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Grid item xs={2}>
            <PriceLabel/>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant='standard' fullWidth error={isError('priceAmount')}>
              <HookTextField
                control={formContext.control}
                rules={{ required: { value: true, message: 'Required' } }}
                name='priceAmount'
                id='price-amount'
                label='Price Amount'
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant='standard' fullWidth error={isError('priceSuffix')}>
              <InputLabel htmlFor="price-suffix">Price Suffix</InputLabel>
              <HookSelect
                control={formContext.control}
                rules={{
                  required: { value: true, message: 'Required' },
                }}
                name='priceSuffix'
                id='price-suffix'
                menuItems={[
                  { id: 'per home', name: 'per home' },
                  { id: 'per job', name: 'per job' },
                  { id: 'per visit', name: 'per visit' },
                  { id: 'per cubic yard', name: 'per cubic yard' },
                  { id: 'per bale', name: 'per bale' },
                  { id: 'per month', name: 'per month' },
                  { id: 'per week', name: 'per week' },
                  { id: 'per lawn', name: 'per lawn' },
                  { id: 'per treatment', name: 'per treatment' },
                  { id: 'per cleaning', name: 'per cleaning' },
                  { id: 'per vehicle', name: 'per vehicle' },
                  { id: 'per service call', name: 'per service call' },
                ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant='standard' fullWidth error={isError('priceSubText')}>
              <InputLabel htmlFor="price-subtext">Price Subtext</InputLabel>
              <HookSelect
                control={formContext.control}
                rules={{
                  required: { value: false, message: 'Required' },
                }}
                name='priceSubText'
                id='price-subtext'
                menuItems={[
                  { id: 'free estimate', name: 'free estimate' },
                  { id: 'varies by job', name: 'varies by job' },
                  { id: 'varies by home', name: 'varies by home' },
                  { id: 'varies by service', name: 'varies by service' },
                  { id: 'waived if hired', name: 'waived if hired' },
                ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs='auto' alignItems={'center'} alignContent={'center'}>
            <PrimaryButton
              sx={{
                'maxWidth': '80px',
                '&&&': {
                  minWidth: '80px',
                },
                'maxHeight': '30px',
                'marginTop': '10px',
              }}
              onClick={() => {
                formContext.setValue('priceAmount', null);
                formContext.setValue('priceLabel', null);
                formContext.setValue('priceSuffix', null);
                formContext.setValue('priceSubText', null);
                formContext.setValue('unitBasedPricingFlag', null);
              }}>Clear</PrimaryButton>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <TextInput
              name='noPriceTextField'
              label='No Price Text Field'
              maxLength='100'
              required
            />
          </Grid>
        </Grid>
      </TabPanel>
    </ FormProvider>
  );
}