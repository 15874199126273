import Spacer from 'components/Layout/Spacer';
import PageHeader from 'components/SectionHeaders/PageHeader';

import { Button, Grid } from '@mui/material';
import useTerritoryDetails from '../hookStore/useTerritoryDetails';
import { TerritoryNeighborhoodsSkipTraceConfirmationModalKey } from './TerritoryNeighborhoodsSkipTraceConfirmationModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';

export default function TerritoryDataSection(props: any) {
  const {
    lastSkipTracedTimestamp,
  } = useTerritoryDetails();
  const { openModal } = useConfirmationModal(TerritoryNeighborhoodsSkipTraceConfirmationModalKey);

  const onSkiptraceButtonClick = () => {
    openModal({});
  };

  var dateDisplay = !lastSkipTracedTimestamp ? 'Never' : new Date(lastSkipTracedTimestamp).toLocaleString();
  var buttonDisabled = false;
  if (!!lastSkipTracedTimestamp) {
    buttonDisabled = new Date().getTime() - new Date(lastSkipTracedTimestamp).getTime() < 60 * 60 * 1000;
  }
  var buttonText = buttonDisabled ? 'Must wait 1hr between skiptracing attempts' : 'Skiptrace Neighborhoods in Territory';
  return (
    <Grid container item xs={12} spacing={1} className='pageGridContainer' paddingBottom={'25px'}>
      <Grid container item xs={12}>
        <PageHeader headerText={'Territory Data'}/>
      </Grid>
      <Grid item xs={12}>
        <Grid container item spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => onSkiptraceButtonClick() }
              disabled={buttonDisabled}
            >
              <span>{buttonText}</span>
            </Button>
          </Grid>
          <Grid item>
            <p>Last Skiptraced on: {dateDisplay}</p>
          </Grid>
        </Grid>
      </Grid>
      <Spacer/>
    </Grid>
  );
}