
import { Grid } from '@mui/material';
import { createEmptyServiceProviderLaunchDto } from 'model/neighborhoodLaunch';
import ServiceProviderLaunchCards from 'pages/neighborhoodLaunchTooling/formComponents/ServiceProviderLaunchCards';
import ServiceProviderSingleSelect from 'pages/neighborhoodLaunchTooling/formComponents/ServiceProviderSingleSelect';
import useProviderCoverageAreaLaunchToolingStore from 'pages/neighborhoodLaunchTooling/hookStore/useProviderCoverageAreaLaunchTooling';
import { useFieldArray, useFormContext } from 'react-hook-form';

export interface IServiceProviderLaunchFormProps {
  providerId?:string;
}

export default function ServiceProviderLaunchForm( { providerId }:IServiceProviderLaunchFormProps) {
  const { control } = useFormContext();
  const { onSelectProvider, clearLastReconciled } = useProviderCoverageAreaLaunchToolingStore();
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'serviceProviderLaunchDtos',
  });

  return (
    <>
      {!providerId && (
        <Grid container item xs={12} sm={12} md={4} >
          <ServiceProviderSingleSelect
            onServiceProviderAdd={(id, name) => {
              append(createEmptyServiceProviderLaunchDto(id, name));
              void onSelectProvider(id);
            }}
            onServiceProviderRemove={(index) => {
              remove(index);
              clearLastReconciled();
            }}
            variant='providerCoverageAreaLaunchTooling'
          />
        </Grid>
      )}
      <Grid container item xs={12}>
        <ServiceProviderLaunchCards
          disableServiceOfferingsWithoutProviderCoverageArea={true}
          fields={fields}
          onReplace={replace}
          variant='providerCoverageAreaLaunchTooling'
        />
      </Grid>
    </>
  );
}