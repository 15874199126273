;
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import useProviderCoverageArea from '../hookStore/useProviderCoverageArea';
import CoverageArea from './CoverageArea';
import { useParams } from 'react-router-dom';

export default function CoverageAreaList() {
  const { control } = useFormContext();
  const { fields: coverageAreas, append, replace, move, remove } = useFieldArray({
    name: 'providerCoverageAreas',
  });
  const { onRemoveCoverageArea } = useProviderCoverageArea();
  const { providerId } = useParams();
  const watchCoverageAreas = useWatch({ control, name: 'providerCoverageAreas' });
  useEffect(() => {
    if (coverageAreas.length !== watchCoverageAreas.length) {
      replace(watchCoverageAreas);
    }
  }, [watchCoverageAreas]);
  return (
    <>
      <Grid container item xs={12} sm={12} md={12} alignItems='center'>
        {coverageAreas.map((x, i) => {
          return (
            <CoverageArea key={x.id} index={i} remove={async ()=> {
              await onRemoveCoverageArea(i);
              remove(i);
            }}/>
          );
        })}
      </Grid>
    </>
  );
}