import { sortByString } from 'util/sortFunctions';
import { launchProviders } from 'api/neighborhoodLaunchApi';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { ICheckable } from 'model/ancillary';
import { IDropdownOption } from 'model/dropdown';
import { createEmptyLaunchProvidersResult, createEmptyNeighborhoodLaunchForm, ILaunchProvidersResult, INeighborhoodLaunchForm, IServiceProviderLaunchDTO } from 'model/neighborhoodLaunch';
import { IServiceOffering } from 'model/serviceOffering';
import { IServiceProvider, StatusType } from 'model/serviceProvider';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const loadingKey = 'NeighborhoodLaunchToolingStore';
type NeighborhoodLaunchToolingStore = {
  formData:INeighborhoodLaunchForm;
  serviceProviderOptions: IDropdownOption[];
  neighborhoodOptions: IDropdownOption[];
  providerCoverageZoneOptions: IDropdownOption[];
  serviceProviderToServiceOfferingsMap:Map<string, IServiceOffering[]>;
  launchProvidersResult:ILaunchProvidersResult;
}

const { get, update, registerListener, unregisterListener } = createStore<NeighborhoodLaunchToolingStore>('NeighborhoodLaunchToolingStore', {
  formData: createEmptyNeighborhoodLaunchForm(),
  serviceProviderOptions: [],
  neighborhoodOptions: [],
  providerCoverageZoneOptions: [],
  serviceProviderToServiceOfferingsMap: new Map<string, IServiceOffering[]>(),
  launchProvidersResult: createEmptyLaunchProvidersResult(),
});

export default function useNeighborhoodLaunchTooling() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createInfoToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      update({
        ...get(),
      });
    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }


  async function save(data:INeighborhoodLaunchForm) {
    const dataToSave = { ...data };
    onLoading();
    const neighborhoodIds = extractNeighborhoods( dataToSave.neighborhoodIds);
    dataToSave.neighborhoodIds = neighborhoodIds;
    dataToSave.serviceProviderLaunchDtos = dataToSave.serviceProviderLaunchDtos.map(x => {
      let toReturn = {
        ...x,
        neighborhoodIds: neighborhoodIds,
        serviceOfferingIds: x.serviceOfferingOptions.filter(y => y && y.checked && y.value).map(z => z.value as string),
      };
      toReturn.serviceOfferingOptions = [];
      return toReturn;
    });
    try {
      const res = await launchProviders(dataToSave);
      createInfoToast('Provider Launch received and processing. You will be emailed results.');
      doneLoading(200);
    } catch (err:any) {
      console.error(err);
      doneLoading(200);
    }
  }

  function convertServiceProvidersForDropdown(data:IServiceProvider[]): IDropdownOption[] {
    let filtered = data
      .filter(x => x.status === StatusType.ACTIVE)
      .map(x => {
        return {
          key: x.id,
          optionText: x.name,
          optionValue: x.id,
        };
      }).sort(sortByString('optionText', false));
    return filtered;
  }

  function extractNeighborhoods(individualNeighborhoodIds) {
    const neighborhoodIds:string[] = [];
    const uniqueNeighborhoodIds:string[] = [];
    neighborhoodIds.push(...individualNeighborhoodIds);
    const neighborhoodIdSet = new Set(neighborhoodIds);
    neighborhoodIdSet.forEach((id)=>{
      uniqueNeighborhoodIds.push(id);
    });
    return uniqueNeighborhoodIds;
  }

  // takes getValues and setValue from formContext as parameters which is used to retrieve and set data on the card using the index
  async function populateLaunchCard(getValues, setValue, index) {
    const { serviceProviderToServiceOfferingsMap } = get();
    const launchDto:IServiceProviderLaunchDTO = getValues(`serviceProviderLaunchDtos.${index}`);
    const serviceOfferingsRes = await findServiceOfferingsByProvider(launchDto.serviceProviderId);
    if (serviceOfferingsRes.data) {
      serviceProviderToServiceOfferingsMap.set(launchDto.serviceProviderId, serviceOfferingsRes.data);
      let checkableServiceOfferings = convertServiceOfferingsForChecklist(serviceOfferingsRes.data);
      setValue(`serviceProviderLaunchDtos.${index}.serviceOfferingOptions`, checkableServiceOfferings);
    }
    update({
      ...get(),
      serviceProviderToServiceOfferingsMap,
    });
  }

  function convertServiceOfferingsForChecklist(data:IServiceOffering[]):ICheckable[] {
    return data.map(x => {
      return {
        id: x.id ?? '',
        checked: x.defaultForServiceType ?? false,
        displayValue: x.name ?? '',
        value: x.id ?? '',
        hasCoverageArea: x.providerCoverageAreaId !== '' && x.providerCoverageAreaId !== null,
      };
    });
  }


  return {
    ...get(),
    loadingKey,
    init,
    save,
    populateLaunchCard,
  };
}