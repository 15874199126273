import PageHeader from 'components/SectionHeaders/PageHeader';
import IndividualsSearch from './IndividualsSearch';
import IndividualTabs from './IndividualTabs';
import useIndividualsHookStore from './individualsHookStore';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QueueLaunchLeaderBatchModal from './launchLeaderManagement/queueLaunchLeaderBatch/QueueLaunchLeaderBatch';
import MarkAsCalledModal from './launchLeaderManagement/markAsCalled/MarkAsCalled';
import CancelApplicationModal from './launchLeaderManagement/cancelApplication/CancelApplicationModal';
import PauseApplicationModal from './launchLeaderManagement/pauseApplication/PauseApplicationModal';
import ViewInCustomerIoModal from './launchLeaderManagement/viewInCustomerIo/ViewInCustomerIoModal';

interface IndividualDetailsProps {
  queueLaunchBatch?: boolean;
  markAsCalled?: boolean;
  cancelLaunchLeaderApplication?: boolean;
  pauseLaunchLeaderApplication?: boolean;
  viewInCustomerIo?: boolean;
}

export default function IndividualDetails(props :IndividualDetailsProps) {
  const {
    queueLaunchBatch,
    markAsCalled,
    cancelLaunchLeaderApplication,
    pauseLaunchLeaderApplication,
    viewInCustomerIo,
  } = props;

  const { init } = useIndividualsHookStore();
  const { individualId } = useParams();
  useEffect(() => {
    void init();
  }, [individualId]);

  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Individuals'} />
      </Grid>
      <Grid item xs={12}>
        <IndividualsSearch />
      </Grid>
      <Grid item xs={12}>
        <IndividualTabs />
      </Grid>
      {queueLaunchBatch && (
        <Grid item xs={12}>
          <QueueLaunchLeaderBatchModal shouldOpen={queueLaunchBatch}/>
        </Grid>
      )}
      {markAsCalled && (
        <Grid item xs={12}>
          <MarkAsCalledModal shouldOpen={markAsCalled}/>
        </Grid>
      )}
      { cancelLaunchLeaderApplication && (
        <Grid item xs={12}>
          <CancelApplicationModal shouldOpen={cancelLaunchLeaderApplication}/>
        </Grid>
      )}
      {pauseLaunchLeaderApplication && (
        <Grid item xs={12}>
          <PauseApplicationModal shouldOpen={pauseLaunchLeaderApplication}/>
        </Grid>
      )}
      { viewInCustomerIo && (
        <Grid item xs={12}>
          <ViewInCustomerIoModal shouldOpen={viewInCustomerIo}/>
        </Grid>
      )}
    </Grid>

  );
}