import useNeighborhoodBatchForNeighborhoodLead from './hookStore/useLetterBatchNeighborhoodForNeighborhoodLead';
import { Autocomplete, TextField } from '@mui/material';

export default function BatchOptionsDropdown() {
  var {
    letterBatchOptions,
    selectedBatch,
    updateSelectedBatch,
  } = useNeighborhoodBatchForNeighborhoodLead();

  return <Autocomplete
    placeholder=''
    id='letter-batch-select'
    options={letterBatchOptions}
    value={selectedBatch}
    onChange={(e, value:any, reason, details) => {
      if (reason === 'selectOption') {
        updateSelectedBatch(value);
      }
    }}
    renderOption={(props, option, { selected }) => (
      <li {...props}>
        {option.optionText}
      </li>
    )}
    isOptionEqualToValue={(option, value) => {
      return option.optionValue === value.optionValue;
    }}
    getOptionLabel={(option) => {
      return option?.optionText ?? '';
    }}
    renderInput={(params) => (
      <TextField {...params} label={'Batch'} />
    )}
  />;
}