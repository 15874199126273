import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createLetterBatchNeighborhoodForNeighborhoodLead, getBatchOptionsForNeighborhoodLead } from 'api/letterBatchesApi';
import { ILetterBatch, ILetterBatchOptionsForNeighborhoodLead } from 'model/letter_batch/letterBatch';
import useMarkets from 'hooks/useMarkets';
import { format } from 'date-fns';
import { IDropdownOption } from 'model/dropdown';

const loadingKey = 'CreateNeighborhoodBatchForNeighborhoodLead';
interface IBatchMenuItem {
  id: string;
  optionValue: string;
  optionText: string;
}

export interface IBatchSelectionForm {
  letterBatch: IBatchMenuItem | null;
}

type CreateNeighborhoodBatchForNeighborhoodLead = {
  individualId:string;
  individualName:string;
  neighborhoodId:string;
  neighborhoodName:string;
  marketName:string;
  marketId:string;
  letterBatchOptions: IDropdownOption[];
  letterBatches:ILetterBatch[];
  selectedBatch: IDropdownOption | null;

}

const { get, update, registerListener, unregisterListener } =
createStore<CreateNeighborhoodBatchForNeighborhoodLead>('CreateNeighborhoodBatchForNeighborhoodLead', {
  individualId: '',
  individualName: '',
  neighborhoodId: '',
  neighborhoodName: '',
  marketName: '',
  marketId: '',
  letterBatchOptions: [],
  letterBatches: [],
  selectedBatch: null,
});


export default function useNeighborhoodBatchForNeighborhoodLead() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading, getLoadingState } = useLoading(loadingKey);
  const { individualId } = useParams();
  const { createErrorToast } = useToast();
  const { marketMap, markets } = useMarkets();
  const navigate = useNavigate();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
      });
      unregisterListener(setState);
    };
  }, []);

  useEffect(() => {
    void init();
  }, [markets]);


  async function init() {
    try {
      if (!individualId) {
        createErrorToast('No individualId found in URL');
        return;
      }

      onLoading();
      const resp = await getBatchOptionsForNeighborhoodLead(individualId);
      const letterBatches = resp.data.letterBatches;
      const formattedBatchOptions = formatBatchOptions(letterBatches);
      const firstIndexMatchingMarket = letterBatches.findIndex((batch) => batch.marketId === resp.data.marketId);
      const selectedBatch = formattedBatchOptions[firstIndexMatchingMarket]; // default to the first batch matching the users market

      update({
        ...get(),
        individualId: resp.data.individualId,
        individualName: resp.data.individualName,
        neighborhoodId: resp.data.neighborhoodId,
        neighborhoodName: resp.data.neighborhoodName,
        marketName: resp.data.marketName,
        letterBatches: letterBatches,
        letterBatchOptions: formattedBatchOptions,
        selectedBatch: selectedBatch,
      });
    } catch (e:any) {
      createErrorToast('An error occurred during init: '+e.response?.data?.message);
    }
    doneLoading(150);
  }

  function formatBatchOptions(batches:ILetterBatch[]):IDropdownOption[] {
    return batches.map((batch) => {
      const { id, batchName, externalBatchId } = batch;
      const marketName = marketMap.get(batch.marketId!)?.name ?? 'Unknown Market';
      return {
        key: id!,
        optionValue: id!,
        optionText: `${marketName} - ${batchName} (${externalBatchId})`,
      };
    });
  }

  function updateSelectedBatch(batch:IDropdownOption) {
    update({
      ...get(),
      selectedBatch: batch,
    });
  }

  async function onSubmit() {
    try {
      const { selectedBatch } = get();
      const { isLoading } = getLoadingState();

      if (isLoading) {
        console.log('currently in loading state, not submitting');
        return;
      }
      if (!selectedBatch || !selectedBatch.key) {
        createErrorToast('No batch selected');
        return;
      }
      if (!individualId) {
        createErrorToast('No individualId found in URL');
        return;
      }

      onLoading();
      const resp = await createLetterBatchNeighborhoodForNeighborhoodLead(individualId, selectedBatch?.key!);
      if (!resp.data.letterBatchNeighborhoodId) {
        createErrorToast('Failed to create letter batch neighborhood');
      }

      const url = `/letterBatches/${selectedBatch.key}/letterBatchNeighborhoods/${resp.data.letterBatchNeighborhoodId}`;
      navigate(url);
    } catch (error:any) {
      console.error(error);
      createErrorToast('An error occurred during creation: '+error.response?.data?.message);
    }

    doneLoading(150);
  }


  return {
    ...get(),
    init,
    loadingKey,
    onSubmit,
    updateSelectedBatch,
  };
}