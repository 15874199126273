import { Button, Grid, Icon, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Pause, Cancel, Check, Launch } from '@mui/icons-material';

export default function QuickActionButtons() {
  const navigate = useNavigate();
  const { individualId } = useParams();

  return (
    <>
      <Typography variant="body1" fontWeight={'bold'} paddingTop={'15px'} paddingBottom={'15px'}>Quick Actions</Typography>
      <Grid container item spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color='success'
            startIcon={<Check /> }
            onClick={() => {
              navigate(`/individuals/${individualId}/markAsCalled`);
            }}
          >
            Mark as Called
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color='warning'
            startIcon={<Cancel />}
            onClick={() => {
              navigate(`/individuals/${individualId}/cancelApplication`);
            }}
          >
            Cancel Application
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color='secondary'
            startIcon={<Pause />}
            onClick={() => {
              navigate(`/individuals/${individualId}/pauseApplication`);
            }}
          >
            Pause Application
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color='primary'
            startIcon={<Launch />}
            onClick={() => {
              navigate(`/individuals/${individualId}/viewInCustomerIo`);
            }}
          >
            View in customer.io
          </Button>
        </Grid>
      </Grid>
    </>
  );
}