
export class CadenceType {
  static get SUBSCRIPTION_ONLY():string {
    return 'SUBSCRIPTION_ONLY';
  }
  static get MOSTLY_SUBSCRIPTION():string {
    return 'MOSTLY_SUBSCRIPTION';
  }
  static get MOSTLY_ONETIME():string {
    return 'MOSTLY_ONETIME';
  }
  static get ONETIME_ONLY():string {
    return 'ONETIME_ONLY';
  }

  static list = [
    { id: CadenceType.SUBSCRIPTION_ONLY, name: 'Subscription Only' },
    { id: CadenceType.MOSTLY_SUBSCRIPTION, name: 'Mostly Subscription' },
    { id: CadenceType.MOSTLY_ONETIME, name: 'Mostly One Time' },
    { id: CadenceType.ONETIME_ONLY, name: 'One Time Only' },
  ];

  static getLabel(cadenceType:string):string {
    return CadenceType.list.find(x => x.id === cadenceType)?.name || '';
  }
}

export class FieldType {
  static get SELECT():string {
    return 'select';
  }
  static get MULTISELECT():string {
    return 'multiselect';
  }
  static get list():any[] {
    return [
      { id: FieldType.SELECT, name: 'Select' },
      { id: FieldType.MULTISELECT, name: 'Multiselect' },
    ];
  }
}

export class CustomFieldGroupType {

  static get ADDITIONAL_SERVICES():string {
    return 'ADDITIONAL_SERVICES';
  }
  static get NONE():string {
    return '';
  }

  static get list():any[] {
    return [
      { id: CustomFieldGroupType.NONE, name: 'None' },
      { id: CustomFieldGroupType.ADDITIONAL_SERVICES, name: 'Additional Services' },
    ];
  }
}

export interface ICustomFieldOption {
  optionText: string;
  optionValue: string;
}

export interface IWorkflowStep{
  stepName: string;
  shouldShow: boolean;
}

export interface ICustomField {
  customFieldOptions:ICustomFieldOption[];
  fieldLabel: string;
  fieldName: string;
  fieldPlaceholderText: string;
  fieldTypeCode: string; //see FieldType
  required: boolean;
  customFieldGroupType:string | null;
}

export interface ISubscriptionOption {
  optionName: string;
  optionValue: string;
  isDefaultOption:boolean;
  isOneTime:boolean;
}

export interface IFieldSubscriptionOption extends ISubscriptionOption{
  isNew:boolean;
}


export interface ISubscriptionOptions {
  label:string;
  options:ISubscriptionOption[];
  subscriptionTermInMonths:number;
}

export interface IFormFriendlySubscriptionOptions {
  label:string;
  options:IFieldSubscriptionOption[];
  subscriptionTermInMonths:number;
}

export function createEmptySubscriptionOptions():ISubscriptionOptions {
  return {
    label: '',
    options: [],
    subscriptionTermInMonths: 0,
  };
}

export interface IServiceDetailSection {
  sectionHeaderName: string;
  sectionIcon?: string;
  sectionItems: string[];
  sectionListItems: string[];
}

export interface IFormFriendlyServiceDetailSection{
  sectionHeaderName: string;
  sectionIcon?: string;
  sectionItems: any[];
  sectionListItems: any[];
}

export interface IFormFriendlyServiceOffering {
  id: string | null;
  name: string;
  serviceTypeId:string;
  serviceType:string;
  serviceCategory:string;
  frequency:string;
  invitationDescription:string;
  cadenceType:string; //see CadenceType
  subscriptionOptions:IFormFriendlySubscriptionOptions;
  serviceDetailSections:IFormFriendlyServiceDetailSection[];
  workflowSteps: IWorkflowStep[];
  customFields:ICustomField[];
  providerId:string;
  defaultForServiceType:boolean;
  defaultDiscountScheduleId:string;
  cutoffDayCount:number | null;
  territoryServiceOfferingEnabled: boolean;
  providerCoverageAreaId:string | null;
  hasDisabledMaxCustomerCount?:boolean;
}

export interface IServiceOfferingWithProviderCoverageArea {
  id: string | null;
  name: string;
  providerId:string;
  providerCoverageAreaId:string | null;
}

export interface IServiceOffering {
  id: string | null;
  name: string;
  serviceTypeId:string;
  serviceType:string;
  serviceCategory:string;
  frequency?:string | null;
  invitationDescription?:string | null;
  cadenceType:string; //see CadenceType
  subscriptionOptions?:ISubscriptionOptions;
  serviceDetailSections:IServiceDetailSection[];
  customFields:ICustomField[];
  workflowSteps: IWorkflowStep[];
  providerId:string;
  defaultForServiceType:boolean;
  defaultDiscountScheduleId:string;
  cutoffDayCount:number | null;
  territoryServiceOfferingEnabled: boolean;
  disabledNeighborhoodServiceOfferingsDate:string | null;
  imageRefs: IServiceOfferingImageRef[];
  providerCoverageAreaId:string | null;
}
export interface IServiceOfferingImageRef {
  imageFileName: string;
  thumbnailImageFileName:string;
  markedForDeletion:boolean;
}
export interface IServiceOfferingFormDTO {
  serviceOffering:IServiceOffering;
  existingDefaultServiceOffering:IServiceOffering | null;
  hardDeletedImageRefs: IServiceOfferingImageRef[];
}

export function createEmptyServiceOffering():IServiceOffering {
  return {
    id: '',
    name: '',
    serviceTypeId: '',
    serviceType: '',
    serviceCategory: '',
    frequency: '',
    invitationDescription: '',
    cadenceType: '',
    subscriptionOptions: createEmptySubscriptionOptions(),
    serviceDetailSections: [],
    customFields: [],
    workflowSteps: [],
    providerId: '',
    defaultForServiceType: false,
    defaultDiscountScheduleId: '',
    cutoffDayCount: 0,
    disabledNeighborhoodServiceOfferingsDate: '',
    territoryServiceOfferingEnabled: false,
    imageRefs: [],
    providerCoverageAreaId: '',
  };
}

export interface IServiceOfferingStatusCheckDTO {
  disabledServiceOfferings:IServiceOffering[];
  hasDisabledServiceOfferings:boolean;
}

export function createEmptyServiceOfferingStatusCheckDTO():IServiceOfferingStatusCheckDTO {
  return {
    disabledServiceOfferings: [],
    hasDisabledServiceOfferings: false,
  };
}


export interface IAssignCoverageAreaDTO {
  serviceOfferingId:string;
  providerCoverageAreaId:string | null;
}