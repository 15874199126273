import { Accordion, AccordionSummary, Typography, AccordionDetails, Grid, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrimaryInfo from './PrimaryInfo';
import CheckProviderUsers from './CheckProviderUsers';
import AdditionalRelevantPlatformRatings from '../advanced/components/AdditionalRelevantPlatformRatings';
import FacebookRatingInfo from '../advanced/components/FacebookRatingInfo';
import RatingInfo from '../advanced/components/RatingInfo';
import StreetFairProviderRating from '../advanced/components/StreetFairProviderRating';

export default function CoreSettings() {

  return (
    <Accordion defaultExpanded >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Core Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <PrimaryInfo/>
          <Grid container item xs={12} sx={{ height: '32px' }}></Grid>
          <Grid container item xs={12} alignItems={'flex-start'}>
            <Grid container item xs={12} md='auto'>
              <CheckProviderUsers/>
            </Grid>
            <Grid item xs='auto'>
              <Box sx={{ width: { xs: '0px', md: '10px' } }}></Box>
            </Grid>
            <Grid container item xs={12} md={6}>
              <RatingInfo/>
              <FacebookRatingInfo/>
              <AdditionalRelevantPlatformRatings/>
              <StreetFairProviderRating/>
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ height: '20px' }}></Grid>

        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}