import { PrimaryButton } from 'components/buttons';
import useNeighborhoodBatchForNeighborhoodLead from './hookStore/useLetterBatchNeighborhoodForNeighborhoodLead';
import { useLoading } from 'components/Layout/Loading';

export default function CreateButton() {
  const { onSubmit, loadingKey } = useNeighborhoodBatchForNeighborhoodLead();
  const { getLoadingState } = useLoading(loadingKey);
  const { isLoading } = getLoadingState();

  return (
    <>
      <PrimaryButton
        type='submit'
        fullWidth
        onClick={onSubmit}
        disabled={isLoading}
      >
        Add to Batch
      </PrimaryButton>
    </>
  );
}