import { IconButton, Grid, Tooltip, Card } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { IDiscountSchedule } from 'model/discountSchedule';
import { IServiceOffering } from 'model/serviceOffering';
import useProviderSetupStore from '../useProviderSetupStore';
import { useNavigate, useParams } from 'react-router-dom';

export interface IAddServiceOfferingProps {
  defaultServiceOffering?: IServiceOffering | null;
}

export default function AddServiceOffering({ defaultServiceOffering }: IAddServiceOfferingProps) {
  const {
    init,
    provider,
    setCurrentDiscountSchedule,
    setCurrentServiceOffering,
    setServiceType,
    setProvider,
    setServiceOfferingCloneOptions,
  } = useProviderSetupStore();
  const { providerId } = useParams();
  const navigate = useNavigate();

  const onCreateAnotherServiceOfferingClick = async () => {
    try {
      setCurrentServiceOffering({} as IServiceOffering);
      setCurrentDiscountSchedule({} as IDiscountSchedule);
      setServiceType('');
      setServiceOfferingCloneOptions([]);
      if (providerId !== 'create' && defaultServiceOffering) {
        return navigate(`/providerOnboarding/${providerId}/service-offerings?cloneServiceProviderId=${provider?.id}&cloneServiceOfferingId=${defaultServiceOffering?.id}`);
      } else {
        return navigate(`/providerOnboarding/${providerId}/service-offerings`);
      }

    } catch (error) {
      console.error('error attempting to create a new service offering: ', { error });
    }
  };


  return (
    <Grid container item xs={'auto'} sx={{
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}>

      <Tooltip title={'Add a new service offering'}>
        <IconButton color="primary" aria-label="add"
          onClick={onCreateAnotherServiceOfferingClick}
          sx={{
            color: '#4F9866',

          }}>
          <AddIcon sx={{ fontSize: 100 }} />
        </IconButton>
      </Tooltip>

    </Grid>
  );
}