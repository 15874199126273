import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, FormHelperText, Grid, Typography } from '@mui/material';
import { HookCheckbox } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import useNeighborhoodLaunchTooling from '../hookStore/useNeighborhoodLaunchTooling';
import useProviderCoverageAreaLaunchToolingStore from '../hookStore/useProviderCoverageAreaLaunchTooling';
import styles from './formComponents.module.css';

export interface IServiceProviderLaunchCardProps {
  serviceProviderId: string;
  index:number;
  disableServiceOfferingsWithoutProviderCoverageArea?:boolean;
  variant: 'neighborhoodLaunchTooling' | 'providerCoverageAreaLaunchTooling';
}

export default function ServiceProviderLaunchCard({ serviceProviderId, index, disableServiceOfferingsWithoutProviderCoverageArea, variant }:IServiceProviderLaunchCardProps) {
  let populateLaunchCard:any;
  if (variant === 'neighborhoodLaunchTooling') {
    const store = useNeighborhoodLaunchTooling();
    populateLaunchCard = store.populateLaunchCard;
  } else if (variant === 'providerCoverageAreaLaunchTooling') {
    const store = useProviderCoverageAreaLaunchToolingStore();
    populateLaunchCard = store.populateLaunchCard;
  }
  const [expanded, setExpanded] = useState(true);
  const formContext = useFormContext();
  const { getValues, setValue, control, setError, clearErrors, trigger, formState } = formContext;
  function handleChange() {
    setExpanded(!expanded);
  }
  useEffect(() => {
    void populateLaunchCard(getValues, setValue, index);
  }, [serviceProviderId]);
  const { fields } = useFieldArray({
    control,
    name: `serviceProviderLaunchDtos.${index}.serviceOfferingOptions`,
  });
  const { isError, getErrorMessage } = useErrorState(formContext);
  return (
    <Accordion expanded={expanded} onChange={handleChange} className={styles.serviceProviderLaunchCard}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{getValues(`serviceProviderLaunchDtos.${index}.serviceProviderName`)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {fields.map((x, j) => {
            const hasCoverageArea = getValues(`serviceProviderLaunchDtos.${index}.serviceOfferingOptions.${j}.hasCoverageArea`);
            const disabled = disableServiceOfferingsWithoutProviderCoverageArea && !hasCoverageArea;
            return (
              <Grid key={x.id} container item xs={12} justifyContent={'center'}>
                <Grid item xs={12}>
                  <HookCheckbox
                    name={`serviceProviderLaunchDtos.${index}.serviceOfferingOptions.${j}.checked`}
                    label={(x as any).displayValue}
                    extraProps={{
                      disabled: disabled,
                    }}
                    labelProps={{
                      sx: {
                        'fontSize': '14px',
                        'color': disabled ? '#a9a9a9' : null,
                        '&:hover': {
                          cursor: disabled ? 'not-allowed' : 'pointer',
                        },
                      },
                    }}
                  />
                </Grid>
                {!hasCoverageArea && (
                  <Grid item xs='auto' justifySelf={'center'}>
                    <Typography variant='caption' color='textSecondary'>
                      No coverage area assigned
                    </Typography>
                  </Grid>
                )}
              </Grid>
            );
          },
          )}
        </Grid>
        <FormHelperText sx={{ color: 'red' }}>{getErrorMessage(`serviceProviderLaunchDtos.${index}`)}</FormHelperText>
      </AccordionDetails>
    </Accordion>

  );
}