import { createUninitializedDropdownOption, IDropdownOption } from 'model/dropdown';
import { sortByString } from './sortFunctions';
import { createUnassignedTerritoryOption, ITerritory } from 'model/territories';
import { ILookUp } from 'model/lookup/lookup';

export interface IDropdownPropertyMapper<Type> {
  getKey: (x:Type) => string | null;
  getOptionText: (x:Type) => string | null;
  getOptionValue: (x:Type) => string | null;
  getAncillary?: (x:Type) => any;
}

export function convertToDropdown<Type>(
  data: Type[],
  filter: (x:Type) => boolean,
  mapper:IDropdownPropertyMapper<Type>,
  unselectedOption?: () => IDropdownOption,
):IDropdownOption[] {
  const {
    getKey,
    getOptionText,
    getOptionValue,
    getAncillary,
  } = mapper;
  let filtered = data
    .filter(filter)
    .map(x => {
      const key = getKey(x);
      const optionText = getOptionText(x);
      const optionValue = getOptionValue(x);
      if (key !== null && optionText !== null && optionValue !== null) {
        return {
          key,
          optionText,
          optionValue,
          ancillary: getAncillary ? getAncillary(x) : null,
        };
      } else {
        return unselectedOption ? unselectedOption() : createUninitializedDropdownOption();
      }
    }).sort(sortByString('optionText', false));
  return [unselectedOption ? unselectedOption() : createUninitializedDropdownOption(), ...filtered];
}

export interface ITerritoryDropdownOptionConversionParams {
  optionValueIsZipCodeTerritory:boolean;
  addUnassignedTerritory?:boolean;
}
export function convertTerritoriesToDropdownOptions(
  territories: ITerritory[],
  { optionValueIsZipCodeTerritory, addUnassignedTerritory }: ITerritoryDropdownOptionConversionParams,
): IDropdownOption[] {
  let options = territories.map(m => {
    return {
      key: m.id,
      optionValue: optionValueIsZipCodeTerritory ? m.zipCodeTerritory : m.id,
      optionText: `${m.name ?? ''}${m.name ? ' ' : ''}(${m.zipCodeTerritory})`,
      ancillary: m,
    } as IDropdownOption;
  });
  if (addUnassignedTerritory) {
    options.unshift(createUnassignedTerritoryOption());
  }
  return options;
}

export function convertLookupToDropdownOptions<T extends ILookUp>(
  lookUpOptions: T[],
): IDropdownOption[] {
  return lookUpOptions.map(x => {
    return {
      key: x.entityValue,
      optionValue: x.entityValue,
      optionText: x.entityValue,
      ancillary: x,
    } as IDropdownOption;
  });
}

export function convertSingleValueToDropdownOption(
  value: string,
): IDropdownOption {
  return {
    key: value,
    optionValue: value,
    optionText: value,
  };
}