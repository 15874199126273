import PrimaryOutlinedButton from 'components/buttons/PrimaryOutlinedButton';
import { IDiscountSchedule } from 'model/discountSchedule';
import { IServiceOffering } from 'model/serviceOffering';
import { IInternalServiceProvider } from 'model/serviceProvider';
import useProviderSetupStore from 'pages/providerSetup/useProviderSetupStore';
import { useNavigate } from 'react-router-dom';

export default function CreateAnotherProvider() {
  const {
    setCurrentDiscountSchedule,
    setCurrentServiceOffering,
    setServiceType,
    setProvider,
  } = useProviderSetupStore();

  const navigate = useNavigate();
  const onCreateAnotherProviderClick = async () => {
    try {
      setCurrentServiceOffering({} as IServiceOffering);
      setCurrentDiscountSchedule({} as IDiscountSchedule);
      setProvider({} as IInternalServiceProvider);
      setServiceType('');
      navigate('/providerOnboarding/create');
    } catch (error) {
      console.error('error attempting to create a new service offering: ', { error });
    }
  };
  return (
    <PrimaryOutlinedButton onClick={onCreateAnotherProviderClick}>Create Provider</PrimaryOutlinedButton>
  );
}