import { Grid, Typography } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useSeedFromTemplate from '../_hookStore/useSeedFromTemplate';

export const ReSeedProviderConfirmationModalKey = 'ReSeedProviderConfirmationModalKey';

export default function ReSeedNeighborhoodConfirmationModal() {
  const { selectedServiceProviderReseed, onConfirmProviderReSeeding } = useSeedFromTemplate();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Service Provider ReSeeding'}
      body='ReSeed Service Provider?'
      modalKey={ReSeedProviderConfirmationModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12} paddingTop={'30px'} paddingBottom={'25px'}>
            <Typography variant='body1'>ReSeed {selectedServiceProviderReseed?.optionText} from template?</Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        await onConfirmProviderReSeeding();
      }}
    />
  );
}