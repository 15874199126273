
export interface IRoleProps {
  hasDefaultAdminRole:boolean;
  hasUserAdminRole:boolean;
  hasSupplyManagerRole:boolean;
  hasConsumerGrowthOpsRole:boolean;
}

export function createLocationsMenu({
  hasDefaultAdminRole,
  hasUserAdminRole,
  hasSupplyManagerRole,
  hasConsumerGrowthOpsRole,
}: IRoleProps):any[] {

  const menuItems:any[]= [];


  if (hasDefaultAdminRole) {
    menuItems.push({ buttonText: 'Markets', route: '/markets' });
  }

  if (hasConsumerGrowthOpsRole || hasDefaultAdminRole) {
    menuItems.push(...[
      { buttonText: 'Neighborhoods', route: '/neighborhoods' },
      { buttonText: 'Neighborhood WKT Upload', route: '/neighborhoodUpload' },
    ]);
  }
  if (hasDefaultAdminRole) {
    menuItems.push(...[
      { buttonText: 'Territory WKT Upload', route: '/territoryUpload' },
      { buttonText: 'Territories', route: '/territories' },
    ]);
  }


  return menuItems;
}

export function createSupplyMenu({
  hasDefaultAdminRole,
  hasUserAdminRole,
  hasSupplyManagerRole,
  hasConsumerGrowthOpsRole,
}: IRoleProps):any[] {

  return [
    { buttonText: 'Providers', route: '/service-providers' },
    { buttonText: 'Service Offerings', route: '/service-offerings' },
    { buttonText: 'Discount Schedules', route: '/discount-schedules' },
    { buttonText: 'Coverage Areas', route: '/providerCoverageArea' },
    { buttonText: 'Neighborhood Service Offerings', route: '/neighborhood-service-offerings' },
  ];
}

export function createSupplyToolsMenu({
  hasDefaultAdminRole,
  hasUserAdminRole,
  hasSupplyManagerRole,
  hasConsumerGrowthOpsRole,
}: IRoleProps):any[] {
  const menuItems:any[]= [];
  if (hasSupplyManagerRole || hasDefaultAdminRole) {
    menuItems.push({ buttonText: 'Provider Onboarding', route: '/providerOnboarding' });
    menuItems.push({ buttonText: 'Provider Recommendations', route: '/providerRecommendations' });
  }
  if (hasDefaultAdminRole || hasConsumerGrowthOpsRole || hasSupplyManagerRole) {
    menuItems.push({ buttonText: 'Customer Uploads', route: '/customerUpload' });
  }
  if (hasDefaultAdminRole || hasConsumerGrowthOpsRole) {
    menuItems.push(...[
      { buttonText: 'Launch Tooling', route: '/neighborhoodLaunchTooling' },
      { buttonText: 'Territory Prep', route: '/territoryPrep' },
    ]);
  }
  if (hasDefaultAdminRole) {
    menuItems.push(...[
      { buttonText: 'TSOI Happening Soon Management', route: '/tsoiManagementBatches' },
    ]);
  }

  return menuItems;
}

export function createDemandMenu({
  hasDefaultAdminRole,
  hasUserAdminRole,
  hasSupplyManagerRole,
  hasConsumerGrowthOpsRole,
}: IRoleProps):any[] {
  const menuItems:any[]= [];
  if (hasDefaultAdminRole || hasConsumerGrowthOpsRole) {
    menuItems.push({ buttonText: 'Address Contacts Uploads', route: '/addressContactsUpload' });
    menuItems.push({ buttonText: 'Home Sale Upload', route: '/homeSaleUpload' });
    menuItems.push({ buttonText: 'Home Upload', route: '/homeUpload' });
    menuItems.push({ buttonText: 'Letter Batch Management', route: '/letterBatches' });
  }

  if (hasDefaultAdminRole) {
    menuItems.push(...[
      { buttonText: 'One Time Newsletter', route: '/oneTimeNewsletter' },
      { buttonText: 'Rebook Service Notifications', route: '/rebookServiceAdmin' },
      { buttonText: 'Reward Code Management', route: '/rewards/codeManagement' },
    ]);
  }

  return menuItems;
}


export function createAdminMenu({
  hasDefaultAdminRole,
  hasUserAdminRole,
  hasSupplyManagerRole,
}: IRoleProps):any[] {
  const menuItems:any[]= [];
  if (hasUserAdminRole) {
    menuItems.push(...[
      { buttonText: 'Users', route: '/users' },
    ]);
  }
  if (hasDefaultAdminRole) {
    menuItems.push(...[

      { buttonText: 'Service Types', route: '/serviceTypeInfoTabs' },
      { buttonText: 'Flush Cache', route: '/flush-cache' },
      { buttonText: 'Invoicing', route: '/providerInvoices' },
      { buttonText: 'Individuals', route: '/individuals' },
      { buttonText: 'Neighborhood Banner LD Config', route: '/launchDarkly/neighborhoodBannerLDConfig' },
      { buttonText: 'Short Links', route: '/shortlink/' },
      { buttonText: 'Invite Neighbors', route: '/inviteNeighbors' },
      { buttonText: 'MARC upload', route: '/marcUpload' },
    ]);
  }

  return menuItems;
}

export function createSeedingMenu({
  hasDefaultAdminRole,
  hasConsumerGrowthOpsRole,
}: IRoleProps):any[] {
  const menuItems:any[]= [];
  if (hasConsumerGrowthOpsRole || hasDefaultAdminRole) {
    menuItems.push({ buttonText: 'Territory Seeding Templates', route: '/territorySeedingTemplates' });
    menuItems.push({ buttonText: 'Add Provider To Templates', route: '/addProviderToTemplates' });
    menuItems.push({ buttonText: 'Remove Provider From Templates', route: '/removeProviderFromTemplates' });
  }
  if (hasConsumerGrowthOpsRole || hasDefaultAdminRole) {
    menuItems.push({ buttonText: 'Seed From Template', route: '/seedFromTemplate' });
  }
  if (hasDefaultAdminRole) {
    menuItems.push(...[
      { buttonText: 'Neighborhood Seeding Tooling', route: '/neighborhoodSeedingTooling' },
    ]);
  }

  return menuItems;
}