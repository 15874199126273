import { createValidateNumberNotRequired } from 'util/rhfValidateFunctions';
import { Grid } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';
import HookRating from 'components/reactHookForm/HookRating';
import FacebookIcon from 'images/logos/facebook_logo.png';

const validateNumber = createValidateNumberNotRequired();

export default function FacebookRatingInfo() {

  return (
    <Grid container item spacing={2} alignItems='top' xs={12}>
      <Grid container item xs='auto' alignItems='center'>
        <img src={FacebookIcon} width={24} height={24}/>
      </Grid>
      <Grid container item xs='auto' alignItems='center'>
        <HookRating
          name="facebookRating.rating"
          label='Rating'
          precision={0.1}
          size='large'
        />
      </Grid>
      <Grid item xs>
        <HookTextField
          label='Rating number entry'
          name='facebookRating.rating'
          validationRules={{
            min: { value: 0, message: 'rating must be above 0' },
            max: { value: 5, message: 'rating must be less than 5' },
            validate: validateNumber,
          }}
          inputProps={{ inputMode: 'numeric' }}
        />
      </Grid>
      <Grid item xs>
        <HookTextField
          label='Number of ratings'
          name='facebookRating.numberOfRatings'
          validationRules={{
            min: { value: 0, message: 'rating must be above 0' },
            validate: validateNumber,
          }}
          inputProps={{ inputMode: 'numeric' }}
        />
      </Grid>
    </Grid>
  );
}