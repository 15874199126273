import { Grid, Typography } from '@mui/material';
import { ProviderSelect } from '../providerInformation/ProviderSelect';
import { PrimaryButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';
import RecentProviders from './RecentProviders';

export default function ProviderSetupLanding() {
  const navigate = useNavigate();
  return (
    <Grid container item xs={12} sx={{ margin: '50px 20px 0 20px' }} alignContent={'center'} justifyContent={'center'}>
      <Grid container item xs={12} md={2} sx={{ margin: '20px' }} alignItems={'center'} justifyContent={'center'}>
        <PrimaryButton
          sx={{ 'width': '100%', '&&': { height: '50px' } }}
          onClick={() => {
            navigate('/providerOnboarding/create');
          }}
        >Create New Provider
        </PrimaryButton>
      </Grid>
      <Grid item xs={12} sx={{ height: '20px' }}></Grid>
      <Grid item xs={12} md={2}>
        <ProviderSelect />
      </Grid>
      <Grid item xs={12} sx={{ height: '20px' }}></Grid>
      <RecentProviders/>
    </Grid>
  );
}