import { Link, MenuItem, Tooltip, Typography } from '@mui/material';
import { IServiceOffering } from 'model/serviceOffering';


export interface IEditServiceOfferingLinkProps {
  serviceOffering: IServiceOffering;
}

export default function EditServiceOfferingLink({ serviceOffering } : IEditServiceOfferingLinkProps) {
  return (
    <Link href={`/providerOnboarding/${serviceOffering?.providerId}/service-offerings/${serviceOffering?.id}`} rel='noreferrer'>
      <Tooltip title={'Edit this service offering'} >
        <Typography>Edit</Typography>
      </Tooltip>
    </Link>
  );
}