import { CheckBox } from '@mui/icons-material';
import { Checkbox, Grid, Typography } from '@mui/material';
import { updateLaunchLeaderStatus } from 'api/individualApi';
import Loading, { useLoading } from 'components/Layout/Loading';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';
import useToast from 'components/toast/useToast';
import { LaunchLeaderStatusTypes } from 'model/individual';
import useIndividualsHookStore from 'pages/individuals/individualsHookStore';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const modalStoreKey = 'MarkAsCalledModal';
const modalLoadingKey = 'MarkAsCalledModalLoader';

export interface IMarkAsCalledModal extends IModal {
}

export function createInitialMarkAsCalledModal() :IMarkAsCalledModal {
  return {
    open: false,
  };
}

export function createEmptyMarkAsCalledModal() :IMarkAsCalledModal {
  return {
    open: false,
  };
}

export default function MarkAsCalledModal({ shouldOpen }) {
  // Internal State
  const { getModalState } = useModal(modalStoreKey);
  const state = getModalState() as IMarkAsCalledModal;
  state.open = shouldOpen; // This modal will be used opened via a "modal route", and won't be opened via a button click, so setting the open state here
  const [requesting, setRequesting] = useState(false);
  var [wasSuccessful, setWasSuccessful] = useState(false);
  var [requestComplete, setRequestComplete] = useState(false);
  var [isConfirmed, setIsConfirmed] = useState(false);

  // External State
  const { individualId } = useParams();
  const { init, individual, loadingKey: individualLoadingKey, updateIndividual } = useIndividualsHookStore();
  var isIndividualALaunchLeader = !!individual.id && !!individual.launchLeaderStatus;
  // Hooks
  const navigate = useNavigate();
  const { createErrorToast } = useToast();
  const { onLoading, doneLoading } = useLoading(modalLoadingKey);
  // Effects
  useEffect(() => {
    void init();
  }, [individualId]);

  useEffect(() => {
    if (!!individual?.launchLeaderAcceptedOfferDate) {
      setIsConfirmed(true);
    }
  }, [individual]);


  // Methods
  const onSubmit = async () => {
    try {
      setRequesting(true);
      onLoading();
      const payload = {
        status: isConfirmed ? LaunchLeaderStatusTypes.PAY_REWARDS : LaunchLeaderStatusTypes.CALL_NEIGHBORHOOD_LEAD,
        markAsCalled: true,
        isConfirmed: isConfirmed,
      };
      const resp = await updateLaunchLeaderStatus(individual.id.toString(), payload);
      await updateIndividual(resp.data);
      setWasSuccessful(true);
      setRequestComplete(true);
      setRequesting(false);
      doneLoading();
      return onCloseModal();
    } catch (error:any) {
      console.error(error);
      createErrorToast('Error updating Launch Leader: ' + error.response?.data?.message);
    }
    doneLoading();
    setRequestComplete(true);
    setRequesting(false);
  };

  const onCloseModal = () => {
    if (!!individualId) {
      navigate(`/individuals/${encodeURIComponent(individualId)}`);
    }
  };

  var message = 'Loading...';
  if (requesting) {
    message = 'Updating...';
  }
  // success message
  if (requestComplete && wasSuccessful) {
    message = `Successfully updated status to PAY_REWARDS for individual ${individualId}!`;
  }
  // error message
  if (requestComplete && !wasSuccessful) {
    message = `Failed to update status for individual ${individualId}`;
  }
  // not a launch leader message
  if (!isIndividualALaunchLeader) {
    message = `Individual ${individualId} is not a launch leader`;
  }

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Mark NL applicant as Called'}
      modalContent={
        (
          <>
            <Loading loadingKey={individualLoadingKey}>
              <Loading loadingKey={modalLoadingKey}>
                <Grid container justifyContent={'flex-start'}>
                  <Grid container item justifyContent={'flex-start'} flexDirection={'column'}>
                    <Grid container item xs={12} alignItems={'center'}>
                      <Checkbox
                        checked={isConfirmed}
                        onChange={(e) => {
                          setIsConfirmed(e.target.checked);
                        }}
                      />
                      <Typography variant='body1'>Applicant accepted NL offer</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ Loading>
            </Loading>
          </>
        )
      }
      onConfirm={onSubmit}
      primaryButtonText='Submit'
      primaryDisabled={requesting}
      hideSecondaryButton={false}
      secondaryButtonText='Cancel'
      onSecondaryAction={onCloseModal}
    />
  );
}