import useCoverageAreaAssignment from 'components/assignCoverageArea/useCoverageAreaAssignment';
import { useEffect, useState } from 'react';
import ServiceOfferingAssignment from './ServiceOfferingAssignment';
import { IServiceOffering } from 'model/serviceOffering';
import { Save } from '@mui/icons-material';
import { Tooltip, IconButton, CircularProgress, Grid } from '@mui/material';
import { useLoading } from 'components/Layout/Loading';
import PrimaryOutlinedButton from 'components/buttons/PrimaryOutlinedButton';
import useToast from 'components/toast/useToast';

export interface IServiceOfferingsProps {
  serviceProviderId?: string;
}

export default function ServiceOfferings({ serviceProviderId }: IServiceOfferingsProps) {
  const { getServiceOfferings, onAssignCoverageArea } = useCoverageAreaAssignment();
  const [serviceOfferings, setServiceOfferings] = useState([] as IServiceOffering[]);
  const [serviceOfferingToCoverageAreaMap, setServiceOfferingToCoverageAreaMap] = useState<Map<string, string>>(new Map());
  const { onLoading, doneLoading, getLoadingState } = useLoading(`${serviceProviderId}-coverage-area-page`);
  const { isLoading } = getLoadingState();
  const { createSuccessToast, createErrorToast } = useToast();
  useEffect(() => {
    if (serviceProviderId) {
      getServiceOfferings(serviceProviderId).then((serviceOfferingsData) => {
        setServiceOfferings(serviceOfferingsData);
      }).catch((error) => {
        console.error('could not get service offerings', error);
      });
    }
  }, [serviceProviderId]);
  if (!serviceProviderId) {
    return null;
  }
  return (
    <>
      <Grid container justifyContent={'flex-start'}>
        <Grid item xs='auto'>
          <PrimaryOutlinedButton
            onClick={async () => {
              onLoading();
              try {
                for (const [serviceOfferingId, providerCoverageAreaId] of serviceOfferingToCoverageAreaMap.entries()) {
                  if (providerCoverageAreaId === 'UNSELECTED') {
                    await onAssignCoverageArea(serviceOfferingId, null);
                  } else {
                    await onAssignCoverageArea(serviceOfferingId, providerCoverageAreaId);
                  }
                }
                createSuccessToast('Assignments saved');
              } catch (e) {
                console.error('could not save assignments', e);
                createErrorToast('Could not save assignments');
              }
              doneLoading(150);
            }}
          >
            Save Assignments
          </PrimaryOutlinedButton>
        </Grid>
      </Grid>
      {
        serviceOfferings.map((serviceOffering:IServiceOffering) => {
          return (
            <ServiceOfferingAssignment
              key={serviceOffering.id}
              serviceOffering={serviceOffering}
              onChange={(coverageAreaId: string) => {
                setServiceOfferingToCoverageAreaMap(prevMap => new Map(prevMap).set(serviceOffering.id!, coverageAreaId));
              }}
            />
          );
        })
      }
    </>
  );
}