import Axios, { AxiosResponse } from 'axios';
import { IInternalNeighborhood, INeighborhood } from 'model/neighborhood';
import { IHomeSaleUploadForm, INeighborhoodUploadForm } from 'model/neighborhoodUpload';
import { ISearchPage, IStreetFairSearchRequest } from 'model/search';


const apiUrl = window.REACT_APP_BASE_API_URI;
export function searchNeighborhoods(request:IStreetFairSearchRequest):Promise<AxiosResponse<ISearchPage<INeighborhood>>> {
  return Axios.post(`${apiUrl}/neighborhood/v2/searchNeighborhoods`, request);
}

export function findAllNeighborhoods() {
  return Axios.get(`${apiUrl}/neighborhood/v2/findAll`);
}

export function getNeighborhoodById(id:string) {
  return Axios.get(`${apiUrl}/neighborhood/v2/findInternalNeighborhood/${id}`);
}

export function saveNeighborhood(data:IInternalNeighborhood) {

  if (data.id) {
    return Axios.put(`${apiUrl}/neighborhood/v2/update/${data.id}`, data);
  }
  return Axios.post(`${apiUrl}/neighborhood/v2/create`, data);
}

export function saveNeighborhoodImage(data:FormData) {
  return Axios.post(`${apiUrl}/neighborhood/v2/saveNeighborhoodImage`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function sendNeighborhoodCommunication(neighborhoodId: string, communicationTemplate: string, shouldSendToLoggedInUser:boolean, overrideEmail?:string, neighborhoodServiceOfferingInstanceId?: string) {
  let hoursOffset = new Date().getTimezoneOffset() / 60;
  var nsoiIDSuffix = neighborhoodServiceOfferingInstanceId ? neighborhoodServiceOfferingInstanceId : '';
  return Axios.post(`${apiUrl}/communications/v2/neighborhood/${neighborhoodId}/template/${communicationTemplate}/${nsoiIDSuffix}`, { overrideEmail, shouldSendToLoggedInUser, hoursOffset });
}

export function sendNeighborhoodServiceOfferingInstanceCommunication(neighborhoodServiceOfferingInstanceId: string, communicationTemplate: string, shouldSendToLoggedInUser:boolean, overrideEmail?:string) {
  let hoursOffset = new Date().getTimezoneOffset() / 60;
  return Axios.post(`${apiUrl}/communications/v2/neighborhoodServiceOfferingInstance/${neighborhoodServiceOfferingInstanceId}/template/${communicationTemplate}`, { overrideEmail, shouldSendToLoggedInUser, hoursOffset });
}

export function deleteNeighborhood(id:string) {
  return Axios.delete(`${apiUrl}/neighborhood/v2/delete/${id}`);
}

export function uploadNeighborhoodWKT(data:INeighborhoodUploadForm) {
  var formData = new FormData();
  formData.append('neighborhoodTemplate', data.neighborhoodTemplate);
  return Axios.post(`${apiUrl}/neighborhood/v2/uploadNeighborhoodWKT`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function oldUploadHomeSales(data:IHomeSaleUploadForm) {
  var formData = new FormData();
  formData.append('homeSaleTemplate', data.homeSaleTemplate);
  return Axios.post(`${apiUrl}/neighborhood/v2/uploadHomeSales`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

}

export function uploadHomeSales(data:IHomeSaleUploadForm) {
  var formData = new FormData();
  formData.append('homeSaleTemplate', data.homeSaleTemplate);
  return Axios.post(`${apiUrl}/neighborhood/v3/uploadHomeSales?marketId=${data.marketId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

}

export function initiateSkipTraceForNeighborhood({ nid }) {
  return Axios.get(`${apiUrl}/neighborhood/v2/skipTraceNeighborhood/${nid}`);
}