import { Grid, Typography } from '@mui/material';
import { AddButton, RemoveButton } from 'components/buttons';
import Spacer from 'components/Layout/Spacer';
import { HookTextField } from 'components/reactHookForm';
import HookDatePicker from 'components/reactHookForm/HookDatePicker';
import PageSubsectionHeader from 'components/SectionHeaders/PageSubsectionHeader';
import { useEffect } from 'react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';

export default function NeighborhoodFacebookGroups() {
  const { control, getValues } = useFormContext();

  const { fields: facebookGroups = [], append, replace, remove } = useFieldArray({
    name: 'facebookGroups',

  });
  const watchFacebookGroups = useWatch({ control, name: 'facebookGroups', defaultValue: [] });
  useEffect(() => {
    if (facebookGroups.length !== watchFacebookGroups.length) {
      replace(watchFacebookGroups);
    }
  }, [watchFacebookGroups]);

  return (
    <Grid container item spacing={1} alignItems='center'>
      <Grid container item xs={12} justifyContent={'flex-start'}>
        <Grid item xs={12}>
          <PageSubsectionHeader text='Neighborhood Facebook Groups'/>
        </Grid>
        <Grid xs={12} paddingTop={2} paddingBottom={2}>
          <HookDatePicker
            name='facebookPostDate'
            label='Facebook Post Date'
          />
        </Grid>
        <Grid container alignItems='center' item xs='auto'>
          <Typography>Add Group</Typography>
          <Grid item xs='auto'>
            <AddButton
              tooltipTitle="Add a Facebook Group"
              onClick={() => append({ groupId: '', groupName: '' })}
            />
          </Grid>
          <Spacer/>
        </Grid>
      </Grid>
      <Grid container item xs={12} gap={3} spacing={1}>
        {facebookGroups!! && facebookGroups.map((item, index) => {
          return (
            <Grid container item
              alignItems='center'
              key={item.id}
              border={'solid'}
              borderRadius={5}
              borderColor={'primary.main'}
              xs={12}
              md={5}
            >
              <Grid container item xs={12} justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                  <Typography variant='subtitle2' fontWeight={'bold'} >Group {index + 1}</Typography>
                </Grid>
                <Grid item xs='auto' alignSelf='center' >
                  <RemoveButton
                    tooltipTitle="Delete this group"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1} xs={12}>
                <Grid item alignItems='center'>
                  <HookTextField
                    name={`facebookGroups.${index}.groupId`}
                    label={'Group ID'}
                    required
                  />
                </Grid>
                <Grid item xs={7} alignItems='center'>
                  <HookTextField
                    name={`facebookGroups.${index}.groupName`}
                    label={'Group Name'}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>

    </Grid>
  );
}