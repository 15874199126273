import { Box, Grid } from '@mui/material';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';

import TermsAndConditions from './components/TermsAndConditions';
import SupplyQualitativeScore from './components/SupplyQualitativeScore';
import StreetFairRepId from './components/StreetFairRepId';
import ServiceProviderCrmCreatableAutocomplete from 'components/serviceProviderCrmCreatableAutocomplete/ServiceProviderCrmCreatableAutocomplete';
import StatusTypeSelect from './components/StatusTypeSelect';
import ContactInfo from './components/ContactInfo';
import CompanyImageDropzone from './components/providerImage/CompanyImageDropzone';
import CompanyImagePreview from './components/providerImage/CompanyImagePreview';
import SectionWrapper from './components/styling/SectionWrapper';

export default function PrimaryInfo() {
  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>

        <SectionWrapper xs={12} md >
          <Grid item xs={12} md={6}>
            <HookTextField
              label='Provider name'
              name='name'
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StatusTypeSelect/>
          </Grid>
          <Grid item xs={12} md={6}>
            <HookTextField
              label='Google Place Id'
              name='googlePlaceId'
            />
          </Grid>
          <Grid item xs={12} md>
            <ServiceProviderCrmCreatableAutocomplete />
          </Grid>
        </SectionWrapper>
        <SectionWrapper xs={12} md>
          <Grid item xs={12} md={3}>
            <SupplyQualitativeScore/>
          </Grid>
          <Grid item xs={12} md={4}>
            <StreetFairRepId/>
          </Grid>
          <Grid item xs={12} md='auto'>
            <HookCheckbox
              name='hipFireProvider'
              label='Hip Fire Provider?'
            />
          </Grid>
          <CompanyImageDropzone/>
          <CompanyImagePreview/>
        </SectionWrapper>
        <Grid item xs={12} sx={{ height: '24px' }}></Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} >
            <SectionWrapper xs={12} md={4}>
              <TermsAndConditions/>
            </SectionWrapper>
            <SectionWrapper xs={12} md={8}>
              <ContactInfo/>
            </SectionWrapper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}