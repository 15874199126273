import { Save } from '@mui/icons-material';
import { Grid, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton, CircularProgress, Typography } from '@mui/material';
import useCoverageAreaAssignment from 'components/assignCoverageArea/useCoverageAreaAssignment';
import { useLoading } from 'components/Layout/Loading';
import { IServiceOffering } from 'model/serviceOffering';
import { useState } from 'react';

export interface IServiceOfferingAssignmentProps {
  serviceOffering:IServiceOffering;
  onChange: (coverageAreaId: string) => void;
}

export default function ServiceOfferingAssignment({ serviceOffering, onChange }:IServiceOfferingAssignmentProps) {
  const {
    providerCoverageAreaOptions,
    onAssignCoverageArea,
  } = useCoverageAreaAssignment();

  const [providerCoverageAreaId, setProviderCoverageAreaId] = useState<string | null>(serviceOffering.providerCoverageAreaId ?? 'UNSELECTED');
  return (
    <Grid container alignItems={'end'} justifyContent={'start'}>
      <Grid item xs='auto' sx={{ padding: '0 20px 0 0', minWidth: '300px' }}>
        <Typography variant='h6'>{serviceOffering.name}</Typography>
      </Grid>
      <Grid item xs='auto' sx={{ minWidth: '200px' }}>
        <FormControl variant='standard' fullWidth>
          <InputLabel htmlFor="provider-coverage-area">Coverage Area</InputLabel>

          <Select
            id={`provider-coverage-area-service-offering-${serviceOffering.id}`}
            name={`providerCoverageAreaAssignment-${serviceOffering.id}`}
            autoWidth
            label="Coverage Area"
            onChange={(e) => {
              setProviderCoverageAreaId(e.target.value);
              if (e.target.value) {
                if (e.target.value === 'UNSELECTED') {
                  onChange('UNSELECTED');
                } else {
                  onChange(e.target.value!);
                }
              }
            }}
            value={providerCoverageAreaId}
            sx ={{ width: '100%' }}
          >
            {
              providerCoverageAreaOptions.map((x: any, i) => {
                return <MenuItem key={x.key} value={x.optionValue}>{x.optionText}</MenuItem>;
              })
            }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}