import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useState, useEffect } from 'react';
import { DiscountScheduleStandardOptions, IDiscountSchedule, IDiscountScheduleSelectOption } from 'model/discountSchedule';
import { IPreviewFile } from 'model/imageUpload';
import { IServiceOffering, IServiceOfferingImageRef } from 'model/serviceOffering';
import { IInternalServiceProvider } from 'model/serviceProvider';
import { useParams } from 'react-router-dom';
import { findInternalServiceProviderById } from 'api/serviceProviderApi';
import { findServiceOfferingById, findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { findDiscountScheduleById, findDiscountSchedulesByProvider } from 'api/discountScheduleApi';
import { sortByString } from 'util/sortFunctions';
import { IMarket, MarketUtils } from 'model/markets';
import { findMarkets } from 'api/marketsApi';
import { IDropdownOption } from 'model/dropdown';
import { getAllServiceProviderPhoneNumbersList } from 'util/serviceProviderUtil';
import { ICheckNumbersAgainstDNCListRequest } from 'model/phoneNumberValidation';
import { checkNumbersAgainstDNCList } from 'api/phoneNumberValidatorApi';
import useSelf from 'hooks/useSelf';
import { ROLE_ADMIN_SUPPLY_MANAGER } from 'model/roles';
import { findNeighborhoodServiceOfferingsByFilter } from 'api/neighborhoodServiceOfferingApi';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { NSOCreationUtil } from 'util/data/nsoCreationUtil';
import { AxiosResponse } from 'axios';
import { convertSingleValueToDropdownOption } from 'util/dropdownUtil';

const loadingKey = 'ProviderOnboardingStore';

const defaultPriceLabelOptions = [
  { key: 'Starting at', optionValue: 'Starting at', optionText: 'Starting at' },
  { key: 'Average Price', optionValue: 'Average Price', optionText: 'Average Price' },
  { key: 'Price', optionValue: 'Price', optionText: 'Price' },
] ;
type ServiceOfferingCloneOption = {
  key: string;
  optionText: string;
  optionValue: string;
  ancillary: IServiceOffering;
}

type ServiceOfferingSummaryState = {
  nsos: INeighborhoodServiceOffering[];
  discountSchedules: IDiscountSchedule[];
  serviceOfferings: IServiceOffering[];
}

type ProviderOnboardingStore = {
  cadenceType: string;
  currentServiceOffering: IServiceOffering;
  currentDiscountSchedule: IDiscountSchedule;
  discountSchedules: IDiscountSchedule[];
  discountScheduleOptions: IDiscountScheduleSelectOption[];
  files: IPreviewFile[];
  isImageUploading: boolean;
  markets: IDropdownOption[];
  onImageUploading: Function;
  provider: IInternalServiceProvider;
  removeFile: Function;
  serviceType: string;
  serviceOfferingCloneProvider: IInternalServiceProvider;
  serviceOfferingCloneOptions: ServiceOfferingCloneOption[];
  setCadenceType: Function;
  setCurrentDiscountSchedule: Function;
  setCurrentServiceOffering: Function;
  setDiscountSchedules: Function;
  setProvider: Function;
  setProviderId: Function;
  setServiceType: Function;
  updateFiles: Function;
  pendingDeleteImages: IServiceOfferingImageRef[];
  hasSupplyManagerRole: boolean;
  serviceOfferingSummaryState:ServiceOfferingSummaryState;
  priceLabelOptions: IDropdownOption[];
}

const { get, update, registerListener, unregisterListener } = createStore<ProviderOnboardingStore>('ProviderOnboardingStore', {
  cadenceType: '',
  currentServiceOffering: {} as IServiceOffering,
  currentDiscountSchedule: {} as IDiscountSchedule,
  discountSchedules: [] as IDiscountSchedule[],
  discountScheduleOptions: [],
  files: [] as IPreviewFile[],
  isImageUploading: false,
  markets: [] as IDropdownOption[],
  onImageUploading: () => () => {},
  provider: {} as IInternalServiceProvider,
  removeFile: files => files,
  serviceType: '',
  serviceOfferingCloneProvider: {} as IInternalServiceProvider,
  serviceOfferingCloneOptions: [] as ServiceOfferingCloneOption[],
  setCadenceType: (cadenceType) => cadenceType,
  setCurrentDiscountSchedule: (discountSchedule) => discountSchedule,
  setCurrentServiceOffering: (serviceOffering) => serviceOffering,
  setDiscountSchedules: (dss) => dss,
  setProvider: (p) => p,
  setProviderId: (id) => id,
  setServiceType: (serviceType) => serviceType,
  updateFiles: files => files,
  pendingDeleteImages: [],
  hasSupplyManagerRole: false,
  serviceOfferingSummaryState: {
    nsos: [],
    discountSchedules: [],
    serviceOfferings: [],
  },
  priceLabelOptions: [],
});

export default function useProviderSetupStore() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createInfoToast } = useToast();
  const { providerId, serviceOfferingId, discountScheduleId } = useParams();
  const { roles } = useSelf();


  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(market:IMarket | null) {
    onLoading();
    try {
      if (market == null) {
        console.log('waiting to load market');
        //we should wait for the market to load before making any requests
        return;
      }
      const hasSupplyManagerRole = roles.indexOf(ROLE_ADMIN_SUPPLY_MANAGER) > -1;
      if (providerId) {
        const providerResp = await findInternalServiceProviderById(providerId);
        if (!providerResp.data.marketId) {
          providerResp.data.marketId = market.id;
        }
        const marketsResp = await findMarkets();
        const markets = MarketUtils.convertToDropdownOptions(marketsResp.data);
        update({
          ...get(),
          hasSupplyManagerRole: hasSupplyManagerRole,
          markets: markets,
          provider: providerResp?.data,
        });
      } else {
        const marketsResp = await findMarkets();
        const markets = MarketUtils.convertToDropdownOptions(marketsResp.data);

        update({
          ...get(),
          hasSupplyManagerRole: hasSupplyManagerRole,
          currentServiceOffering: {} as IServiceOffering,
          currentDiscountSchedule: {} as IDiscountSchedule,
          markets: markets,
          provider: {} as IInternalServiceProvider,
        });
      }

      if (serviceOfferingId) {
        const serviceOfferingResp = await findServiceOfferingById(serviceOfferingId);
        update({
          ...get(),
          currentServiceOffering: serviceOfferingResp?.data,
        });
      } else {
        update({
          ...get(),
          currentServiceOffering: {} as IServiceOffering,
        });
      }

      if (discountScheduleId) {
        const discountScheduleResp = await findDiscountScheduleById(discountScheduleId);
        var currentDiscountSchedule = discountScheduleResp?.data;
        var convertedOption: IDiscountScheduleSelectOption = {
          id: DiscountScheduleStandardOptions.Existing,
          name: `${currentDiscountSchedule.name} (assigned)`,
          discountType: currentDiscountSchedule.type,
          ancillary: currentDiscountSchedule,
          options: {
            ...currentDiscountSchedule.discountSchedule,
            ...currentDiscountSchedule.pricingSchedule,
            ...currentDiscountSchedule.flatRateSchedule,
          },
        };
        const discountScheduleOptions: IDiscountScheduleSelectOption[]= [
          ...DiscountScheduleStandardOptions.list,
          convertedOption,
        ];
        let priceLabelOptions: IDropdownOption[] = [
          ...defaultPriceLabelOptions,
        ];
        let currentPriceLabelOption:IDropdownOption = convertSingleValueToDropdownOption(currentDiscountSchedule.priceLabel);
        if (!priceLabelOptions.find(x => x.key === currentPriceLabelOption.key)) {
          priceLabelOptions.push(currentPriceLabelOption);
        }
        update({
          ...get(),
          currentDiscountSchedule,
          discountScheduleOptions,
          priceLabelOptions,
        });
      } else {
        const discountScheduleOptions: IDiscountScheduleSelectOption[] = [
          ...DiscountScheduleStandardOptions.list,
        ];
        let priceLabelOptions: IDropdownOption[] = [
          ...defaultPriceLabelOptions,
        ];
        update({
          ...get(),
          currentDiscountSchedule: {} as IDiscountSchedule,
          discountScheduleOptions,
          priceLabelOptions,
        });
      }
    } catch (e:any) {
      console.error('error initializing providerSetupStore', e);
    }
    doneLoading(300);
  }

  async function initServiceOfferingSummary() {
    const nsosPromise = findNeighborhoodServiceOfferingsByFilter({ selectedServiceProviderId: providerId, selectedNeighborhoodId: window.REACT_APP_NEIGHBORVILLE_UUID });
    const serviceOfferingsPromise = findServiceOfferingsByProvider(providerId!);
    const discountSchedulesPromise = findDiscountSchedulesByProvider(providerId!);
    const [nsosResp, serviceOfferingsResp, discountSchedulesResp] = await Promise.all([
      nsosPromise,
      serviceOfferingsPromise,
      discountSchedulesPromise,
    ]);
    let nsos = nsosResp.data;
    if (nsosResp.data.length !== serviceOfferingsResp.data.length && serviceOfferingsResp.data.length > 0) {
      var promises: Promise<AxiosResponse<any, any> | undefined>[] = [];
      const nsoMap = new Map<string, INeighborhoodServiceOffering>();
      nsosResp.data.forEach((nso: INeighborhoodServiceOffering) => {
        if (nso.serviceOfferingId) {
          nsoMap.set(nso.serviceOfferingId, nso);
        }
      });
      for (const serviceOffering of serviceOfferingsResp.data) {
        if (nsoMap.has(serviceOffering.id!)) {
          continue;
        }
        var promise = NSOCreationUtil.generateNeighborvilleNso({
          serviceProviderId: providerId!,
          serviceOfferingId: serviceOffering.id!,
          serviceType: serviceOffering.serviceType,
          discountScheduleId: serviceOffering.defaultDiscountScheduleId!,
        });
        promises.push(promise);
      }
      await Promise.all(promises);
      const generatedNsosRes = await findNeighborhoodServiceOfferingsByFilter({ selectedServiceProviderId: providerId, selectedNeighborhoodId: window.REACT_APP_NEIGHBORVILLE_UUID });
      nsos = generatedNsosRes.data;
      createInfoToast('Neighborhood service offerings have been created!');
    }

    update({
      ...get(),
      serviceOfferingSummaryState: {
        nsos: nsos,
        serviceOfferings: serviceOfferingsResp.data,
        discountSchedules: discountSchedulesResp.data,
      },
    });
  }

  function onImageUploading() {
    update({
      ...get(),
      isImageUploading: true,
    });
    return () => update({
      ...get(),
      isImageUploading: false,
    });
  }

  function updateFiles(nextFiles:IPreviewFile[]) {
    const { files } = get();
    const newFiles = [...files, ...nextFiles];
    update({
      ...get(),
      files: newFiles,
    });
  }

  function removeFile(file:IPreviewFile) {
    const { files } = get();
    const newFiles = files.filter(x => x !== file);
    update({
      ...get(),
      files: newFiles,
    });
  }

  function setProvider(prov:IInternalServiceProvider) {
    update({
      ...get(),
      provider: prov,
    });
  }

  function setServiceType(serviceType:string) {
    update({
      ...get(),
      serviceType,
    });
  }

  function setDiscountSchedules(discSchedules:IDiscountSchedule[]) {
    update({
      ...get(),
      discountSchedules: discSchedules,
    });
  }

  function setCadenceType(cadenceType:string) {
    update({
      ...get(),
      cadenceType,
    });
  }

  function setCurrentServiceOffering(currentServiceOffering:IServiceOffering) {
    update({
      ...get(),
      currentServiceOffering,
    });
  }

  function setCurrentDiscountSchedule(currentDiscountSchedule:IDiscountSchedule) {
    update({
      ...get(),
      currentDiscountSchedule,
    });
  }

  function setServiceOfferingCloneProvider(provider:IInternalServiceProvider) {
    update({
      ...get(),
      serviceOfferingCloneProvider: provider,
    });
  }

  function setServiceOfferingCloneOptions(serviceOfferingCloneOptions:IServiceOffering[]) {
    const formatted = serviceOfferingCloneOptions
      .map((x, i) => ({
        key: `${x.id}`,
        optionText: `${x.name}`,
        optionValue: `${x.id}`,
        ancillary: x,
      }))
      .sort(sortByString('optionText'));

    return update({
      ...get(),
      serviceOfferingCloneOptions: formatted,
    });
  }


  async function maybeCheckNumbersAgainstDNCList(data:any) {
    var phoneNumbersArray = getAllServiceProviderPhoneNumbersList(data);

    if (phoneNumbersArray.length > 0) {
      var request:ICheckNumbersAgainstDNCListRequest = {
        phoneNumbers: phoneNumbersArray,
      };
      var resp = await checkNumbersAgainstDNCList(request);

      return resp.data.doNotCallNumbers;
    }
  }


  /**
   * Mark an image ref for delete.
   * @param data
   * @param formCtx
   */
  function markForDelete(data:IServiceOfferingImageRef, formCtx: any) {
    const { pendingDeleteImages } = get();
    const imageRefs = formCtx.getValues('imageRefs');
    const after = imageRefs.filter(x => x.imageFileName !== data.imageFileName);
    formCtx.setValue('imageRefs', after);
    pendingDeleteImages.push({ ...data, markedForDeletion: true });
    update({ ...get(), pendingDeleteImages });
  }

  /**
   * This will only work before submission. The image ref will be removed from the list.
   * @param data
   * @param formCtx
   */
  function unmarkForDelete(data:IServiceOfferingImageRef, formCtx: any) {
    const { pendingDeleteImages } = get();
    const imageRefs = formCtx.getValues('imageRefs');
    const after = pendingDeleteImages.filter(x => x.imageFileName !== data.imageFileName);
    formCtx.setValue('imageRefs', [...imageRefs, { ...data, markedForDeletion: false }]);
    update({
      ...get(),
      pendingDeleteImages: after,
    });
  }

  function buildClaimBusinessUri(nsoId: string): string {
    const { provider } = get();
    const baseUri = `${window.REACT_APP_PROVIDER_STREETFAIR_COM}/why-streetfair`;
    const params = new URLSearchParams({
      nso: nsoId,
    });
    if (provider.marketId) {
      params.append('market', provider.marketId);
    }
    if (provider.approvedBy) {
      params.append('specialist', provider.approvedBy);
    }

    const result = `${baseUri}?${params.toString()}`;
    return result;
  }

  function resetPendingDeleteImages() {
    update({
      ...get(),
      pendingDeleteImages: [],
    });
  }


  return {
    ...get(),
    loadingKey,
    init,
    onImageUploading,
    setProvider,
    setServiceOfferingCloneOptions,
    setServiceOfferingCloneProvider,
    setServiceType,
    removeFile,
    updateFiles,
    setCadenceType,
    setCurrentDiscountSchedule,
    setCurrentServiceOffering,
    setDiscountSchedules,
    maybeCheckNumbersAgainstDNCList,
    markForDelete,
    unmarkForDelete,
    resetPendingDeleteImages,
    buildClaimBusinessUri,
    initServiceOfferingSummary,
  };
}