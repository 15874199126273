import GenericModal from 'components/modal/GenericModal';
import useModal from 'components/modal/useModal';
import useToast from 'components/toast/useToast';
import useServiceProviderCrmCreatableAutocomplete from './useServiceProviderCrmCreatableAutocomplete';
import { Grid, Typography } from '@mui/material';
import { useLoading } from 'components/Layout/Loading';
import { createProviderCrmOption } from 'api/providerCrmOptionLookUpApi';
import { CreateProviderCrmOptionRequest } from 'model/providerCrmOptionLookUp';

export const modalStoreKey = 'SendHappeningSoonInvitesModal';

export interface ICreateCrmOptionConfirmationModal {
  newValue: string;
}
export function createEmptyCreateCrmOptionConfirmationModal() :ICreateCrmOptionConfirmationModal {
  return {
    newValue: '',
  };
}

export default function CreateCrmOptionConfirmationModal({ newValue }) {
  const { closeModal } = useModal(modalStoreKey);
  const { createSuccessToast, createErrorToast } = useToast();
  const { loadingKey } = useServiceProviderCrmCreatableAutocomplete();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const onSubmit = async (data:any) => {
    onLoading();
    try {
      const request:CreateProviderCrmOptionRequest = { value: newValue };
      await createProviderCrmOption(request);
      createSuccessToast(`Successfully added ${newValue} as a CRM option!`);
    } catch (error) {
      console.error(error);
      createErrorToast('Error creating CRM option :(');
    }
    doneLoading(300);
    closeModal(createEmptyCreateCrmOptionConfirmationModal());
  };
  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Add new CRM option?'}
      modalContent={
        (
          <Grid container item justifyContent='center' rowSpacing={2}>
            <Grid item xs={10}>
              <Typography variant='body1'>Would you like to add "{newValue}" as a CRM option?</Typography>
            </Grid>
          </Grid>
        )
      }
      onConfirm={onSubmit}
      onSecondaryAction={() => closeModal(createEmptyCreateCrmOptionConfirmationModal())}
      primaryButtonText='Confirm'
      secondaryButtonText='Cancel'
      overrideSx={{
        '& .MuiPaper-root': {
          width: '500px',
        },
      }}
    />
  );
}