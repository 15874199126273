import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { ILetterBatchFormDto, createEmptyLetterBatchFormDto } from 'model/letter_batch/letterBatch';
import { findLetterBatchFormById, findLetterBatchNeighborhoodFormById, saveLetterBatchNeighborhood } from 'api/letterBatchesApi';
import { useNavigate, useParams } from 'react-router-dom';
import { IMarket, MarketUtils } from 'model/markets';
import { IDropdownOption } from 'model/dropdown';
import { IPreviewFile } from 'model/imageUpload';
import useToast from 'components/toast/useToast';
import { ILetterBatchNeighborhoodFormDto, createEmptyLetterBatchNeighborhoodFormDto } from 'model/letter_batch/letterBatchNeighborhood';

const loadingKey = 'LetterBatchNeighborhoodDetailStore';
type LetterBatchNeighborhoodDetailStore = {
  letterBatch: ILetterBatchFormDto;
  formData: ILetterBatchNeighborhoodFormDto;
  markets: IDropdownOption[];
  file:IPreviewFile | null;
  altFile:IPreviewFile | null;
}

const { get, update, registerListener, unregisterListener } =
createStore<LetterBatchNeighborhoodDetailStore>('LetterBatchNeighborhoodDetailStore', {
  letterBatch: createEmptyLetterBatchFormDto(),
  formData: createEmptyLetterBatchNeighborhoodFormDto(),
  markets: [],
  file: null,
  altFile: null,
});


export default function useLetterBatchNeighborhoodDetail() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const navigate = useNavigate();
  const { createSuccessToast, createErrorToast } = useToast();
  const { letterBatchId, letterBatchNeighborhoodId } = useParams();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        letterBatch: createEmptyLetterBatchFormDto(),
        formData: createEmptyLetterBatchNeighborhoodFormDto(),
        file: null,
        altFile: null,
      });
      unregisterListener(setState);
    };
  }, []);


  async function init(isNew:boolean, markets: IMarket[]) {
    onLoading();
    try {

      const letterBatchRes = await findLetterBatchFormById(letterBatchId!);

      let nextLetterBatch: ILetterBatchFormDto = createEmptyLetterBatchFormDto();
      if (letterBatchRes.data) {
        nextLetterBatch = letterBatchRes.data;
      } else {
        throw new Error('Letter Batch not found');
      }

      if (isNew) {

        update({
          ...get(),
          formData: createEmptyLetterBatchNeighborhoodFormDto(),
          markets: MarketUtils.convertToDropdownOptions(markets),
          letterBatch: nextLetterBatch,
        });
        doneLoading(300);
        return;
      } else {
        const res = await findLetterBatchNeighborhoodFormById(letterBatchId!, letterBatchNeighborhoodId!);
        const data = res.data;
        if (res.data) {
          update({
            ...get(),
            formData: data,
            letterBatch: nextLetterBatch,
          });
        }
      }


    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }


  async function onSave(data:ILetterBatchNeighborhoodFormDto) {
    onLoading();
    const { file, altFile } = get();
    var formData = new FormData();
    const formDto = convertDataToSave(data);
    formData.append('dto', new Blob([JSON.stringify(formDto)], {
      type: 'application/json',
    }));
    if (file != null) {
      formData.append('letterTemplate', file);
    }
    if (altFile != null) {
      formData.append('letterTemplateAlt', altFile);
    }
    let savedLetterBatchNeighborhoodId:string | null = null;
    let hasError = false;
    try {
      let res = await saveLetterBatchNeighborhood(letterBatchId!, letterBatchNeighborhoodId ?? null, formData);
      if (res.status === 200) {
        if (res.data.valid) {
          createSuccessToast('Saved letter batch - neighborhood details');
          savedLetterBatchNeighborhoodId = res.data.letterBatchNeighborhoodId;
          //need to clear the file so we don't accidentally upload it again
          update({
            ...get(),
            file: null,
          });
        } else {
          hasError = true;
          createErrorToast(`An error occurred: ${res.data.message}`);
        }
      }
    } catch (e:any) {
      hasError = true;
      if (e.response?.data?.message) {
        createErrorToast(`Save failed: ${e.response.data.message}`, null, { persistent: true });
      } else {
        createErrorToast('Save failed', null, { persistent: true });
      }
    }
    doneLoading(300);
    if (letterBatchNeighborhoodId && !hasError) {
      navigate(`/letterBatches/${letterBatchId}`);
    } else if (savedLetterBatchNeighborhoodId != null) {
      navigate(`/letterBatches/${letterBatchId}/letterBatchNeighborhoods/${savedLetterBatchNeighborhoodId}`);
    }
  }

  function convertDataToSave(data:any):ILetterBatchNeighborhoodFormDto {
    //TODO: we should find out why the value of dropdowns are being stored as objects and not just the ids
    let nextData = {
      ...data,
      letterBatchId: letterBatchId!,
      campaignTag: data.campaignTag?.optionValue ? data.campaignTag.optionValue : data.campaignTag,
      campaignTags: data.campaignTagIds,
      neighborhoodId: data.neighborhoodId?.optionValue ? data.neighborhoodId.optionValue : data.neighborhoodId,
      territoryId: data.territoryId?.optionValue ? data.territoryId.optionValue : data.territoryId,
    };
    //there are two autocompletes. one for the core campaign and one for the quick add. Need to make sure
    // that the neighborhoodId is not duplicated in the additionalNeighborhoodIds
    var dupeNeighborhoodIndex = data.additionalNeighborhoodIds.indexOf(data.neighborhoodId.optionValue);
    if (dupeNeighborhoodIndex > -1) {
      data.additionalNeighborhoodIds = data.additionalNeighborhoodIds.splice(dupeNeighborhoodIndex, 1);
    }
    if (data.variablePrintTemplateId != null && data.variablePrintTemplateId.optionValue != null) {
      nextData.variablePrintTemplateId = data.variablePrintTemplateId.optionValue;
    }
    if (data.variablePrintTemplateIdAlt != null && data.variablePrintTemplateIdAlt.optionValue != null) {
      nextData.variablePrintTemplateIdAlt = data.variablePrintTemplateIdAlt.optionValue;
    }
    return nextData;
  };

  function updateFile(nextFile:IPreviewFile) {

    update({
      ...get(),
      file: nextFile,
    });
  }

  function removeFile() {
    update({
      ...get(),
      file: null,
    });
  }


  function updateAltFile(nextFile:IPreviewFile) {

    update({
      ...get(),
      altFile: nextFile,
    });
  }

  function removeAltFile() {
    update({
      ...get(),
      altFile: null,
    });
  }

  return {
    ...get(),
    loadingKey,
    init,
    onSave,
    updateFile,
    removeFile,
    updateAltFile,
    removeAltFile,
  };
}