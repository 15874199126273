import { useNavigate } from 'react-router-dom';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import { ProviderSetupRecentProviders } from 'util/storage/providerSetupRecentProviders';

export function ProviderSelect() {
  const navigate = useNavigate();

  async function onServiceProviderChange(providerId, providerName:string) {
    // jump the user to the summary step so they can see what they have completed
    // for this provider so far
    ProviderSetupRecentProviders.addProvider({ id: providerId, name: providerName });
    navigate(`/providerOnboarding/${providerId}/summary`);
  };

  return (
    <ServiceProviderAutocomplete
      label={'Edit Service Provider'}
      fieldName='providerId'
      postOnChange={(_formCtx, selectedOption, reason, details) => {
        void onServiceProviderChange(selectedOption?.optionValue, selectedOption?.optionText ?? '');
      }}
      formless={true}
      placeholder={''}
      required={false}
    />
  );
}
