import { Button, Grid, TextField } from '@mui/material';
import { sendTestPushNotification } from 'api/notificationApi';
import { useState } from 'react';
import InviteNearbyNeighborTest from './testing/invite_nearby_neighbor_test';

export default function MiscTesting() {

  const [individualId, setIndividualId] = useState('');

  const handleIndividualIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIndividualId(e.target.value);
  };

  return (
    <div>
      <h1>Misc Testing</h1>
      <Grid container>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label='individualId'
            variant='outlined'
            onChange={handleIndividualIdChange}
          />
        </Grid>
        <Grid item xs={3}>

          <Button
            variant='outlined'
            onClick={async () => {
              await sendTestPushNotification(individualId);
            }}
          >
            <span>test push notification</span>
          </Button>
        </Grid>
        <InviteNearbyNeighborTest/>
      </Grid>
    </div>
  );
}