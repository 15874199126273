import { IGeoJSONCoordinates, IMapBounds, ILatLng } from 'model/maps';

export function findBounds(geoJson:IGeoJSONCoordinates):IMapBounds {
  let bounds = {
    sw: { lat: 0, lng: 0 },
    ne: { lat: 0, lng: 0 },
  };
  if (geoJson?.coordinates) {
    let minLat:number | null = null;
    let minLng:number | null = null;
    let maxLat:number | null = null;
    let maxLng:number | null = null;
    geoJson.coordinates.forEach((x, i) => {
      if (minLng === null || x.longitude < minLng) {
        minLng = x.longitude;
      }
      if (minLat === null || x.latitude < minLat) {
        minLat = x.latitude;
      }
      if (maxLng === null || x.longitude > maxLng) {
        maxLng = x.longitude;
      }
      if (maxLat === null || x.latitude > maxLat) {
        maxLat = x.latitude;
      }
    });
    bounds.sw.lat = minLat ?? 0;
    bounds.sw.lng = minLng ?? 0;
    bounds.ne.lat = maxLat ?? 0;
    bounds.ne.lng = maxLng ?? 0;
  }
  return bounds;
}

export function findCenter(bounds:IMapBounds):ILatLng {
  const minLat = bounds.sw.lat;
  const minLng = bounds.sw.lng;
  const maxLat = bounds.ne.lat;
  const maxLng = bounds.ne.lng;
  return {
    lng: calculateMiddle(maxLng, minLng),
    lat: calculateMiddle(maxLat, minLat),
  };
}

export function findPolygonCenter(polygonCoords: { lat: number; lng: number }[]): google.maps.LatLng {
  const bounds = new google.maps.LatLngBounds();
  polygonCoords.forEach(coord => bounds.extend(coord));
  return bounds.getCenter();
}

function calculateMiddle(max:number | null, min:number | null):number {
  return (min as number) + (((max as number) - (min as number)) / 2.0);
}