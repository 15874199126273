import { Grid, Typography } from '@mui/material';
import styles from '../individuals.module.css';
import LaunchLeaderDetails from './LaunchLeaderDetails';
import LaunchLeaderStatusUpdate from './LaunchLeaderStatusSelect';
import QuickActionButtons from './QuickActionButtons';

export default function LaunchLeaderManagement() {
  return (
    <Grid container flexDirection='column' rowSpacing={5}>
      <Grid container item rowSpacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" className={styles.header}>Launch Leader Details</Typography>
        </Grid>
        <Grid item>
          <LaunchLeaderDetails />
        </Grid>
      </Grid>
      <Grid container item flexDirection='column' rowSpacing={1}>
        <Grid item>
          <Typography variant="h5" className={styles.header}>Update Launch Leader Status</Typography>
        </Grid>
        <Grid item>
          <LaunchLeaderStatusUpdate />
        </Grid>
      </Grid>
      <Grid>
        <QuickActionButtons />
      </Grid>
    </Grid>
  );
}