import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, CardContent, Modal, Grid, useMediaQuery, useTheme, IconButton } from '@mui/material';
import useModal from 'components/modal/useModal';
import { ReactNode } from 'react';
import styles from './genericModal.module.css';

export interface IGenericModalProps {
  modalStoreKey:string;
  title:ReactNode | string | JSX.Element;
  modalContent: string | JSX.Element;
  onConfirm?: any;
  onSecondaryAction?:any;
  secondaryButtonText?:string;
  hideSecondaryButton?:boolean;
  primaryButtonText?:string;
  hideTitleBar?:boolean;
  overrideSx?:any;
  primaryDisabled?:boolean;
  customConfirmationHandling?:boolean;
}

export default function GenericModal({
  modalStoreKey,
  title,
  modalContent,
  secondaryButtonText,
  primaryButtonText,
  onConfirm,
  onSecondaryAction,
  hideTitleBar,
  overrideSx,
  primaryDisabled,
  customConfirmationHandling,
  hideSecondaryButton,
}:IGenericModalProps) {
  const { closeModal, open } = useModal(modalStoreKey);
  const theme = useTheme();
  const xsBrk = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <Modal
      open={open}
      aria-labelledby={`${modalStoreKey}-modal-title`}
      aria-describedby={`${modalStoreKey}-modal-description`}
      className={styles.modalParent}
      sx={{

        '& .MuiPaper-root': {
          xs: {
            height: '100%',
          },
          sm: {
            height: 'auto',
          },
        },
        ...overrideSx,
      }}
    >
      <Card>
        <CardContent sx={{ height: '100%', margin: xsBrk ? '10px' : 0 }}>
          <Grid container justifyContent={'space-between'} direction='column' sx={{ height: '100%' }}>
            <Grid container item >
              {!hideTitleBar && (
                <Grid container item alignItems='center' className={styles.modalTitleWrapper}>
                  <Grid item xs={11}>
                    <div id={`${modalStoreKey}-modal-title`} className={styles.modalTitle}>
                      {title}
                    </div>
                  </Grid>
                  <Grid container item justifyContent='end' xs={1} >
                    <IconButton
                      onClick={closeModal}
                    >
                      <CloseIcon sx={{ color: theme.palette.primary.main }}/>
                    </IconButton>
                  </Grid>
                </Grid>
              )}

              <Grid container item xs={12}>
                <div id={`${modalStoreKey}-modal-description`} className={styles.modalContent} style={{ width: '100%' }}>
                  {modalContent}
                </div>
              </Grid>
            </Grid>
            {!customConfirmationHandling && (
              <Grid container
                justifyContent={{ xs: 'space-between', sm: 'right' }}
                spacing={{ xs: 0, sm: 1 }}
                sx={{
                  margin: {
                    xs: '10px',
                    sm: '0',
                  },
                }}
              >
                {!hideSecondaryButton && (
                  <Grid item xs='auto'>
                    <Button type='button' variant='text' onClick={onSecondaryAction ?? closeModal}>{secondaryButtonText || 'Cancel'}</Button>
                  </Grid>
                )}
                <Grid item xs='auto'>
                  <Button
                    type='button'
                    color='primary'
                    variant='contained'
                    onClick={onConfirm}
                    disabled={primaryDisabled}
                  >
                    {primaryButtonText || 'Confirm'}
                  </Button>
                </Grid>
              </Grid>
            )}

          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
}