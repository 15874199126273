export class ProviderSetupRecentProviders {

  static saveProviders(providers: { name: string; id: string; timestamp: string }[]): void {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 2);
    providers = providers.filter(provider => new Date(provider.timestamp) > sevenDaysAgo);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(providers));
  }

  static addProvider(provider: { name: string; id: string }): void {
    const providers = this.getProviders();
    const existingProviderIndex = providers.findIndex(p => p.id === provider.id);
    if (existingProviderIndex !== -1) {
      providers.splice(existingProviderIndex, 1);
    }
    providers.push({ ...provider, timestamp: new Date().toISOString() });
    this.saveProviders(providers);
  }

  static getProviders(): { name: string; id: string; timestamp: string }[] {
    const data = localStorage.getItem(this.STORAGE_KEY);

    var retrievedData = data ? JSON.parse(data) : [];
    retrievedData.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    return retrievedData;
  }

  private static readonly STORAGE_KEY = 'recentProviders';
}