import { Grid, Typography } from '@mui/material';
import useNeighborhoodBatchForNeighborhoodLead from './hookStore/useLetterBatchNeighborhoodForNeighborhoodLead';

export default function NeighborhoodLeadDetailItems() {
  var {
    individualId,
    individualName,
    neighborhoodName,
    marketName,
  } = useNeighborhoodBatchForNeighborhoodLead();

  return (
    <Grid container item>
      <DetailItem label={'User'} value={`${individualName} (${individualId})`} />
      <DetailItem label={'Neighborhood'} value={neighborhoodName} />
      <DetailItem label={'Market'} value={marketName} />
    </Grid>
  );
}


function DetailItem({ label, value }) {
  return (
    <Grid container item xs={12} justifyContent={'flex-start'}>
      <Typography variant='body1' fontWeight={'bold'}>
        {label}:
      </Typography>
      <Typography variant='body1' paddingLeft={'10px'}>
        {value}
      </Typography>
    </Grid>
  );
}