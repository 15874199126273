import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ServiceOfferingSummaryCard from './serviceOfferingCard/ServiceOfferingSummaryCard';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { findNeighborhoodServiceOfferingsByFilter } from 'api/neighborhoodServiceOfferingApi';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { useParams } from 'react-router-dom';
import { findDiscountSchedulesByProvider } from 'api/discountScheduleApi';
import { IServiceOffering } from 'model/serviceOffering';
import styles from '../providerOnboarding.module.css';
import AddServiceOffering from './AddServiceOffering';
import useProviderSetupStore from '../useProviderSetupStore';

export default function ServiceOfferingsSummary() {
  const { providerId } = useParams();
  const { initServiceOfferingSummary, serviceOfferingSummaryState } = useProviderSetupStore();
  const {
    nsos,
    discountSchedules,
    serviceOfferings,
  } = serviceOfferingSummaryState;

  useEffect(() => {
    void initServiceOfferingSummary();
  }, [providerId]);

  return (
    <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ padding: '0 20px' }}>
      <Typography variant="h6" className={styles.summarySectionTitle} marginTop={'50px'}>Service Offerings</Typography>
      <Grid container item sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {serviceOfferings.length === 0 && (
          <Grid container item xs={12} justifyContent={'center'} alignItems={'center'}>
            <Typography
              variant="body1"
              sx={{ marginTop: '50px', marginBottom: '20px' }}>
              <>No service offerings have been created yet.
              </>
            </Typography>
          </Grid>
        )}
        {serviceOfferings.map((serviceOffering, index) => {
          const nso = nsos.find((el:INeighborhoodServiceOffering) => el.serviceOfferingId === serviceOffering.id);
          const ds = discountSchedules.find((el:any) => el.id === serviceOffering.defaultDiscountScheduleId);
          return (
            <ServiceOfferingSummaryCard
              serviceOffering={serviceOffering}
              index={index}
              key={index}
              nso={nso!}
              discountSchedule={ds!}
            />
          );
        })}
        <AddServiceOffering defaultServiceOffering={serviceOfferings.length > 0 ? serviceOfferings[0] : null}/>
      </Grid>
    </Grid>
  );
}