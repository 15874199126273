import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';
import useProviderSetupStore from '../useProviderSetupStore';
import { findServiceOfferingsByProvider } from 'api/serviceOfferingApi';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { IDiscountSchedule } from 'model/discountSchedule';
import { IServiceOffering } from 'model/serviceOffering';

export function ProviderSelect() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cloneServiceProviderId = queryParams.get('cloneServiceProviderId');
  const { setServiceOfferingCloneOptions, setCurrentServiceOffering, setCurrentDiscountSchedule, setServiceType } = useProviderSetupStore();

  async function onServiceProviderChange(serviceProviderId?:string) {
    if (!serviceProviderId) {
      return;
    }
    if (serviceProviderId != cloneServiceProviderId) {
      const nextQueryParams = new URLSearchParams(location.search);
      nextQueryParams.delete('cloneServiceProviderId');
      const newUrl = `${location.pathname}?${nextQueryParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
    }
    const serviceOfferings = await findServiceOfferingsByProvider(serviceProviderId);
    setServiceOfferingCloneOptions(serviceOfferings.data);
    setCurrentServiceOffering({} as IServiceOffering);
    setCurrentDiscountSchedule({} as IDiscountSchedule);
    setServiceType('');
  };

  useEffect(() => {
    if (cloneServiceProviderId) {
      void onServiceProviderChange(cloneServiceProviderId);
    }
  }, [cloneServiceProviderId]);

  return (
    <ServiceProviderAutocomplete
      label={'Clone From Service Provider'}
      postOnChange={(_formCtx, selectedOption, reason, details) => {
        void onServiceProviderChange(selectedOption?.ancillary?.id);
      }}
      controlledValue={cloneServiceProviderId ? cloneServiceProviderId : undefined}
      formless={true}
      placeholder={''}
      required={false}
    />
  );
}
