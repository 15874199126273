
import { DiscountScheduleStandardOptions, DiscountScheduleTypes, IDiscountScheduleWithStandardOption } from 'model/discountSchedule';
import { CadenceType, IFormFriendlyServiceOffering, IServiceOffering } from 'model/serviceOffering';
import isEqual from 'lodash/isEqual';
import { saveDiscountSchedule } from 'api/discountScheduleApi';

export function createDefaultServiceOfferingForProviderOnboarding({ providerId = '' }):IFormFriendlyServiceOffering {
  return {
    cadenceType: '',
    customFields: [],
    frequency: '',
    hasDisabledMaxCustomerCount: false,
    id: '',
    invitationDescription: '',
    name: '',
    providerId: providerId,
    serviceDetailSections: [
      {
        sectionIcon: 'listIcon',
        sectionHeaderName: 'Service Details',
        sectionItems: ['Our Services Include:'],
        sectionListItems: [
          { val: 'Lorem ipsum dolor sit amet' },
        ],
      },
    ],
    workflowSteps: [
      {
        stepName: 'CONTACT_CUSTOMER',
        shouldShow: true,
      },
      {
        stepName: 'CONSULTATION_SCHEDULED',
        shouldShow: false,
      },
      {
        stepName: 'QUOTE_OR_ESTIMATE_SENT',
        shouldShow: true,
      },
      {
        stepName: 'CUSTOMER_CONFIRMED',
        shouldShow: true,
      },
    ],
    serviceTypeId: '',
    serviceType: '',
    serviceCategory: '',
    subscriptionOptions: {
      label: '',
      options: [
        {
          optionName: '',
          optionValue: '',
          isDefaultOption: false,
          isOneTime: false,
          isNew: true,
        },
      ],
      subscriptionTermInMonths: 0,
    },
    cutoffDayCount: 3,
    defaultDiscountScheduleId: '',
    defaultForServiceType: true,
    territoryServiceOfferingEnabled: true,
    providerCoverageAreaId: '',
  };
}

export function createInitialServiceOfferingFormDataFromExisting(existingServiceOffering):IFormFriendlyServiceOffering {
  const final = { ...existingServiceOffering };
  // adding back subscrition options if they don't exist
  // they will be removed prior to submission for one time cadence type
  if (!final.subscriptionOptions) {
    final.subscriptionOptions = {
      label: '',
      options: [
        {
          optionName: '',
          optionValue: '',
          isDefaultOption: false,
          isOneTime: false,
          isNew: true,
        },
      ],
      subscriptionTermInMonths: 0,
    };
  }
  // format serviceDetailsSections
  final.serviceDetailSections = final.serviceDetailSections.map((section) => {
    const updatedSectionItems = section.sectionItems.map((item) => ({ val: item }));
    const updatedSectionListItems = section.sectionListItems.map((item) => ({ val: item }));

    return {
      ...section,
      sectionItems: updatedSectionItems,
      sectionListItems: updatedSectionListItems,
    };
  });
  return final;
}

export function shouldServiceTypeHavePrice(serviceType: string): Boolean {
  // should probably configure defaults in DB
  const hidePriceServiceTypes = [
    'Window Cleaning',
    'Handyman',
    'Tree Services',
    'Painting',
    'Home Cleaning',
    'Outdoor Living',
    'Roof Inspection & Maintenance',
    'Landscaping',
    'Exterior Painting',
    'Leaf Clean Up',
    'Fencing',
    'Garage Door Repair',
    'Flooring',
    'TV Mounting',
    'Brick Repair & Restoration',
    'Home Media & Technology',
    'Landscape Design',
    'Stump Removal',
    'Concrete Sealing',
    'Window & Door Replacement',
  ];

  if (hidePriceServiceTypes.includes(serviceType)) {
    return false;
  }
  return true;
}

export function createDefaultDiscSchedByServiceAndCadence(serviceType: string, cadenceType: CadenceType) {
  let defaultObj;
  if (shouldServiceTypeHavePrice(serviceType)) {
    defaultObj = {
      hasPrice: true,
      name: `Standard Discount (${serviceType})`,
      priceLabel: 'Starting at',
      priceAmount: 125,
      priceSuffix: '',
      priceSubText: '',
    };
  } else {
    defaultObj = {
      hasPrice: false,
      name: `Standard Discount (${serviceType})`,
      noPriceTextField: '',
    };
  }

  if (cadenceType === CadenceType.ONETIME_ONLY || cadenceType === CadenceType.MOSTLY_ONETIME ) {
    defaultObj.annualBillingCycleCount = 1;
  }

  defaultObj.type = DiscountScheduleTypes.PercentSavePerCustomer;
  defaultObj.discountSchedule = {
    startingAt: '0',
    discountIncrement: '5',
    maxDiscount: '10',
    startingCountOffset: '0',
  };

  return defaultObj;
};

export function getDiscountScheduleStandardOptionFromSchedule(discountSchedule) {
  const match = DiscountScheduleStandardOptions.list.find(
    (option) => (option.discountType === discountSchedule.type && isEqual(option.options, discountSchedule.discountSchedule)),
  );

  return match?.id;
}


export async function createDiscountSchedule(data:IDiscountScheduleWithStandardOption, providerId?:string, currentServiceOffering?:IServiceOffering ): Promise<any> {
  if (data.type === DiscountScheduleTypes.NoDiscount) {
    delete data.discountSchedule;
    delete data.pricingSchedule;
    delete data.flatRateSchedule;
  } else if (data.type === DiscountScheduleTypes.DollarPerCustomer) {
    delete data.discountSchedule;
    delete data.flatRateSchedule;
  } else if (data.type === DiscountScheduleTypes.DollarSavePerCustomer) {
    delete data.pricingSchedule;
    delete data.flatRateSchedule;
  } else if (data.type === DiscountScheduleTypes.DollarFlatRate) {
    delete data.pricingSchedule;
    delete data.discountSchedule;
  } else if (data.type === DiscountScheduleTypes.PercentFlatRate) {
    delete data.pricingSchedule;
    delete data.discountSchedule;
  } else if (data.type === DiscountScheduleTypes.PercentSavePerCustomer) {
    delete data.pricingSchedule;
    delete data.flatRateSchedule;
  }
  if (providerId) {
    data.providerId = providerId;
  }
  if (currentServiceOffering?.serviceTypeId) {
    data.serviceTypeId = currentServiceOffering.serviceTypeId;
  }

  if (data.hasPrice) {
    delete data.noPriceTextField;
  } else {
    delete data.priceAmount;
    delete data.priceLabel;
    delete data.priceSuffix;
    delete data.priceSubText;
    delete data.unitBasedPricingFlag;
  }
  delete data.standardOption;
  return saveDiscountSchedule(data);
}