import { Grid } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AddListItemButton } from '../common';
import ServiceDetailItem from './ServiceDetailItem';

export interface IServiceDetailCommonItemsProps {
  items:any[];
  labelSuffix:string;
  namePrefix:string;
  onClickAdd:any;
  onClickDelete:any;
  onDragEnd:any;
  droppableId:string;
}

export default function ServiceDetailCommonItems({
  items,
  labelSuffix,
  namePrefix,
  onClickAdd,
  onClickDelete,
  onDragEnd,
  droppableId,
}:any) {

  return (
    <Grid container item xs={12} alignItems='center' >
      <Grid container item xs={12}>
        <AddListItemButton
          label={`Add ${labelSuffix}`}
          onClick={onClickAdd}
        />
      </Grid>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId} >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} style={{ width: '100%' }}>

              {
                items.map((field:any, index:number)=> {
                  return (
                    <Draggable draggableId={field.id} index={index} key={field.id}>
                      {(dragprovided, snapshot) => (
                        <div
                          style={{ width: '100%' }}
                          ref={dragprovided.innerRef}
                          {...dragprovided.draggableProps}
                          {...dragprovided.dragHandleProps}
                        >
                          <ServiceDetailItem
                            field={field}
                            index={index}
                            labelSuffix={labelSuffix}
                            namePrefix={namePrefix}
                            onClickAdd={onClickAdd}
                            onClickDelete={onClickDelete}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

    </Grid>
  );
}