import { useParams } from 'react-router-dom';
import useNeighborhoodBatchForNeighborhoodLead from './hookStore/useLetterBatchNeighborhoodForNeighborhoodLead';
import { useEffect } from 'react';
import Loading from 'components/Layout/Loading';
import NeighborhoodLeadDetailItems from './NeighborhoodLeadDetailItems';
import { Grid } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import BatchOptionsDropdown from './BatchOptionsDropdown';
import FormRow from './FormRow';


export default function CreateLetterBatchNeighborhoodForNeighborhoodLead() {
  var { init, loadingKey } = useNeighborhoodBatchForNeighborhoodLead();
  var { individualId } = useParams();

  useEffect(() => {
    void init();
  }, [individualId]);

  return (
    <Loading loadingKey={loadingKey} >
      <Grid container spacing={2} className='pageGridContainer'>
        <Grid item xs={12}>
          <PageHeader headerText={'Create Letter Batch Neighborhood for Neighborhood Lead'} />
        </Grid>
        <NeighborhoodLeadDetailItems />
        <Grid item xs={12}>
          <FormRow />
        </Grid>
      </Grid>
    </Loading>
  );
}