import Axios, { AxiosResponse } from 'axios';
import { ICoveredNeighborhood, IProviderCoverageAreaDTO, IProviderCoverageAreaFormDTO, ITerritoryLayer } from 'model/providerCoverageArea';


export function findCoverageAreaDTOsByServiceProvider(serviceProviderId:string):Promise<AxiosResponse<IProviderCoverageAreaDTO[]>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/providerCoverageArea/v2/findByServiceProvider/${serviceProviderId}`);
}

export function saveProviderCoverageAreas(dto:IProviderCoverageAreaFormDTO) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/providerCoverageArea/v2/saveProviderCoverageAreas`, dto);
}

export function findCoveredNeighborhoods(dto:IProviderCoverageAreaDTO):Promise<AxiosResponse<ICoveredNeighborhood[]>> {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/providerCoverageArea/v2/findCoveredNeighborhoods`, dto);
}

export function getTerritoryLayerForMarket(marketId:string):Promise<AxiosResponse<ITerritoryLayer>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/providerCoverageArea/v2/getTerritoryLayerForMarket/${marketId}`);
}


