import CadenceTypeSelect from '../formComponents/CadenceTypeSelect';
import Loading, { useLoading } from 'components/Layout/Loading';
import ServiceOfferingFormTabsSection from './ServiceOfferingFormTabsSection';
import ServiceTypeSelect from '../formComponents/ServiceTypeSelect';
import styles from '../providerOnboarding.module.css';
import useProviderSetupStore from '../useProviderSetupStore';
import useToast from 'components/toast/useToast';
import {
  CadenceType, IServiceOffering,
} from 'model/serviceOffering';
import { Box, Grid, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { HookTextField } from 'components/reactHookForm';
import { PrimaryButton } from 'components/buttons';
import { createDefaultServiceOfferingForProviderOnboarding, createInitialServiceOfferingFormDataFromExisting } from '../helper';
import { saveServiceOffering } from 'api/serviceOfferingApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ProviderSelect } from './ProviderSelect';
import ServiceOfferingCloneOptionsSelect from './ServiceOfferingCloneOptionsSelect';
import useMarkets from 'hooks/useMarkets';
import { seedCustomerFromReferral } from 'api/popularityApi';
import { maybeSendReferringIndividualProviderLaunchedEmail } from 'api/serviceProviderApi';
import CloneWithImagesCheckbox from './CloneWithImagesCheckbox';
import { NSOCreationUtil } from 'util/data/nsoCreationUtil';
import { ICreateDiscountScheduleParams, ProviderSetupServiceOfferingUtil } from './providerSetupServiceOfferingUtil';
import AdditionalDetails from './AdditionalDetails';

const loadingKey = 'ProviderOnboardingServiceOfferingForm';


export default function ServiceOfferingForm() {
  const {
    cadenceType,
    currentServiceOffering,
    files,
    init,
    serviceType,
    setCadenceType,
    setCurrentServiceOffering,
    provider,
  } = useProviderSetupStore();


  const { market } = useMarkets();
  const { providerId, serviceOfferingId } = useParams();
  const defaultValues = currentServiceOffering?.id ?
    createInitialServiceOfferingFormDataFromExisting(currentServiceOffering) :
    createDefaultServiceOfferingForProviderOnboarding({ providerId });
  const formContext = useForm<IServiceOffering>({
    mode: 'onChange',
    defaultValues: defaultValues,
  });
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast } = useToast();
  const { serviceTypeId } = formContext.getValues();
  const navigate = useNavigate();

  useEffect(() => {
    void init(market);
  }, [market]);

  useEffect(() => {
    if (currentServiceOffering?.id) {
      formContext.reset(createInitialServiceOfferingFormDataFromExisting(currentServiceOffering));
    }
  }, [currentServiceOffering]);


  const onSubmit : SubmitHandler<IServiceOffering> = async data => {
    try {
      onLoading();
      if (data.subscriptionOptions?.options) {
        var options = data.subscriptionOptions?.options;
        if (options.filter(x => x.isOneTime).length > 0 && data.cadenceType === CadenceType.SUBSCRIPTION_ONLY) {
          throw new Error('Cannot save a subscription only service offering with a one-time option.');
        }
      }
      if (!data.defaultDiscountScheduleId) {
        const params: ICreateDiscountScheduleParams = {
          providerId: providerId!,
          serviceType: serviceType,
          cadenceType: cadenceType,
          currentServiceOffering: currentServiceOffering,
        };
        const defaultDiscountSchedule = await ProviderSetupServiceOfferingUtil.createDefaultDiscountSchedule(params);
        data.defaultDiscountScheduleId = defaultDiscountSchedule?.data.id;
      }
      const formDto = await ProviderSetupServiceOfferingUtil.convertDataToSave(data, providerId!, serviceOfferingId);

      var formData = new FormData();
      formData.append('dto', new Blob([JSON.stringify(formDto)], {
        type: 'application/json',
      }));
      for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i]);
      }

      setCadenceType(data.cadenceType);
      const serviceOfferingResp = await saveServiceOffering(formData, currentServiceOffering?.id);
      setCurrentServiceOffering(serviceOfferingResp?.data);

      // if serviceOfferingId is present in the path,
      // we are updating an existing service offering
      // and we don't need to generate an NSO
      if (!serviceOfferingId) {
        var savedServiceOffering = serviceOfferingResp?.data;
        // generate NSO in neighborville (or northstone in local & staging envs)
        await NSOCreationUtil.generateNeighborvilleNso(
          {
            serviceProviderId: providerId ?? savedServiceOffering.providerId,
            serviceType: serviceType,
            serviceOfferingId: savedServiceOffering.id,
            discountScheduleId: savedServiceOffering.defaultDiscountScheduleId,
          });

        // if the service provider was a referral, and we have the referring neighborhood ID, then create an NSO in that neighborhood as well
        // and seed a customer
        if (provider?.referringNeighborhoodId) {
          const response = await NSOCreationUtil.generateNeighborvilleNso({
            serviceProviderId: providerId ?? savedServiceOffering.providerId,
            serviceType: serviceType,
            serviceOfferingId: savedServiceOffering.id,
            discountScheduleId: savedServiceOffering.defaultDiscountScheduleId,
            neighborhoodId: provider?.referringNeighborhoodId,
          });
          if (response?.data?.length > 0) {
            const nso = response?.data[0];
            await seedCustomerFromReferral(providerId!, nso.id);
            try {
              await maybeSendReferringIndividualProviderLaunchedEmail(providerId!);
            } catch (e) {
              console.error('error sending referring individual provider launched email: ', { error: e, provider: providerId });
            }
          }
        }
      }

      doneLoading(300);
      navigate(`/providerOnboarding/${providerId}/service-offerings/${serviceOfferingResp?.data.id}/discount-schedules/${serviceOfferingResp?.data.defaultDiscountScheduleId}`);
    } catch (e:any) {
      doneLoading();
      if (e.response.data) {
        createErrorToast('Error creating Service offering: '+ e.response.data.message);
      }

      console.error('error creating Service offering:', e);
    }
  };

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container sx={{ padding: '20px' }} >
          <Grid item xs={12} sx={{ boxShadow: 1, borderRadius: '4px', padding: '10px' }}>
            <Typography variant='h6' className={styles.cardTitle} sx={{ marginTop: '10px' }}>Service Offering</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} sx={{ paddingBottom: '10px' }}>
                <ServiceTypeSelect/>
              </Grid>
              {serviceTypeId && (
                <>
                  <Grid item xs={12} sm={3}>
                    <HookTextField
                      name='name'
                      label='Name'
                      required
                      maxLength={100}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <CadenceTypeSelect />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6}>
                <AdditionalDetails/>
              </Grid>
              {!serviceOfferingId && (
                <>
                  <Grid item xs={12} sm={3}>
                    <ProviderSelect/>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ServiceOfferingCloneOptionsSelect />
                    <Box sx={{ marginTop: '20px' }}>
                      <CloneWithImagesCheckbox/>
                    </Box>
                  </Grid>
                </>
              )}

            </Grid>

          </Grid>

          {serviceTypeId && (
            <Grid item xs={12} sx={{ justifyContent: 'center', alignItems: 'center', boxShadow: 1, borderRadius: '4px', padding: '20px', width: '100%', marginTop: '20px' }}>
              <ServiceOfferingFormTabsSection />
            </Grid>
          )}
          <Grid item xs={4}>
            <PrimaryButton type='submit' sx={{ marginTop: '40px' }}> Save & Continue </PrimaryButton>
          </Grid>
        </Grid>
      </form>
      <Loading loadingKey={loadingKey} />
    </FormProvider>
  );
}