import { Grid, Typography } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useTerritoryDetails from '../hookStore/useTerritoryDetails';
import useMarkets from 'hooks/useMarkets';

export const GenericReferAProConfirmationModalKey = 'GenericReferAProConfirmationModalKey';

export default function GenericReferAProConfirmationModal() {
  const { formData, onSendReferAProGenericForTerritory, init } = useTerritoryDetails();
  var { market } = useMarkets();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Email Send'}
      body='Send Generic refer a pro email?'
      modalKey={GenericReferAProConfirmationModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' sx={{ maxWidth: '600px' }}>
          <Grid item xs={12} paddingTop={'30px'} paddingBottom={'25px'}>
            <Typography variant='body1'>
                Send refer a pro generic email to territory?
            </Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (args) => {
        const { sendToMeAsTest } = args;
        await onSendReferAProGenericForTerritory(sendToMeAsTest);
        if (market) {
          void init(market.id, false);
        }
      }}
    />
  );
}