import { Grid } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';

export default function StreetFairRepId() {
  return (
    <Grid item xs={12}>
      <HookTextField
        name='approvedBy'
        label='SF Representative ID'
      />
    </Grid>
  );
}