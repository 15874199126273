import { Grid } from '@mui/material';
import { HookIntegerField } from 'components/reactHookForm';
import { createValidateIntegerRange } from 'util/rhfValidateFunctions';

const validateQualitativeScore = createValidateIntegerRange(1, 10);

export default function SupplyQualitativeScore() {
  return (
    <Grid item xs={12}>
      <HookIntegerField
        name='supplyQualitativeScore'
        label='Stoke Meter'
        validationRules={{
          min: { value: 1 || false, message: 'Minimum value of 1' },
          max: { value: 10, message: 'Maximum value of 10' },
          validate: validateQualitativeScore,
        }}
      />
    </Grid>
  );
}