import { Grid, Typography } from '@mui/material';
import { IServiceOfferingImageRef } from 'model/serviceOffering';
import { useFormContext, useWatch } from 'react-hook-form';

import ExistingImage from './ExistingImage';
import useProviderSetupStore from 'pages/providerSetup/old/useProviderSetupStore';

export default function ExistingImages() {
  const formCtx = useFormContext();
  const { markForDelete, unmarkForDelete, pendingDeleteImages } = useProviderSetupStore();
  const imageRefs:IServiceOfferingImageRef[] = useWatch({ control: formCtx.control, name: 'imageRefs' }) ?? [];
  const allImages = [...imageRefs, ...pendingDeleteImages];
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant='h6'>Existing images</Typography>
      </Grid>
      {allImages.map((x, i) => {
        return (
          <ExistingImage
            key={x.imageFileName}
            imageWrapper={x}
            markForDelete={markForDelete}
            unmarkForDelete={unmarkForDelete}
          />
        );
      })}
    </Grid>
  );
}