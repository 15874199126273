
import { ConfirmationModal } from 'pages/neighborhoodLaunchTooling/confirmationModal';
import useProviderCoverageAreaLaunchToolingStore from 'pages/neighborhoodLaunchTooling/hookStore/useProviderCoverageAreaLaunchTooling';
import { useFormContext } from 'react-hook-form';

export const reconcileNsosByServiceProviderModalKey = 'reconcileNsosByServiceProviderModalKey';

export default function ReconcileModalWrapper() {
  const { getValues, control } = useFormContext();
  const { onReconcileNSOsByServiceProvider } = useProviderCoverageAreaLaunchToolingStore();
  const dryRun = getValues().dryRun;
  return (
    <ConfirmationModal
      body={`Reconcile NSOs by provider?. ${dryRun ? '(DRY RUN)' : ''}`}
      modalKey={reconcileNsosByServiceProviderModalKey}
      title={`Reconcile NSOs? ${dryRun ? '(DRY RUN)' : ''}`}
      onConfirm={async (data) => {
        await onReconcileNSOsByServiceProvider(data);
      }}
      successText='NSO reconciliation received.'
    />
  );
}