import { PrimaryButton } from 'components/buttons';
import useServiceProviderDetail from '../../hookStore/useServiceProviderDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { useState } from 'react';

export default function CheckProviderUsers() {
  const { existingUsersDto, queryExistingProviderUsers } = useServiceProviderDetail();
  const [buttonPressed, setButtonPressed] = useState(false);
  return (
    <Accordion sx={{ borderRadius: '4px', width: '600px' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>View Provider users</Typography>
      </AccordionSummary>
      <AccordionDetails>

        <Box sx={{
          paddingBottom: '100px',
        }}>
          <PrimaryButton onClick={async () => {
            await queryExistingProviderUsers();
            setButtonPressed(true);
          }}>Query Existing Provider Users</PrimaryButton>
          <Typography variant='body1' sx={{ paddingTop: '20px', paddingBottom: '20px' }}>Existing Provider Users</Typography>
          {buttonPressed && (existingUsersDto == null || existingUsersDto.providerUsers.length === 0) ? <div>No existing users</div> : null}
          {existingUsersDto?.providerUsers.map((user) => (
            <div style={{ paddingBottom: '12px' }}>
              <div key={user.uuid}>{user.email}</div>
              <div style={{ fontSize: '12px' }}>
                Message: {user.message ?? 'No issues found'}
              </div>
            </div>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}