import { Grid } from '@mui/material';
import Loading from 'components/Layout/Loading';
import PageHeader from 'components/SectionHeaders/PageHeader';
import ProviderCoverageAreaLaunchToolingForm from './ProviderCoverageAreaLaunchToolingForm';
import useNeighborhoodLaunchTooling from '../hookStore/useNeighborhoodLaunchTooling';

/**
 * This version is linked from the "provider onboarding" page. Selection of service provider is not available
 * @returns
 */
export default function ServiceProviderLaunch() {
  const { loadingKey: launchProvidersLoadingKey } = useNeighborhoodLaunchTooling();
  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Provider Launch Tool'} />
      </Grid>
      <Grid item xs={12}>
        <Loading loadingKey={launchProvidersLoadingKey} size={100}>
          <ProviderCoverageAreaLaunchToolingForm/>
        </Loading>
      </Grid>
    </Grid>
  );
}