import { Campaign } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import NeighborhoodMultiAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodMultiAutocomplete';
import { BigTooltip } from 'components/tooltip/BigTooltip';
import HelpIcon from '@mui/icons-material/Help';
import { CampaignType } from 'model/letter_batch/ancillary';
import { useFormContext, useWatch } from 'react-hook-form';

export interface IAddNeighborhoodToBatchAutocompleteProps {
  marketId: string | null;
}

export default function AddNeighborhoodToBatchAutocomplete({
  marketId,
}: IAddNeighborhoodToBatchAutocompleteProps) {
  const formCtx = useFormContext();

  const val = useWatch({ name: 'campaignType', control: formCtx.control });
  if (val === CampaignType.LAUNCH_LEADER_ACQUISITION) {
    return (
      <Grid container item xs >
        <Grid item xs>
          <NeighborhoodMultiAutocomplete
            label={'Quick Add Neighborhoods'}
            fieldName='additionalNeighborhoodIds'
            formless={false}
            placeholder={'Search and check additional neighborhoods'}
            required={false}
            extraProps={{
              clearOnBlur: false,

            }}
            optionDescriptionFunc= {(x) => {
              if (x.zipCodeTerritory) {
                return `${x.name} ${x.status} ${x.zipCodeTerritory}`;
              }
              if (!x.zipCodes || x.zipCodes.length === 0) {
                return `${x.name} ${x.status}`;
              }
              return `${x.name} ${x.status} [zip(s): ${x.zipCodes.join(',')}]`;
            }}
            marketOverride={!marketId ? 'skip' : `${marketId}`}
          />
        </Grid>
        <Grid item xs='auto' >
          <Box sx={{ height: '6px' }}/>
          <BigTooltip
            title={
              'Use this multiple selection autocomplete to add additional neighborhood campaigns that will inherit the data on this page.'
            }
          >
            <IconButton>
              <HelpIcon />
            </IconButton>
          </BigTooltip>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }

}
