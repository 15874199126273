import { Button, Grid } from '@mui/material';
import ProviderLogo from 'components/visual/ProviderLogo';
import { useFormContext } from 'react-hook-form';

export default function CompanyImagePreview() {
  const formContext = useFormContext();
  const watchImageUrlGuid = formContext.watch('imageUrlGuid');
  return (
    <Grid container item xs={12}>
      {watchImageUrlGuid && (
        <>
          <ProviderLogo imageUrlGuid={watchImageUrlGuid}
            style={{
              maxHeight: '65px',
              maxWidth: '250px',
            }}
          />
          <Button type='button' onClick={() => formContext.setValue('imageUrlGuid', '')}>Clear image</Button>
        </>
      )}
    </Grid>
  );
}