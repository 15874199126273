import Axios, { AxiosResponse } from 'axios';
import { IMinimumAcceptableRateOfConversionForm } from 'model/lookup/minimumAcceptableRateOfConversion';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function uploadMarc(data:IMinimumAcceptableRateOfConversionForm) : Promise<AxiosResponse<any>> {
  var formData = new FormData();
  formData.append('template', data.template);
  return Axios.post(`${apiUrl}/marc/v2/uploadMarc`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

