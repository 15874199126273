import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import ProviderInformationForm from './providerInformation/ProviderInformationForm';
import ServiceOfferingForm from './serviceOffering/ServiceOfferingForm';
import DiscountScheduleForm from './discountSchedule/DiscountScheduleForm';
import { Grid, StepButton } from '@mui/material';
import OnboardingSummary from './summary/OnboardingSummary';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ProviderSetupLanding from './providerSetupLanding/ProviderSetupLanding';
import { ProviderSetupMode } from './ProviderSetupChooser';
import PrimaryOutlinedButton from 'components/buttons/PrimaryOutlinedButton';

const steps = ['Provider Information', 'Service Offering', 'Discount Schedule', 'Summary'];

export class ProviderOnboardingQueryParams {
  static editDiscountSchedule = 'edit-discount-schedule';
}
class ProviderOnboardingStep {
  static providerInformation = 0;
  static serviceOffering = 1;
  static discountSchedule = 2;
  static summary = 3;
}

const getComponentForStep = (step: number) => {

  switch (step) {
    case 0:
      return <ProviderInformationForm />;
    case 1:
      return <ServiceOfferingForm />;
    case 2:
      return <DiscountScheduleForm />;
    case 3:
      return <OnboardingSummary/>;
    default:
      return <div>Invalid step</div>;
  }
};

export default function ProviderOnboardingStepper({ step, mode }) {
  const handleStep = (s) => {
    // setActiveStep(step);
  };
  const { providerId, serviceOfferingId, discountScheduleId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editDiscountSchedule = queryParams.get(ProviderOnboardingQueryParams.editDiscountSchedule);
  const shouldShowOnDiscountSchedule = editDiscountSchedule && step === ProviderOnboardingStep.discountSchedule;
  const navigate = useNavigate();
  if (!providerId && !mode) {
    return <ProviderSetupLanding/>;
  }
  return (
    <Grid container justifyContent={'center'}>
      <Grid container item xs={12} sm={8} justifyContent={'center'}>
        {providerId && ((step != ProviderOnboardingStep.summary && step != ProviderOnboardingStep.discountSchedule) || shouldShowOnDiscountSchedule) && (
          <PrimaryOutlinedButton
            style={{ marginTop: '25px' }}
            onClick={() => {
              navigate(`/providerOnboarding/${providerId}/summary`);
            }}>Back to summary</PrimaryOutlinedButton>
        )}
      </Grid>
      {providerId && step !== ProviderOnboardingStep.summary && (
        <Grid item xs={12} sm={6}>
          <Stepper activeStep={step} sx={{ marginTop: '25px' }}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepButton onClick={() => handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      )}

      {!providerId && mode === ProviderSetupMode.create && (
        <Grid item xs={12}>
          <ProviderInformationForm/>
        </Grid>
      )}
      {providerId && (
        <Grid item xs={12}>
          {getComponentForStep(step)}
        </Grid>
      )}
    </Grid>

  );
}