import { Box, Typography } from '@mui/material';
import TooltipDrawer from 'components/drawer/TooltipDrawer';

export const neighborhoodDetailsMiningDrawerTooltipKey = 'neighborhoodDetailsMiningDrawerTooltipKey';
export default function MiningDrawer() {
  return (
    <TooltipDrawer
      storeKey={neighborhoodDetailsMiningDrawerTooltipKey}
    >
      <Box sx={{ padding: '20px', maxWidth: '500px' }}>
        <Typography variant='h5'>Mining (aka Launch Leader Acquisition)</Typography>
        <Typography variant='subtitle1'sx={{ paddingTop: '20px', fontWeight: 'bold' }}>
            When does the letter offer display to the user in the onboarding flow modal?
        </Typography>
        <Typography variant='body1' sx={{ paddingTop: '20px' }}>
            The letter offer will display as the last slide in the onboarding modal when:
        </Typography>
        <ul>
          <li>'Hide LL Offer' is toggled off</li>
          <li>'Hide Facebook RTP Offer' is toggled on or there are no 'Neighborhood Facebook Groups'</li>
          <li>
            The neighborhood status is either not 'Launched' or it was 'Launched' in the past 30 days. If there is no launch date at all then we assume it was launched further back than 30 days.
          </li>
          <li>
            The launch darkly flag 'new-user-onboarding-referral-content' has the 'launch_leader_offer_enabled' field set to 'true'.
          </li>
        </ul>
        <Typography variant='body1' sx={{ paddingTop: '20px' }}>
            The Facebook Request to Post offer will display in the neighborhood portal when the following conditions are met:
        </Typography>
        <ul>
          <li>The user must be signed in</li>
          <li>The user must not have previously dismissed the offer on the device (i.e. value in local storage)</li>
          <li>The user must not be a launch leader (aka neighborhood lead)</li>
          <li>The neighborhood is not launched or was launched recently (past 30 days)</li>
          <li>The "nlUnlockFlowRouteUpdatesNov2024" feature flag must be WIDE</li>
          <li>The "neighborhood-portal-fab-facebook-offer-nov24" must be WIDE</li>
          <li>The variant for "neighborhood-portal-fab-facebook-offer-variant-dec2024" must not be NONE</li>
          <li>The neighborhood must exist</li>
          <li>The "Hide FB Offer" must be toggled off</li>
          <li>The neighborhood must have at least 1 facebook group</li>
          <li>The "newUserOnboardingReferralContent" feature flag must have an offer value either using the "use_rating_offer" field alongside a map of "fb_rtp_offer" values or the value of the feature flag must be null to get a default of $50.</li>
        </ul>
      </Box>
    </TooltipDrawer>
  );
}