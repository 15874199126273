import { createFilterOptions } from '@mui/material';
import { createNeighborhoodCampaignTagOption, findAllNeighborhoodCampaignTags } from 'api/neighborhoodCampaignTagApi';
import HookAsyncAutocomplete from 'components/reactHookForm/HookAsyncAutocomplete';
import useToast from 'components/toast/useToast';
import { IDropDownOptionCreatable, IDropdownOption } from 'model/dropdown';
import { CampaignTagUtil } from 'model/letter_batch/campaign_tag/campaignTag';
import { LookUpEntityType } from 'model/lookup/lookup';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { generateGuid, isGuid } from 'util/guidUtil';
import { debounce } from 'lodash';
const filter = createFilterOptions<IDropDownOptionCreatable>();


export default function CampaignTag() {
  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();
  const formCtx = useFormContext();
  const [options, setOptions] = useState<IDropdownOption[]>([]);
  useEffect(() => {
    const fetchCampaignTags = async () => {
      const campaignTagsRes = await findAllNeighborhoodCampaignTags();
      if (campaignTagsRes.data) {
        setOptions(CampaignTagUtil.convertToDropdownOptions(campaignTagsRes.data!));
      }
    };

    void fetchCampaignTags();
  }, []);


  const postOnChange = async (_formCtx, val, reason, details) => {

    if (reason === 'selectOption' && details?.option.inputValue) {
      createInfoToast('Saving new option...');
      let res = await createNeighborhoodCampaignTagOption({
        value: details?.option.inputValue.trim(),
        lookUpEntityType: LookUpEntityType.NEIGHBORHOOD_CAMPAIGN_TAG,
      });
      if (res.data) {
        const campaignTagsRes = await findAllNeighborhoodCampaignTags();
        if (campaignTagsRes.data) {
          var nextOptions = CampaignTagUtil.convertToDropdownOptions(campaignTagsRes.data!);
          const campaignTagIdSet = new Set(nextOptions.map(tag => tag.optionValue));
          setOptions(nextOptions);
          let nextCampaignTags:string[] = [];
          let vals = formCtx.getValues();
          for (let id of vals.campaignTagIds) {
            if (!campaignTagIdSet.has(id)) {
              //this is the case where we generated a guid on the client and need to replace it
              nextCampaignTags.push(res.data.id);
            } else {
              nextCampaignTags.push(id);
            }
          }
          _formCtx.setValue('campaignTagIds', nextCampaignTags);
          createSuccessToast('New option saved');
        }
      } else {
        createErrorToast(`Error saving new option ${res.data}` );
      }
    }
  };

  return (
    <HookAsyncAutocomplete
      name='campaignTagIds'
      label="Campaign Tags (type to add)"
      postOnChange={postOnChange}
      extraProps={{
        onOpen: async () => {
          const campaignTagsRes = await findAllNeighborhoodCampaignTags();
          if (campaignTagsRes.data) {
            setOptions(CampaignTagUtil.convertToDropdownOptions(campaignTagsRes.data!));
          }
        },
        filterOptions: (opts, params) => {
          const filtered = filter(opts, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.optionValue);
          if (inputValue !== '' && !isExisting) {
            var tempGuid = generateGuid();
            filtered.push({
              key: tempGuid,
              optionValue: tempGuid, //doing this as a hack to get the dropdown to work
              optionText: `Add "${inputValue}"`,
              inputValue: inputValue,
            });
          }

          return filtered;
        },
        selectOnFocus: true,
        clearOnBlur: true,
        handleHomeEndKeys: true,
        getOptionLabel: (option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.optionText;
        },
        freeSolo: true,
      }}
      dropdownOptions={options}
      placeholder={''}
      onInputChange={(event, newInputValue, reason) => {

      } }
    />
  );
}