
import { IGeoJSONCoordinates } from 'model/maps';
import { ITerritoryLayer } from 'model/providerCoverageArea';
import { findPolygonCenter } from '../util';

export class GmapsTerritoryLayerService {

  static addTerritoryLayer(
    map: google.maps.Map,
    territoryLayer?: ITerritoryLayer,
  ) : () => void {
    if (!territoryLayer) {
      return () => {};
    }
    const layer = new google.maps.Data();
    var options: google.maps.Data.GeoJsonOptions | null = null;
    let labelMap = new Map<string, google.maps.Marker>();
    for (const territoryId in territoryLayer.territoryIdToPolygonMap) {

      const geoJson = territoryLayer.territoryIdToPolygonMap[territoryId];
      let territoryName = territoryLayer.territoryIdToTerritory[territoryId].name;
      const { center, feature } = GmapsTerritoryLayerService.convertForGoogleMaps(territoryId, territoryName, geoJson);
      const label = new google.maps.Marker({
        position: center,
        map: map,
        label: {
          className: 'google-map-marker-label',
          text: territoryName,
        },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 0,
        },
      });
      labelMap.set(territoryId, label);
      layer.add(feature);
    }

    layer.addListener('mouseover', (event) => {
      const id = event.feature.getProperty('id');
      const name = event.feature.getProperty('name');
      var label = labelMap.get(id);
      if (label != null) {
        label.setLabel({
          className: 'google-map-marker-label hovered',
          text: name,
        });
      }

    });

    layer.addListener('mouseout', (event) => {
      const id = event.feature.getProperty('id');
      const name = event.feature.getProperty('name');
      var label = labelMap.get(id);
      if (label != null) {
        label.setLabel({
          className: 'google-map-marker-label',
          text: name,
        });
      }
    });


    layer.setStyle({
      clickable: true,

      fillColor: '#bb22bb',
      strokeColor: '#bb22bb',
      fillOpacity: 0.1,
      strokeWeight: 0.75,
    });
    layer.setMap(map);
    return () => {
      layer.setMap(null);
    };
  }

  static convertForGoogleMaps(id:string, name:string, geoJson: IGeoJSONCoordinates) : TerritoryFeature {
    const polygonCoords = geoJson.coordinates.map((c) => {
      return { lat: c.latitude, lng: c.longitude };
    });
    const center = findPolygonCenter(polygonCoords);
    var feature = new google.maps.Data.Feature({
      id: id,
      geometry: new google.maps.Data.Polygon([polygonCoords]),
      properties: {
        id: id,
        name: name,
      },
    });

    return new TerritoryFeature(
      feature,
      center,
    );
  }


}

export class TerritoryFeature {
  feature: google.maps.Data.Feature;
  center: google.maps.LatLng;

  constructor(feature: google.maps.Data.Feature, center: google.maps.LatLng) {
    this.feature = feature;
    this.center = center;
  }
}