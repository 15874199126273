import { Grid, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { StreetFairProviderRatings } from 'model/serviceProvider';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function StreetFairProviderRating() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const [streetFairRatingsMenuItems] = useState<any[]>(StreetFairProviderRatings.list);
  return (
    <Grid item xs={12} md={3}>
      <FormControl variant='standard' fullWidth error={isError('streetFairProviderRating')}>
        <InputLabel htmlFor="status-type">StreetFair Provider Rating</InputLabel>
        <HookSelect
          control={formCtx.control}
          rules={{
            required: { value: true, message: 'Required' },
          }}
          name={'streetFairProviderRating'}
          id='streetfair-provider-rating'
          menuItems={streetFairRatingsMenuItems}
        />
        <FormHelperText>{getErrorMessage('streetFairProviderRating')}</FormHelperText>
      </FormControl>
    </Grid>
  );
}