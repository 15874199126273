import Axios, { AxiosResponse } from 'axios';
import { ICampaignTag } from 'model/letter_batch/campaign_tag/campaignTag';
import { ICreateLookUpOptionRequest } from 'model/lookup/lookup';
import { INeighborhoodCampaignTag } from 'model/lookup/neighborhoodCampaignTag';

const apiUrl = window.REACT_APP_BASE_API_URI;
const baseUrl = `${apiUrl}/neighborhoodCampaignTags`;

export function findAllNeighborhoodCampaignTagsOld():Promise<AxiosResponse<INeighborhoodCampaignTag[]>> {
  return Axios.get(`${baseUrl}/v2/findAll`);
}

export function findAllNeighborhoodCampaignTags():Promise<AxiosResponse<ICampaignTag[]>> {
  return Axios.get(`${baseUrl}/v3/findAll`);
}

export function createNeighborhoodCampaignTagOptionOld(data: ICreateLookUpOptionRequest) {
  return Axios.post(`${baseUrl}/v2/createNeighborhoodCampaignTag`, data);
}

export function createNeighborhoodCampaignTagOption(data: ICreateLookUpOptionRequest): Promise<AxiosResponse<ICampaignTag>> {
  return Axios.post(`${baseUrl}/v3/createNeighborhoodCampaignTag`, data);
}