
import { FormControl, FormHelperText } from '@mui/material';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import get from 'lodash.get';
import { useFormContext } from 'react-hook-form';
import ServiceProviderAutocomplete from 'components/serviceProviderAutocomplete/ServiceProviderAutocomplete';


export interface IServiceProviderSelectProps {
  validate?:Function;
}

export default function ServiceProviderSelect({ validate }:IServiceProviderSelectProps) {
  const formCtx = useFormContext();
  const { formState, setError, clearErrors } = formCtx;
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const isDirty = get(formState.dirtyFields, 'selectedServiceProvider', false);
  const isTouched = get(formState.touchedFields, 'selectedServiceProvider', false);
  let rules:any = {
    required: {
      value: true, message: 'Required',
    },
  };
  if (validate) {
    rules = {
      validate,
    };
  }
  return (

    <ServiceProviderAutocomplete
      label='Service Providers'
      fieldName='selectedServiceProvider'
      placeholder='Search and check service provider'
      required={false}
      formless={false}
      rules={rules}
    />


  );
}