import { Grid, Alert } from '@mui/material';
import { saveServiceProviderImage } from 'api/serviceProviderApi';
import { ServiceProviderFormContext } from 'contexts/serviceProvider/useServiceProviderForm';
import { IServiceProvider } from 'model/serviceProvider';
import { useContext, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import styles from './companyImageDropzone.module.css';
import { useParams } from 'react-router-dom';

export default function CompanyImageDropzone() {

  const { onImageUploading } = useContext(ServiceProviderFormContext);
  const { serviceProviderId } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const ctx = useFormContext<IServiceProvider>();
  const onDrop = useCallback(async acceptedFiles => {
    var formData = new FormData();
    if (acceptedFiles.length === 0) return;
    if (acceptedFiles.filter(x => x.type === 'image/webp').length > 0) {
      setErrorMessage('WebP format is not supported. Please upload a PNG, JPEG, or SVG file.');
      return;
    } else {
      setErrorMessage('');
    }

    formData.append('image', acceptedFiles[0]);
    if (ctx.getValues().imageKey) {
      formData.set('imageKey', ctx.getValues().imageKey);
    }
    if (serviceProviderId) {
      formData.set('serviceProviderId', serviceProviderId);
    }
    //refactor this later
    const onImageUploadingFinished = onImageUploading();
    let existingImageUrlGuid = '';
    try {
      existingImageUrlGuid = ctx.getValues('imageUrlGuid');
    } catch (e:any) {
      //noop. will be new image
    }

    try {
      const response = await saveServiceProviderImage(formData);
      const imageUrlGuid = response.data;
      ctx.setValue('imageUrlGuid', imageUrlGuid);
      onImageUploadingFinished();
    } catch (e:any) {
      onImageUploadingFinished();
      setErrorMessage(e.response.data);
    }

  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Grid container item xs={12} justifyContent='center'>
      <Grid item xs>
        <div {...getRootProps()} className={styles.dropzoneWrapper}>
          <input {...getInputProps()} accept='.png,.jpeg,.svg'/>
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag and drop a file or click here to select a file</p>
          }

        </div>
      </Grid>
      <Grid item xs={12}>
        {errorMessage && (
          <Alert
            severity='error'
            className={styles.uploadErrorMessage}
            variant='outlined'
          >
            {errorMessage}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}