import { Link, MenuItem, Tooltip, Typography } from '@mui/material';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { IServiceOffering } from 'model/serviceOffering';


export interface INeighborvilleNsoLinkProps {
  nso: INeighborhoodServiceOffering;
}

export default function NeighborvilleNsoLink({ nso } : INeighborvilleNsoLinkProps) {
  return (
    <Link href={`${window.REACT_APP_CONSUMER_BASE_URI}/neighborhood-service-offering/${nso?.id}`} target="_blank" rel='noreferrer'>

      <Tooltip title={'View the Neighborville version of this service offering in a new tab'} >
        <Typography>View</Typography>
      </Tooltip>

    </Link>
  );
}