import { useFlags } from 'launchdarkly-react-client-sdk';
import ServiceProviderDetails from './serviceProviderDetails/serviceProviderDetails';
import { default as OldServiceProviderDetails } from './old/serviceProviderDetails/serviceProviderDetails';

export default function ServiceProviderDetailsChooser() {
  const {
    adminPortalCleanupDec2024,
  } = useFlags();
  if (adminPortalCleanupDec2024) {
    return <ServiceProviderDetails />;
  }
  return <OldServiceProviderDetails />;
}