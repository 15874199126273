import PrimaryOutlinedButton from 'components/buttons/PrimaryOutlinedButton';
import { createEmptyCoverageArea } from 'model/maps';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import useProviderCoverageArea from '../hookStore/useProviderCoverageArea';
import { useLoading } from 'components/Layout/Loading';

export default function AddCoverageAreaButton() {
  const { control, formState } = useFormContext();
  const { loadingKey, marketCoverageAreaMap, onAddCoverageArea } = useProviderCoverageArea();
  const { append } = useFieldArray({
    name: 'providerCoverageAreas',
  });
  const { getLoadingState } = useLoading(loadingKey);
  const isLoading = getLoadingState().isLoading;
  const watchedServiceProviderId = useWatch({ control, name: 'serviceProviderId' });
  return (
    <PrimaryOutlinedButton
      disabled={!watchedServiceProviderId || isLoading}
      onClick={async () => {

        await onAddCoverageArea();
        append(createEmptyCoverageArea(marketCoverageAreaMap));
      }}
    >
        Add Coverage Area
    </PrimaryOutlinedButton>
  );
}