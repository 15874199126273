
import { Card, CardContent, Grid, Grow } from '@mui/material';
import AddButton from 'components/buttons/AddButton';
import Centered from 'components/Layout/Centered';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useLayoutEffect, useState } from 'react';
import styles from '../providerOnboarding.module.css';

interface IServiceOfferingContainerProps {
  items:any[];
  render:any;
}
interface IDraggableServiceOfferingContainerProps {
  items:any[];
  render:any;
  droppableId:string;
  onDragEnd:any;
}

interface IServiceOfferingCardProps {
  idx:number;
  render: any;
}

export function StepHeader({ children }:any) {
  return (
    <h4 className={''}>{children}</h4>
  );
}

export function AddListItemButton({ label, onClick, tooltipTitle }:any) {
  return (
    <>
      <Centered centerType='vertical' className={styles.addLabel}>{label}</Centered>
      <AddButton
        tooltipTitle={tooltipTitle ||''}
        onClick={onClick}
        className={styles.addButton}
      />
    </>
  );
}

export function ServiceOfferingCard({ idx, render }:IServiceOfferingCardProps) {
  const timeout = 250;
  const [transition, setTransition] = useState(false);
  const [sortOrder, setSortOrder] = useState(idx);


  useLayoutEffect(() => {
    //this layout effect is used to enable the transition when it first renders
    setTransition(true);
  }, []);

  function onDelete(cb:any) {
    //this function calls the onDelete callback (e.g. remove(index)) after
    //transitioning out. Need to use the setTimeout function to allow the transition to complete before deleting.
    setTransition(false);

    setTimeout(() => {
      cb();
    }, timeout);
  }

  function onSortOrderChange(e:any) {
    setSortOrder(e.target.value);
  }

  return (
    <Grid container item xs={12} sx={{ marginTop: '10px' }}>
      <Grow in={transition} timeout={timeout}>
        <Card sx={{ width: '100%' }}>
          <CardContent>
            {render(idx, onDelete, sortOrder, onSortOrderChange)}
          </CardContent>
        </Card>
      </Grow>
    </Grid>
  );
}

export function ServiceOfferingCardContainer({ items, render }:IServiceOfferingContainerProps) {

  return (
    <Grid container item >
      {
        items.map((item, index) => {
          return (
            <ServiceOfferingCard
              key={item.id}
              idx={index}
              render={render}
            />
          );
        },
        )}
    </Grid>
  );
}

export function DraggableServiceOfferingCardContainer({ items, render, droppableId, onDragEnd }:IDraggableServiceOfferingContainerProps) {

  return (
    <Grid container item xs={12} sx={{ margin: '20px 0' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId} >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} style={{ width: '100%' }}>
              {items.map((item, index) => {

                return (
                  <Draggable draggableId={item.id} index={index} key={item.id}>
                    {(dragprovided, snapshot) => (
                      <div
                        style={{ width: '100%' }}
                        ref={dragprovided.innerRef}
                        {...dragprovided.draggableProps}
                        {...dragprovided.dragHandleProps}
                      >
                        <ServiceOfferingCard
                          idx={index}
                          render={render}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              },
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
}