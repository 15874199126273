import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from '../providerOnboarding.module.css';
import { Accordion, AccordionSummary, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { HookCheckbox, HookIntegerField } from 'components/reactHookForm';

export default function AdditionalDetails() {
  const formContext = useFormContext();
  const { serviceTypeId } = formContext.getValues();
  if (!serviceTypeId) {
    return null;
  }
  //TODO: this uses Grid without proper structure
  return (

    <Accordion sx={{ paddingLeft: '10px', borderRadius: '4px' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant='h6' className={styles.accordionTitle}>Additional Details</Typography>
      </AccordionSummary>
      <Grid item xs={3}>
        <HookIntegerField
          name='cutoffDayCount'
          label='Default Cutoff Day Count'
        />
      </Grid>
      <Grid item container sx={{ flexDirection: 'row', justifyContent: 'flex-start', padding: '10px' }}>
        <Grid item xs={2}>
          <HookCheckbox
            name='territoryServiceOfferingEnabled'
            label='TSOI Enabled?'
          />
        </Grid>
        <Grid item xs={4}>
          <HookCheckbox
            name='hasDisabledMaxCustomerCount'
            label='Has Disabled Max Customer Count?'
          />
        </Grid>
        <Grid item xs={4}>
          <HookCheckbox
            name='defaultForServiceType'
            label='Is Default for Service Type'
          />
        </Grid>
      </Grid>
    </Accordion>

  );
}