
import ConfirmationModal from 'pages/neighborhoodLaunchTooling/confirmationModal/ConfirmationModal';
import useProviderCoverageAreaLaunchToolingStore from 'pages/neighborhoodLaunchTooling/hookStore/useProviderCoverageAreaLaunchTooling';

export const launchProviderModalKey = 'launchProviderModalKey';

export default function ModalWrapper() {
  const { save } = useProviderCoverageAreaLaunchToolingStore();
  return (
    <ConfirmationModal
      body='Confirm launch.'
      modalKey={launchProviderModalKey}
      title={'Launch Providers?'}
      onConfirm={async (data) => {
        await save(data);
      }}
      successText='Launch providers submitted.'
    />
  );
}