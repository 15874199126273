
import ServiceOfferingsSummary from './ServiceOfferingsSummary';
import styles from '../providerOnboarding.module.css';
import { Grid, Typography } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import useProviderSetupStore from '../useProviderSetupStore';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IInternalServiceProvider, StatusType } from 'model/serviceProvider';
import { saveServiceProvider } from 'api/serviceProviderApi';
import useToast from 'components/toast/useToast';
import useModal from 'components/modal/useModal';
import ApproveForSapModal, { createInitialApproveForSapModal, modalStoreKey } from './ApproveForSapModal';
import useSelf from 'hooks/useSelf';
import { ROLE_ADMIN_DEFAULT } from 'model/roles';
import useMarkets from 'hooks/useMarkets';
import ProviderSummary from './ProviderSummary';
import SummaryActionBar from './summaryActions/SummaryActionBar';
import CreateAnotherProvider from './summaryActions/CreateAnotherProvider';

export default function OnboardingSummary() {
  const {
    init,
    provider,
  } = useProviderSetupStore();
  const { market } = useMarkets();

  const { providerId } = useParams();

  const { createErrorToast, createSuccessToast } = useToast();
  const { openModal } = useModal(modalStoreKey);
  const { roles } = useSelf();
  const hasDefaultAdminRole = roles.indexOf(ROLE_ADMIN_DEFAULT) > -1;

  useEffect(() => {
    if (providerId && market) {
      void init(market);
    }
  }, [providerId, market]);

  const onSubmitForApprovalClick = async () => {
    var newProvider = { ...provider, status: StatusType.READY_FOR_APPROVAL } as IInternalServiceProvider;
    try {
      await saveServiceProvider(newProvider);
      createSuccessToast('Successfully submitted for approval!');
    } catch (error) {
      console.error('error attempting to submit for approval: ', { error });
      createErrorToast('Error submitting for approval');
    }
  };

  const onApproveForSapClick = () => {
    return openModal(createInitialApproveForSapModal(provider));
  };

  return (
    <Grid container >

      <Grid container item justifyContent={'center'}>
        <Typography variant="h5" sx={{ marginTop: '20px', alignSelf: 'center' }} className={styles.summarySectionTitle}>Summary</Typography>
      </Grid>

      <Grid container item xs={12} alignItems={'flex-start'} >
        <Grid container item xs={12} sm={4}>
          <SummaryActionBar/>
        </Grid>
        <Grid container item xs={12} sm={4}>
          <ProviderSummary />
        </Grid>
        <Grid container item xs={12} sm={4} justifyContent={'flex-end'}>
          <Grid item xs='auto'>
            <CreateAnotherProvider/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ServiceOfferingsSummary />
      </Grid>

      <Grid container item sx={{ justifyContent: 'center', alignItems: 'center', marginBottom: '50px' }}>
        { provider.status === StatusType.DRAFT &&
              <PrimaryButton onClick={onSubmitForApprovalClick} className={styles.submitForApprovalButton}>
                Submit For Approval
              </PrimaryButton>
        }
        { provider.status === StatusType.READY_FOR_APPROVAL && hasDefaultAdminRole &&
              <><PrimaryButton onClick={onApproveForSapClick} className={styles.submitForApprovalButton}>
              Approve for SAP
              </PrimaryButton><ApproveForSapModal serviceProvider={provider} /></>
        }
      </Grid>

    </Grid>
  );
}