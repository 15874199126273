
import { useFormContext, useFieldArray } from 'react-hook-form';
import ServiceDetailCommonItems from './ServiceDetailCommonItems';


export default function ServiceDetailItems({ serviceDetailSection }:any) {
  const { control } = useFormContext();
  const { fields: sectionItems, append, move, remove } = useFieldArray({
    control,
    name: `${serviceDetailSection}.sectionItems`,
  });
  return (
    <ServiceDetailCommonItems
      droppableId='provider-setup-service-detail-items'
      onDragEnd={(result:any) => {
        if (result.source && result.destination) {
          move(result.source.index, result.destination.index);
        }
      }}
      items={sectionItems}
      labelSuffix='item'
      namePrefix={`${serviceDetailSection}.sectionItems`}
      onClickAdd={(field:any, index:number) => {
        if (index >= 0) {
          append({ val: '' }, { shouldFocus: true, focusIndex: (index || 0)+1 });
        } else {
          append({ val: '' }, { shouldFocus: true });
        }
      }}
      onClickDelete={(index:number) => remove(index)}
      move={move}
    />
  );
}