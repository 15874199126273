import { Chip, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ProviderSetupRecentProviders } from 'util/storage/providerSetupRecentProviders';

export default function RecentProviders() {

  const navigate = useNavigate();
  return (
    <Grid item xs={12} md={6} justifyContent={'center'} alignContent={'center'} alignItems={'center'} justifyItems={'center'}>
      <Typography variant='subtitle1' sx={{ textAlign: 'center' }}>Recent Providers (past 2 days)</Typography>
      {
        ProviderSetupRecentProviders.getProviders().map((provider) => {
          return (
            <Chip
              label={provider.name}
              onClick={() => navigate(`/providerOnboarding/${provider.id}/summary`)}
              clickable
              sx={{ margin: '4px' }}
            />
          );
        })
      }
    </Grid>
  );
}