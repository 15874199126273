import { Grid, Tab, Tabs, Typography } from '@mui/material';
import Loading from 'components/Layout/Loading';
import ConfirmationModal, { ConfirmationModalContextProvider } from 'components/modals/ConfirmationModal';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useState } from 'react';
import useNeighborhoodLaunchTooling from './hookStore/useNeighborhoodLaunchTooling';
import useReconcileNeighborhood from './hookStore/useReconcileNeighborhood';
import useRequestManagement from './hookStore/useRequestManagement';
import NeighborhoodLaunchToolingForm from './NeighborhoodLaunchToolingForm';
import ProviderCoverageAreaLaunchToolingForm from './providerLaunch/ProviderCoverageAreaLaunchToolingForm';
import ReconcileNsosByNeighborhoodForm from './reconcileNsosByNeighborhoodForm';
import RequestManagementForm from './RequestManagementForm';
import TabPanel from 'components/tabs/SimpleTabPanel';

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function NeighborhoodLaunchTooling() {
  const { loadingKey: launchProvidersLoadingKey } = useNeighborhoodLaunchTooling();
  const { loadingKey: requestManagementLoadingKey } = useRequestManagement();
  const { loadingKey: reconcileNsosByNeighborhoodLoadingKey, onReconcileNSOsByNeighborhood } = useReconcileNeighborhood();
  const [value, setValue] = useState(0);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'New Neighborhood Launch Tool'} />
      </Grid>
      <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange} aria-label='tabs'>
          {/* <Tab label="Launch Providers" {...a11yProps(0)}/>
          <Tab label="Delete by Request Id" {...a11yProps(1)}/> */}
          <Tab label="Launch Providers" {...a11yProps(0)}/>
          <Tab label="Reconcile NSOs by Neighborhood" {...a11yProps(1)}/>
        </Tabs>
        {/* <TabPanel value={value} index={0}>
          <Loading loadingKey={launchProvidersLoadingKey} size={100}>
            <NeighborhoodLaunchToolingForm/>
          </Loading>
        </TabPanel> */}

        {/* <TabPanel value={value} index={1}>
          <Loading loadingKey={requestManagementLoadingKey} size={100}>
            <RequestManagementForm/>
          </Loading>
        </TabPanel> */}
        <TabPanel value={value} index={0} >
          <Loading loadingKey={launchProvidersLoadingKey} size={100}>
            <ProviderCoverageAreaLaunchToolingForm/>
          </Loading>
        </TabPanel>
        <TabPanel value={value} index={1} >
          <Loading loadingKey={reconcileNsosByNeighborhoodLoadingKey} size={100}>
            <ConfirmationModalContextProvider onConfirm={(args) => onReconcileNSOsByNeighborhood(args) }>
              <ReconcileNsosByNeighborhoodForm />
              <ConfirmationModal
                title={(onConfirmArgs) => (<div >Reconcile NSOs for {onConfirmArgs == null ? '' : onConfirmArgs.neighborhoodName}?</div>)}
                message={(onConfirmArgs:any) => (
                  <Grid container spacing={1} alignItems='center' justifyContent='center' sx={{ marginTop: '10px', marginBottom: '20px' }}>
                    <Grid item xs={12}>
                      <Typography>{onConfirmArgs.dryRun ? 'Dry Run' : ''}</Typography>
                    </Grid>
                    <Grid item xs>
                    Click confirm to initiate.
                    </Grid>
                  </Grid>
                )}
              />
            </ConfirmationModalContextProvider>
          </Loading>
        </TabPanel>
      </Grid>
    </Grid>
  );
}
