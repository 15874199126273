import { createValidatePhone, createValidateEmail, createValidatePhoneNotRequired, createValidateEmailNotRequired } from 'util/rhfValidateFunctions';
import { Grid, Typography } from '@mui/material';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import AdditionalPhoneNumbers from './AdditionalPhoneNumbers';
import AdditionalContacts from './AdditionalContacts';
import { PrimaryButton } from 'components/buttons';
import useToast from 'components/toast/useToast';
import { generateProviderSignUpLink } from 'api/userApi';
import { useParams } from 'react-router-dom';
import HookPhoneField from 'components/reactHookForm/HookPhoneField';
import HookDatePicker from 'components/reactHookForm/HookDatePicker';
import { useFormContext, useWatch } from 'react-hook-form';
import { activeOrGreater } from 'util/serviceProviderUtil';


const validateEmail = createValidateEmail();
const validateEmailNotRequired = createValidateEmailNotRequired('Not a valid email');
const validatePhone = createValidatePhone('Should be of form xxx-xxx-xxxx or xxxyyyzzzz');
const validatePhoneNotRequired = createValidatePhoneNotRequired('Should be of form xxx-xxx-xxxx or xxxyyyzzzz');

export default function ContactInfo() {
  const { createErrorToast, createInfoToast } = useToast();
  const { serviceProviderId } = useParams();
  const { control } = useFormContext();
  const watchStatus = useWatch({ control, name: 'status' });
  var isActiveOrGreater = activeOrGreater(watchStatus);
  return (
    <>
      <Grid container alignItems='center' item xs={12}>
        <Grid item xs='auto'>
          <Typography variant='h6' sx={{ textDecoration: 'underline' }}>Contact information</Typography>
        </Grid>
        <Grid item xs='auto' sx={{ paddingLeft: '20px' }}>
          <PrimaryButton
            size='small'
            disabled={!serviceProviderId}
            onClick={ async () => {
              var res = await generateProviderSignUpLink(serviceProviderId!);
              if (res.data) {
                if (res.data.success) {
                  var link = res.data.oneTimeSignUpLink;
                  if (link) {
                    createInfoToast(`Sign up link generated and automatically copied to clipboard: ${link}`);
                    navigator.clipboard.writeText(link).catch(reason => createErrorToast(reason));
                  } else {
                    createErrorToast('Unable to generate sign up link');
                  }
                } else {
                  createErrorToast(`Unable to generate sign up link with message: ${res.data.errorMessage}`);
                }

              }

            }}
          >
            <>Generate Sign Up Link</>
          </PrimaryButton>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <HookTextField
          label='Contact Name'
          name='contactPersonName'
          required={isActiveOrGreater}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <HookPhoneField
          label='Contact Phone'
          name='contactPersonPhone'
          required={isActiveOrGreater}
          validationRules={{
            validate: isActiveOrGreater ? validatePhone : validatePhoneNotRequired,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <HookTextField
          label='Contact Email'
          name='contactPersonEmail'
          required={isActiveOrGreater}
          validationRules={{
            validate: isActiveOrGreater ? validateEmail : validateEmailNotRequired,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <HookPhoneField
          label='Booking Phone Number'
          name='bookingPhone'
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <HookPhoneField
          label='Support Contact Phone'
          name='businessContactPhone'
          required={isActiveOrGreater}
          validationRules={{
            validate: isActiveOrGreater ? validatePhone : validatePhoneNotRequired,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <HookTextField
          label='Support Contact Email'
          name='businessContactEmail'
          required={isActiveOrGreater}
          validationRules={{
            validate: isActiveOrGreater ? validateEmail : validateEmailNotRequired,
          }}
        />
      </Grid>
      <Grid item xs={12} md={'auto'}>
        <HookCheckbox
          label='Phone Booking Opted Out'
          name='phoneBookingOptedOut'
        />
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={6} lg={5}>
          <AdditionalPhoneNumbers/>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          <AdditionalContacts/>
        </Grid>
      </Grid>
      <Grid item xs={12}></Grid>

      <Grid container item spacing={1}>
        <Grid item xs={6} md={6} lg={4}>
          <HookDatePicker
            label='Pause Notifications Until Date'
            name='pauseNotificationsUntilDate'
            textFieldProps={{ fullWidth: true, variant: 'standard' }}
            required={false}
            dateFormats={{
              fullDate: 'yyyy-MM-dd',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}