import { IDropdownOption } from 'model/dropdown';

export interface ICampaignTag {
  id:string;
  tag:string;
}

export class CampaignTagUtil {

  static convertToDropdownOptions(tags: ICampaignTag[]): IDropdownOption[] {
    return tags.map(tag => ( {
      key: tag.id,
      optionValue: tag.id,
      optionText: tag.tag,
      ancillary: tag,
    } as IDropdownOption));
  }
}