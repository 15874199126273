import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { IDropdownOption } from 'model/dropdown';
import { useParams } from 'react-router-dom';
import useToast from 'components/toast/useToast';
import { findInternalServiceProviderById, updateServiceProviderStatus } from 'api/serviceProviderApi';
import { IInternalServiceProvider, IServiceProviderStatusUpdateDTO, createEmptyInternalServiceProvider } from 'model/serviceProvider';


const loadingKey = 'ServiceProviderStatusSelectStore';
type ServiceProviderStatusSelectStore = {
  serviceProvider: IInternalServiceProvider;
  markets: IDropdownOption[];
}

const { get, update, registerListener, unregisterListener } = createStore<ServiceProviderStatusSelectStore>('ServiceProviderStatusSelectStore', {
  serviceProvider: createEmptyInternalServiceProvider(),
  markets: [],
});


export default function useServiceProviderStatusSelect() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { providerId } = useParams();
  const { createErrorToast, createErrorHandlingToast, createInfoToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        serviceProvider: createEmptyInternalServiceProvider(),
      });
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      let serviceProvider = createEmptyInternalServiceProvider();
      if (providerId) {
        const response = await findInternalServiceProviderById(providerId);

        if (response.data) {
          serviceProvider = response.data;
        } else {
          createErrorToast('Error loading service provider');
        }
      } else {

      }

      update({
        ...get(),
        serviceProvider,
      });

    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }


  async function onUpdateStatus(data:IServiceProviderStatusUpdateDTO) {
    try {
      if (providerId) {
        await updateServiceProviderStatus(providerId, data);
      }
      createInfoToast('Status updated');
    } catch (e:any) {
      console.error(e);
      createErrorHandlingToast('Error saving service provider. Data refreshed. ', e);
      await init();
    }
  }

  return {
    ...get(),
    loadingKey,
    init,
    onUpdateStatus,
  };
}