import { Grid, Typography } from '@mui/material';
import { IServiceOfferingImageRef } from 'model/serviceOffering';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ExistingImage from './ExistingImage';
import useProviderSetupStore from 'pages/providerSetup/useProviderSetupStore';
import { useEffect, useState } from 'react';

const getListStyle = isDraggingOver => ({
  border: isDraggingOver ? '1px dashed green' : 'none',
  display: 'flex',
  overflow: 'auto',
  marginBottom: '8px',
  width: '100%',
});

const getItemStyle = (isDragging:any, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
});


const reorderEphemeral = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const moveEphemeral = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};


export default function ExistingImages() {
  const formCtx = useFormContext();
  const { markForDelete, unmarkForDelete, updateFiles, pendingDeleteImages } = useProviderSetupStore();
  const imageRefs:IServiceOfferingImageRef[] = useWatch({ control: formCtx.control, name: 'imageRefs' }) ?? [];
  const allImages = [...imageRefs, ...pendingDeleteImages];
  const [chunkedImages, setChunkedImages] = useState<IServiceOfferingImageRef[][]>([]);

  useEffect(() => {
    const chunkSize = 5; // Adjust the chunk size as needed
    let newChunkedImages:IServiceOfferingImageRef[][] = [];
    for (let i = 0; i < allImages.length; i += chunkSize) {
      newChunkedImages.push(allImages.slice(i, i + chunkSize));
    }
    setChunkedImages(newChunkedImages);
  }, [imageRefs, pendingDeleteImages]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant='h6'>Existing images</Typography>
      </Grid>
      <DragDropContext onDragEnd={(result:any) => {
        const { source, destination } = result;

        if (!destination) {
          return;
        }
        if (source.droppableId === destination.droppableId) {
          if (source.index === destination.index) {
            return;
          }
          const chunkIndex = parseInt(source.droppableId.split('-').pop()!, 10);
          const items = reorderEphemeral(
            chunkedImages[chunkIndex],
            source.index,
            destination.index,
          ) as IServiceOfferingImageRef[];
          const newChunkedImages = [...chunkedImages];
          newChunkedImages[chunkIndex] = items;
          const flattenedImages = newChunkedImages.flat();
          formCtx.setValue('imageRefs', flattenedImages);
        } else if (source.droppableId !== destination.droppableId) {
          const sourceChunkIndex = parseInt(source.droppableId.split('-').pop()!, 10);
          const destinationChunkIndex = parseInt(destination.droppableId.split('-').pop()!, 10);
          const itemResult = moveEphemeral(
            chunkedImages[sourceChunkIndex],
            chunkedImages[destinationChunkIndex],
            source,
            destination,
          );
          const newChunkedImages = [...chunkedImages];
          newChunkedImages[sourceChunkIndex] = itemResult[source.droppableId];
          newChunkedImages[destinationChunkIndex] = itemResult[destination.droppableId];
          const flattenedImages = newChunkedImages.flat();
          formCtx.setValue('imageRefs', flattenedImages);
        }
      }}>
        {chunkedImages.map((chunk, chunkIndex) => {
          return (
            <Droppable droppableId={`provider-setup-service-offering-images-${chunkIndex}`} direction='horizontal' key={chunkIndex}>
              {(provided, droppableSnapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(droppableSnapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >

                  {chunk.map((x, i) => {
                    return (
                      <Draggable draggableId={x.imageFileName} index={i} key={x.imageFileName}>
                        {(dragprovided, snapshot) => (
                          <div
                            ref={dragprovided.innerRef}
                            {...dragprovided.draggableProps}
                            {...dragprovided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, dragprovided.draggableProps.style)}
                          >
                            <ExistingImage
                              imageWrapper={x}
                              markForDelete={markForDelete}
                              unmarkForDelete={unmarkForDelete}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          );
        })
        }

      </DragDropContext>
    </Grid>
  );
}