
import { Grid, Tab, Tabs } from '@mui/material';
import TabPanel from 'components/tabs/SimpleTabPanel';
import { useState } from 'react';
import CustomerUploadForm from './customerUploadForm';
import EditAllocations from './edit_allocations/editAllocations';

function a11yProps(index: number) {
  return {
    'id': `customer-upload-tab-${index}`,
    'aria-controls': `customer-upload-tabpanel-${index}`,
  };
}

export default function StepOneOperations() {
  const [tab, setTab] = useState<number>(0);
  return (
    <>
      <Tabs
        value={tab}
        onChange={(event: React.SyntheticEvent, newValue: number) => {
          setTab(newValue);
        }}
        aria-label="upload customers or edit allocations"
      >
        <Tab label="Customer Upload" {...a11yProps(0)} />
        <Tab label="Edit Allocations" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={tab} index={0} sx={{ width: '100%' }}>
        <Grid container item xs={12} >
          <CustomerUploadForm/>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <EditAllocations/>
      </TabPanel>
    </>
  );
}