import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HookCheckbox, HookIntegerField, HookTextField } from 'components/reactHookForm';
import { useParams } from 'react-router-dom';
import ServiceSummaryActions from '../../serviceSummaryActions/ServiceSummaryActions';
import MarketSelect from './components/MarketSelect';
import HappeningSoonInvitePreferred from './components/HappeningSoonInvitePreferred';
import AdditionalRelevantPlatformRatings from './components/AdditionalRelevantPlatformRatings';
import RatingInfo from './components/RatingInfo';
import FacebookRatingInfo from './components/FacebookRatingInfo';
import StreetFairProviderRating from './components/StreetFairProviderRating';
import AcceptedPaymentTypes from './components/AcceptedPaymentTypes';
import AboutProviderInfo from './AboutProviderInfo';
import CustomerUploadKeyDisplay from './components/CustomerUploadKeyDisplay';

export default function AdvancedSettings() {
  const { serviceProviderId } = useParams();
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Advanced Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container item xs={12} spacing={1}>
          <CustomerUploadKeyDisplay/>
          <Grid item xs={12} md={2}>
            <MarketSelect />
          </Grid>

          <Grid item xs={12} md={2}>
            <HookTextField
              label='Website URL'
              name='websiteUrl'
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <HookIntegerField
              label='Max Service Instances Shown'
              name='maxServiceInstancesShown'
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <HookIntegerField
              name='serviceReminderDaysPrior'
              label='Service Reminder Days Prior'
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} md={3}>
            <HookTextField
              label='Facebook Id'
              name='facebookPlaceId'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <HookTextField
              label='Stripe Customer Id'
              name='stripeCustomerId'
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12} md='auto'>
              <HookCheckbox
                name='nonStandardPricing'
                label='Non-standard pricing?'
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={2} md='auto'>
              <HookCheckbox
                label='Are SMS notifications enabled?'
                name='smsNotificationEnabled'
                defaultValue={true}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={2} md='auto'>
              <HookCheckbox
                label='Neighborhood Notifications Enabled?'
                name='neighborhoodNotificationsAllowed'
                defaultValue={false}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md='auto'>
              <HookCheckbox
                label='In-App Chat Excluded?'
                name='inAppChatExcluded'
                defaultValue={false}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md='auto'>
              <HookCheckbox
                name='verifiedCustomers'
                label='Customers Uploaded?'
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <HappeningSoonInvitePreferred/>
          </Grid>

          <Grid item xs={12}>
            <AcceptedPaymentTypes/>
          </Grid>
          <Grid container item xs={12} md sx={{ marginRight: { md: '10px' } }}>
            <AboutProviderInfo/>
          </Grid>

          {serviceProviderId && (
            <>
              <ServiceSummaryActions serviceProviderId={serviceProviderId}/>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}