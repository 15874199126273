import { sortByString } from 'util/sortFunctions';
import { findDiscountSchedulesByProvider } from 'api/discountScheduleApi';
import { findServiceTypeInfo } from 'api/serviceCategoryApi';
import { findServiceOfferingById, findServiceOfferingsByProvider, saveServiceOffering } from 'api/serviceOfferingApi';
import { findCoverageAreaDTOsByServiceProvider } from 'api/providerCoverageAreaApi';

import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import useServiceOfferingForm from 'hooks/serviceOffering/useServiceOfferingForm';
import { createUninitializedDropdownOption, IDropdownOption, UNSELECTED_OPTION } from 'model/dropdown';
import { CadenceType, createEmptyServiceOffering, IFormFriendlyServiceOffering, IServiceDetailSection, IServiceOffering, IServiceOfferingFormDTO, IServiceOfferingImageRef } from 'model/serviceOffering';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { createDefaultValues } from '../serviceOfferingDetailHelper';
import { IPreviewFile } from 'model/imageUpload';
import { IProviderCoverageAreaDTO } from 'model/providerCoverageArea';
import { ServiceTypeInfoUtil } from 'util/serviceType/serviceTypeInfoUtil';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { saveNeighborhoodServiceOffering } from 'api/neighborhoodServiceOfferingApi';
import { NSOCreationUtil } from 'util/data/nsoCreationUtil';

const loadingKey = 'serviceOfferingDetail';
const sortByName = sortByString('name');

const OMITTABLE_FIELDS = {
  images: 'images',
};

type ServiceOfferingDetail = {
  hasServiceProviderError:boolean;
  discountScheduleOptions:IDropdownOption[];
  providerCoverageAreaOptions: IDropdownOption[];
  selectedServiceProviderId:string;
  existingDefaultServiceOffering:IServiceOffering | null;
  existingServiceOfferings:IServiceOffering[];
  originalFormData: IServiceOffering;
  initialFormData:IFormFriendlyServiceOffering;
  isDefaultForServiceType:boolean;
  originalServiceTypeId:string;
  isClone:boolean;
  files:IPreviewFile[];
  pendingDeleteImages: IServiceOfferingImageRef[];
  hardDeletedImageRefs: IServiceOfferingImageRef[];
  omitImages:boolean;
}

const { get, update, registerListener, unregisterListener } = createStore<ServiceOfferingDetail>('serviceOfferingDetail', {
  hasServiceProviderError: false,
  discountScheduleOptions: [createUninitializedDropdownOption()],
  providerCoverageAreaOptions: [createUninitializedDropdownOption()],
  selectedServiceProviderId: '',

  existingDefaultServiceOffering: null,
  existingServiceOfferings: [],
  originalFormData: createEmptyServiceOffering(),
  initialFormData: createDefaultValues(),
  isDefaultForServiceType: false,
  originalServiceTypeId: '',
  isClone: false,
  files: [],
  pendingDeleteImages: [],
  hardDeletedImageRefs: [],
  omitImages: false,
});

export default function useServiceOfferingDetails() {
  const {
    convertSectionDetailSectionToFormFriendly,
    convertSectionDetailSectionOriginal,
  } = useServiceOfferingForm(null);
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { createErrorToast, createSuccessToast, createInfoToast } = useToast();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId, serviceOfferingId } = useParams();
  const [searchParams] = useSearchParams();


  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(isClone:boolean) {
    onLoading();
    const omissions = searchParams.getAll('omit');
    const omitImages = omissions.indexOf(OMITTABLE_FIELDS.images) > -1;
    let providerCoverageAreaOptions:IDropdownOption[] = [];
    if (serviceOfferingId) {
      const [serviceOfferingRes] = await Promise.all([
        findServiceOfferingById(serviceOfferingId),
      ]);

      let selectedServiceProviderId = '';
      let initialFormData = createDefaultValues();
      let existingServiceOfferings:any[] = [];
      let discountScheduleOptions = [];
      if (serviceProviderId) {
        selectedServiceProviderId = isClone ? '' :serviceProviderId;
        const [_existingServiceOfferings, _discountScheduleOptions] = await Promise.all([
          findAndSetExistingServiceOfferings(serviceProviderId),
          populateDiscountScheduleDropdown(serviceProviderId),
        ]);
        if (selectedServiceProviderId) {
          const providerCoverageAreaDtosRes = await findCoverageAreaDTOsByServiceProvider(selectedServiceProviderId);
          providerCoverageAreaOptions = _createProviderCoverageAreaOptions(providerCoverageAreaDtosRes.data);
        }
        existingServiceOfferings = _existingServiceOfferings;
        discountScheduleOptions = _discountScheduleOptions;
      }


      if (serviceOfferingRes.data) {
        //useFieldArray does not support strings alone so must convert to an object
        serviceOfferingRes.data.serviceDetailSections = serviceOfferingRes.data.serviceDetailSections
          .map((x:IServiceDetailSection) => convertSectionDetailSectionToFormFriendly(x));
        if (!serviceOfferingRes.data.subscriptionOptions) {
          serviceOfferingRes.data.subscriptionOptions = {
            label: '',
            options: [
              {
                optionName: '',
                optionValue: '',
                isDefaultOption: false,
                isOneTime: false,
                isNew: true,
              },
            ],
            subscriptionTermInMonths: 0,
          };
        }
        if (isClone) {
          initialFormData = modifyForClone(serviceOfferingRes.data, omitImages) as IFormFriendlyServiceOffering;
        } else {
          initialFormData = serviceOfferingRes.data;
        }
      }
      update({
        ...get(),
        selectedServiceProviderId,
        initialFormData,
        existingDefaultServiceOffering: null,
        originalServiceTypeId: serviceOfferingRes.data.serviceTypeId,
        isDefaultForServiceType: serviceOfferingRes.data.defaultForServiceType,
        existingServiceOfferings,
        discountScheduleOptions,
        providerCoverageAreaOptions,
        hardDeletedImageRefs: [],
        pendingDeleteImages: [],
        isClone,
        omitImages,
      });
      if (isClone && omitImages) {
        update({
          ...get(),
          files: [],
        });
      }
    } else {

      let selectedServiceProviderId = '';
      let existingServiceOfferings:any[] = [];
      let discountScheduleOptions = [];


      if (serviceProviderId) {
        selectedServiceProviderId = serviceProviderId;
        const [_existingServiceOfferings, _discountScheduleOptions] = await Promise.all([
          findAndSetExistingServiceOfferings(serviceProviderId),
          populateDiscountScheduleDropdown(serviceProviderId),
        ]);
        const providerCoverageAreaDtosRes = await findCoverageAreaDTOsByServiceProvider(selectedServiceProviderId);
        providerCoverageAreaOptions = _createProviderCoverageAreaOptions(providerCoverageAreaDtosRes.data);
        existingServiceOfferings = _existingServiceOfferings;
        discountScheduleOptions = _discountScheduleOptions;
      }

      let initialFormData = createDefaultValues();
      update({
        ...get(),
        selectedServiceProviderId,
        initialFormData,
        existingDefaultServiceOffering: null,
        isDefaultForServiceType: false,
        existingServiceOfferings,
        discountScheduleOptions,
        providerCoverageAreaOptions,
        isClone,
        files: [],
        hardDeletedImageRefs: [],
        pendingDeleteImages: [],
        omitImages,
      });
    }
    doneLoading(100);
  }

  function _createProviderCoverageAreaOptions(providerCoverageAreaDtos:IProviderCoverageAreaDTO[]):IDropdownOption[] {
    return [createUninitializedDropdownOption('Clear'), ...providerCoverageAreaDtos.map(x => ({
      key: x.providerCoverageArea.id ?? '',
      optionValue: x.providerCoverageArea.id ?? '',
      optionText: x.providerCoverageArea.name,
    }
    ))];
  }
  async function findAndSetExistingServiceOfferings(_serviceProviderId:string) {
    const serviceOfferingsRes = await findServiceOfferingsByProvider(_serviceProviderId);
    if (serviceOfferingsRes.data) {
      return serviceOfferingsRes.data;
    }
    return [];
  }

  async function onSaveServiceOffering(data:IServiceOffering) {
    try {
      onLoading();
      if (data.subscriptionOptions?.options) {
        var options = data.subscriptionOptions?.options;
        if (options.filter(x => x.isOneTime).length > 0 && data.cadenceType === CadenceType.SUBSCRIPTION_ONLY) {
          throw new Error('Cannot save a subscription only service offering with a one-time option.');
        }
      }
      const { files, hardDeletedImageRefs } = get();
      var formData = new FormData();
      const formDto = await convertDataToSave(data, hardDeletedImageRefs);
      formData.append('dto', new Blob([JSON.stringify(formDto)], {
        type: 'application/json',
      }));
      for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i]);
      }
      const res = await saveServiceOffering(formData, data.id);
      if (res.data) {
        void NSOCreationUtil.generateNeighborvilleNso(
          {
            serviceProviderId: res.data.providerId,
            serviceOfferingId: res.data.id,
            serviceType: res.data.serviceType,
            discountScheduleId: res.data.defaultDiscountScheduleId,
          },
        );
      }

      update({
        ...get(),
        files: [],
        pendingDeleteImages: [],
      });
      doneLoading(300);
      navigate(-1);
    } catch (e:any) {
      createErrorToast(e.response.data.message);
      doneLoading();
    }
  }


  async function convertDataToSave(data:IServiceOffering, hardDeletedImageRefs: IServiceOfferingImageRef[]): Promise<IServiceOfferingFormDTO> {
    const { selectedServiceProviderId, existingDefaultServiceOffering, isClone, omitImages } = get();
    const dataToSave = { ...data };
    if (selectedServiceProviderId) {
      dataToSave.providerId = selectedServiceProviderId;
    }
    if (dataToSave.cadenceType === 'ONETIME_ONLY') {
      delete dataToSave.subscriptionOptions;
    }
    if (dataToSave.providerCoverageAreaId === UNSELECTED_OPTION) {
      dataToSave.providerCoverageAreaId = null;
    }
    //convert serviceDetailSections.sectionItems and serviceDetailSections.sectionListItems back to
    //original form
    dataToSave.serviceDetailSections = data.serviceDetailSections
      .map(x => convertSectionDetailSectionOriginal(x));
    if (dataToSave.customFields) {
      dataToSave.customFields.forEach((customField) => {
        if (customField.customFieldGroupType === '') {
          //empty string won't deserialize into an enum
          customField.customFieldGroupType = null;
        }
        customField.customFieldOptions.forEach((customFieldOption) => {
          customFieldOption.optionValue = customFieldOption.optionText;
        });
      });
    }


    const serviceTypeInfoRes = await findServiceTypeInfo();
    const serviceTypeInfo = serviceTypeInfoRes.data;
    let sampleNames = ServiceTypeInfoUtil.findMatchingServiceCategoryAndServiceTypeNames(dataToSave.serviceTypeId, serviceTypeInfo);
    dataToSave.serviceType = sampleNames?.serviceTypeName ?? '';
    dataToSave.serviceCategory = sampleNames?.serviceCategoryName ?? '';

    let formDto:IServiceOfferingFormDTO = {
      serviceOffering: dataToSave,
      existingDefaultServiceOffering,
      hardDeletedImageRefs,
    };
    return formDto;
  }

  async function onServiceProviderChanged(nextServiceProviderId:string, formContext) {
    const existingServiceOfferings = await findAndSetExistingServiceOfferings(nextServiceProviderId);
    const discountScheduleOptions = await populateDiscountScheduleDropdown(nextServiceProviderId);
    const providerCoverageAreaDtosRes = await findCoverageAreaDTOsByServiceProvider(nextServiceProviderId);
    const providerCoverageAreaOptions = _createProviderCoverageAreaOptions(providerCoverageAreaDtosRes.data);
    formContext.setValue('providerCoverageAreaId', '');
    update({
      ...get(),
      hasServiceProviderError: false,
      selectedServiceProviderId: nextServiceProviderId,
      discountScheduleOptions,
      existingServiceOfferings,
      providerCoverageAreaOptions,
    });
    const serviceTypeId = formContext.getValues('serviceTypeId');
    checkDefaultServiceOfferingForType(serviceTypeId, formContext);
  }

  function setHasServiceProviderError() {
    update({
      ...get(),
      hasServiceProviderError: true,
    });
  }
  async function populateDiscountScheduleDropdown(_serviceProviderId:string) {
    const discountSchedulesRes = await findDiscountSchedulesByProvider(_serviceProviderId);
    if (discountSchedulesRes.data) {
      return discountSchedulesRes.data.map(x => {
        return {
          id: x.id,
          name: x.name,
        };
      });
    }
    return [];
  }

  async function onServiceTypeChanged(event, formContext:any) {
    const { selectedServiceProviderId, existingServiceOfferings } = get();
    const serviceTypeId = formContext.getValues('serviceTypeId');
    if (selectedServiceProviderId && existingServiceOfferings && serviceTypeId) {
      checkDefaultServiceOfferingForType(serviceTypeId, formContext);
    } else {
      update({
        ...get(),
        existingDefaultServiceOffering: null,
      });
    }
  }

  async function onDefaultForServiceTypeChecked(event, formContext:any) {
    const { selectedServiceProviderId, existingServiceOfferings } = get();
    const serviceTypeId = formContext.getValues('serviceTypeId');
    const defaultForServiceType = formContext.getValues('defaultForServiceType');
    if (selectedServiceProviderId && existingServiceOfferings && serviceTypeId && defaultForServiceType) {
      checkDefaultServiceOfferingForType(serviceTypeId, formContext);
    } else {
      update({
        ...get(),
        existingDefaultServiceOffering: null,
      });
    }
  }

  function checkDefaultServiceOfferingForType(selectedServiceTypeId:string, formContext:any) {
    const { existingServiceOfferings, isClone } = get();

    let defaultServiceOfferings = existingServiceOfferings
      .filter( x => x.defaultForServiceType)
      .filter( x => x.serviceTypeId === selectedServiceTypeId)
      .filter( x => serviceOfferingId === null || x.id !== serviceOfferingId || isClone);
    if (defaultServiceOfferings.length > 0) {
      const markedAsDefault = formContext.getValues().defaultForServiceType;
      if (markedAsDefault) {
        update({
          ...get(),
          existingDefaultServiceOffering: defaultServiceOfferings[0],
        });
      } else {
        update({
          ...get(),
          existingDefaultServiceOffering: null,
        });
      }
    } else {
      update({
        ...get(),
        existingDefaultServiceOffering: null,
      });
      //if we didn't find an existing default then go ahead and preemptively set it
      formContext.setValue('defaultForServiceType', true);
    }
  }

  function modifyForClone(data, omitImages:boolean):any {
    data.name = '';
    data.defaultForServiceType = false;
    delete data.itemMetadata;
    data.defaultDiscountScheduleId = '';
    data.cutoffDayCount = '';
    data.providerId = '';
    data.clonedFromId = data.id;
    if (omitImages) {
      data.imageRefs = [];
    }
    data.providerCoverageAreaId = '';
    delete data.id;
    return data;
  }

  function updateFiles(nextFiles:IPreviewFile[]) {
    const { files } = get();
    update({
      ...get(),
      files: [...files, ...nextFiles],
    });
  }

  function removeFile(file:IPreviewFile) {
    const { files } = get();
    const after = files.filter(x => x !== file);
    update({
      ...get(),
      files: after,
    });
  }

  /**
   * Mark an image ref for delete. If hard delete is checked then this will also delete the
   * corresponding image in the S3 bucket.
   * @param data
   * @param hardDelete
   * @param formCtx
   */
  function markForDelete(data:IServiceOfferingImageRef, formCtx: any) {
    const { pendingDeleteImages } = get();
    const imageRefs = formCtx.getValues('imageRefs');
    const after = imageRefs.filter(x => x !== data);
    formCtx.setValue('imageRefs', after);
    pendingDeleteImages.push(data);
    update({ ...get(), pendingDeleteImages });
  }

  function toggleForHardDelete(data:IServiceOfferingImageRef, hardDelete:boolean) {
    const { hardDeletedImageRefs } = get();
    let nextHardDeletedImageRefs =[...hardDeletedImageRefs];
    if (hardDelete && nextHardDeletedImageRefs.indexOf(data) === -1) {
      nextHardDeletedImageRefs.push(data);
    } else {
      nextHardDeletedImageRefs = nextHardDeletedImageRefs.filter(x => x !== data);
    }
    update({ ...get(), hardDeletedImageRefs: nextHardDeletedImageRefs });
  }

  /**
   * This will only work before submission. the hard delete flag does not matter here. The image ref will be removed from the list.
   * @param data
   * @param formCtx
   */
  function unmarkForDelete(data:IServiceOfferingImageRef, formCtx: any) {
    const { pendingDeleteImages } = get();
    const imageRefs = formCtx.getValues('imageRefs');
    const after = pendingDeleteImages.filter(x => x !== data);
    formCtx.setValue('imageRefs', [...imageRefs, data]);
    update({
      ...get(),
      pendingDeleteImages: after,
    });
  }

  return {
    loadingKey,
    ...get(),
    init,
    onServiceProviderChanged,
    onServiceTypeChanged,
    onSaveServiceOffering,
    updateFiles,
    checkDefaultServiceOfferingForType,
    onDefaultForServiceTypeChecked,
    setHasServiceProviderError,
    removeFile,
    markForDelete,
    toggleForHardDelete,
    unmarkForDelete,
  };
}